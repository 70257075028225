<script setup lang="ts">
  const { authUsername } = storeToRefs(useAuthStore());
  interface Props {
    size?: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    size: 'small',
  });

  const pxSize = computed(() => {
    if (props.size === 'small') {
      return '70px';
    } else if (props.size === 'large') {
      return '170px';
    } else {
      return '70px';
    }
  });

  const titleClass = computed(() => {
    if (props.size === 'small') {
      return 'text-h6 q-mt-md';
    } else if (props.size === 'large') {
      return 'text-h5 q-mt-md';
    } else {
      return 'text-h5 q-mt-md';
    }
  });
</script>

<template>
  <q-avatar
    :size="pxSize"
    font-size="52px"
    color="primary"
    text-color="white"
    class="q-margin-md"
  />
  <div :class="titleClass">
    {{ authUsername }}
  </div>
</template>
