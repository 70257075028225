import { api } from 'src/boot/axios';
import { defineStore } from 'pinia';
import { commonActions } from './mixins/commonActions';
import { commonState } from './mixins/commonState';
import { useAuthStore } from 'stores/auth-store';

const RESOURCE_NAME = 'crud/user';

export const useUsersStore = defineStore({
  id: RESOURCE_NAME,

  state: () => ({
    ...commonState(),
    _current: null,
  }),
  getters: {
    current: (state) => state._current,
  },
  actions: {
    ...commonActions(RESOURCE_NAME),
    async fetchCurrent() {
      const auth = useAuthStore();
      this._current = await this.fetchItem(auth.authUser.id);
    },
    async switchFactory(newFactoryId: number | string) {
      const auth = useAuthStore();
      const { data } = await api.put(`users/${auth.authUser.id}/factory/${newFactoryId}`);
      // Make sure that the cookies and sessions will be correct
      auth.logout();
    },
  },
});
