import { boot } from 'quasar/wrappers';
import { I18n, createI18n } from 'vue-i18n';
import { LocalStorage } from 'quasar';

import messages from 'src/i18n';

export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = (typeof messages)['en-US'];

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}
/* eslint-enable @typescript-eslint/no-empty-interface */

const language = LocalStorage.getItem('language');

let i18n: I18n | null = null;

export default boot(({ app }) => {
  i18n = createI18n({
    locale: language?.toString() ?? 'en-US',
    legacy: false,
    silentFallbackWarn: true,
    messages,
  });

  app.use(i18n);
});

export function getI18n() {
  return i18n;
}
