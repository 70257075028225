import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/vue-query';
import { extend } from 'quasar';
import { api } from 'src/boot/axios';
import { Product, Room } from 'src/components/models';
import {
  GrowingPhase,
  GrowingProgram,
  GrowingRoom,
  HarvestForecast,
  MushroomType,
  ProductRecord,
  ProductStorageRoom,
} from 'src/domain/models/types';

export type GrowingPhasesAPI = {
  step: number;
  growing_program_id: number;
  growing_room: GrowingRoom;
  hex_color: string;
  id: number;
  label: string;
  start: string;
  end: string;
};

export type HarvestDayAPI = {
  id: number;
  date: string;
  harvested_weight: number | null;
  target_weight: number | null;
  declassified_weight: number;
  growing_room: GrowingRoom;
  growing_phase_label?: string;
  moment: 'Empty' | 'Morning' | 'Afternoon' | null;
};

export type GrowingProgramsAPI = {
  active: boolean;
  factory_id: string;
  growing_phase_types: {
    default_duration: number;
    enable_harvest: boolean;
    factory_id: string;
    hex_color: string;
    id: number;
    label: string;
    step: number;
  }[];
  id: number;
  label: string;
  mushroom_type: MushroomType;
};

export type ProductDefaultDemandPeriod = {
  factory_id?: string;
  id?: number;
  start_iso_week: number;
  end_iso_week: number;
  label: string;
  active: boolean;
};

export type ProductDefaultDemand = {
  id?: number;
  product_id?: number;
  period_id?: number;
  monday_weight: number | null;
  tuesday_weight: number | null;
  wednesday_weight: number | null;
  thursday_weight: number | null;
  friday_weight: number | null;
  saturday_weight: number | null;
  sunday_weight: number | null;
};

export type ProductPurchase = {
  id: number;
  date: string;
  product_id: number;
  received: boolean;
  weight: number;
};

export type ProductDemand = {
  id: number;
  product_id: number;
  weight: number;
  date: string;
};

export type GrowingFillingEvent = {
  id: number;
  growing_program_id: number;
  casing_supplier_id: number;
  strain_type_id: number;
  substrate_supplier_id: number;
  caccing_type_id: number;
  tunnel: string;
  days_since_spawning: number;
  ph_before_delivery: number;
  relative_humidity_before_delivery: number;
  delivery_weight: number;
  structure: string;
  filling_height: number;
  filled_surface: number;
  time: string;
};

export type StrainType = {
  id: number;
  label: string;
};

export type CaccingType = {
  id: number;
  label: string;
};

export type Suppliers = {
  id: number;
  label: string;
};

export type Factory = {
  id: string;
  factory_config: {
    id: number;
  };
  company: Company;
  label: string;
};

export type Company = {
  id: number;
  label: string;
};

export type CompanyProduct = {
  id: number;
  label: string;
  bio: boolean;
  mushroom_color: string | null;
  punnet_count: number;
  punnet_weight: number | null;
  mushroom_size_type_id: number;
};

export type MushroomSizeType = {
  id: number;
  label: string;
};

export type DeclassifiedWeight = { date: string; weight: number; mushroom_color: string };

export type ProductionDay = {
  id: string;
  date: string;
  carry: boolean;
  difference: number;
  mushroom_color: string;
  bio: boolean;
};

export type GrowingRoomTypeAPI = { digit: number; label: string; id: string };

export type GrowingWateringEvents = {
  id: number;
  time: string;
  growing_program_id: number;
  volume: number;
  nematodes: boolean;
};

export type GrowingAiringEvents = {
  id: number;
  growing_program_id: number;
  time: string;
  duration: number;
};

export type GrowingRufflingEvents = {
  id: number;
  growing_program_id: number;
  time: string;
  notes: string;
};

export type GrowingSteamingEvents = {
  id: number;
  growing_program_id: number;
  time: string;
  weight: number;
};

export type GetRoutes = {
  '/growing-phases/current': GrowingPhase[];
  '/crud/harvest-forecast': HarvestForecast[];
  '/v2/harvest-forecasts/latest': HarvestForecast[];
  '/crud/mushroom-type': MushroomType[];
  '/crud/growing-program': { growing_room: Room; mushroom_type: MushroomType }[];
  '/crud/growing-program-type': GrowingProgramsAPI[];
  '/crud/growing-filling-event': GrowingFillingEvent[];
  '/factories': Factory[];
  '/v2/growing-programs/current': GrowingProgram[];
  '/v2/products': Product[];
  '/v2/product-records': ProductRecord[];
  '/v2/product-storage-rooms': ProductStorageRoom[];
  '/v2/growing-rooms': GrowingRoomTypeAPI[];
  '/v2/growing-phases': GrowingPhasesAPI[];
  '/v2/harvest-days': HarvestDayAPI[];
  '/v2/product-default-demand-periods': ProductDefaultDemandPeriod[];
  '/v2/product-default-demand-periods/{id}/demands': ProductDefaultDemand[];
  '/v2/product-purchases': ProductPurchase[];
  '/v2/product-demands': ProductDemand[];
  '/v2/harvest-days/declassify-weight': DeclassifiedWeight;
  '/v2/strain-types': StrainType[];
  '/v2/suppliers': Suppliers[];
  '/v2/growing-programs': GrowingProgram[];
  '/v2/company-products': CompanyProduct[];
  '/v2/mushroom-size-types': MushroomSizeType[];
  '/v2/production-days': ProductionDay[];
  '/v2/harvest-forecasts/latest/consensus': LatestConsensusAPI;
  '/v2/growing-filling-events': GrowingFillingEvent[];
  '/v2/growing-watering-events': GrowingWateringEvents[];
  '/v2/growing-airing-events': GrowingAiringEvents[];
  '/v2/growing-ruffling-events': GrowingRufflingEvents[];
  '/v2/growing-steam-events': GrowingSteamingEvents[];
  '/v2/caccing-types': CaccingType[];
  '/v2/factorys/{id}': Factory;
};

export interface LatestConsensusAPI {
  harvest_day_forecasts: { target_weight: number; growing_room: number; harvest_day: number }[];
  is_consensus: boolean;
  is_used_in_consensus: boolean;
  id: number;
  submitted: boolean;
  user_id: number;
  created_at: string;
  updated_at: string;
  consensus_id: number;
  harvest_forecasts_ids: number[];
}

export type CRUDParams = {
  limit?: number;
  cacheDuration?: number;
  offset?: number;
  depth?: number;
  excludedFields?: string;
  sort?: string;
};

export function buildUrlParams(params?: Record<string, string | number | undefined>) {
  if (!params || !Object.entries(params).length) return '';
  const newUlr = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value) newUlr.append(key, value.toString());
  });

  return '?' + newUlr.toString();
}

export const useTypedQuery = <T extends keyof GetRoutes>(
  route: T,
  options?: UseQueryOptions<GetRoutes[T]> & {
    crudParams?: CRUDParams;
    queryParams?: Record<string, string>;
  }
) => {
  const params =
    options?.crudParams || options?.queryParams
      ? { ...(options?.crudParams || {}), ...(options?.queryParams || {}) }
      : undefined;

  const url = route.toString() + buildUrlParams(params);

  return useQuery<GetRoutes[T]>({
    ...(<object>options),
    queryKey: [url, ...(unref(options?.queryKey) || [])],
    // select(data) {
    //   if (data?.length) return extend(true, [], data);
    //   return extend(true, {}, data);
    // },
  });
};

export const fetchTyped = <T extends keyof GetRoutes>(route: T) =>
  api.get<GetRoutes[T]>(route.toString());

export const useFetchCached = () => {
  const queryClient = useQueryClient();
  return <T>(k: string, { staleTime = 5000 }) =>
    queryClient.fetchQuery<T>({ queryKey: [k], staleTime });
};

export const useApiQueryInvalidationByRoute = () => {
  const queryClient = useQueryClient();
  return <T extends keyof GetRoutes>(routes: T[]) =>
    queryClient.invalidateQueries({
      predicate: (query) => {
        for (const route of routes) {
          if (typeof query.queryKey[0] === 'string' && query.queryKey[0].includes(route)) {
            // console.log(query.queryKey[0], route, 'invalid');
            return true;
          }
        }

        return false;
      },
    });
};
