<template>
  <div
    v-if="!isOnline"
    class="font-bold w-min flex justify-center flex-nowrap items-center gap-1 text-white bg-red rounded-sm py-0.1 px-1 text-xs"
  >
    <div>Offline</div>
    <q-icon name="signal_wifi_off" />
  </div>
  <div class="h-0 w-0 overflow-hidden">
    <q-icon name="signal_wifi_off" />
  </div>
</template>

<script setup lang="ts">
  const { isOnline } = useNetwork();
</script>

<style scoped></style>
