import { LocalStorage } from 'quasar';
import { ref, Ref } from 'vue';

interface CacheItem<T> {
  data: T;
  timestamp: number;
}

export function useCacheManager<T>(localstorage = false): {
  getCached: (key: string, cacheDuration?: number) => T | null;
  setCached: (key: string, data: T) => void;
} {
  const cache: Ref<Map<string, CacheItem<T>>> = ref(new Map());

  function getCached(key: string, cacheDuration: number = 5 * 60 * 1000): T | null {
    let cachedData = null;
    if (localstorage) {
      cachedData = LocalStorage.getItem(key);
    } else {
      cachedData = cache.value.get(key);
    }
    if (cachedData && Date.now() - cachedData.timestamp < cacheDuration) {
      return cachedData.data;
    }
    return null;
  }

  function setCached(key: string, data: T): void {
    const body = {
      data,
      timestamp: Date.now(),
    }
    if (localstorage) {
      LocalStorage.set(key, body);
    } else {
      cache.value.set(key, body);
    }
  }
  return {
    getCached,
    setCached,
  };
}
