import { Notify } from 'quasar';

export function notifyWarning(message: string) {
  return Notify.create({
    message,
    type: 'warning',
    position: 'bottom',
    timeout: 20000,
    classes: 'bg-orange text-white',
    actions: [
      {
        icon: 'close',
        color: 'white',
      },
    ],
  });
}
