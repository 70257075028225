import { date } from 'quasar';
import { HarvestDayAPI } from 'src/composables/useApi';

export const useHarvestForecastStoreV2 = defineStore('harvest-forecast-store', () => {
  const { data: latestConsensus, suspense: latestConsensusSupense } = useTypedQuery(
    '/v2/harvest-forecasts/latest/consensus'
  );

  const { data: currentGrowingProgram, suspense: cgPSuspense } = useTypedQuery(
    '/crud/growing-program',
    {
      crudParams: {
        depth: 3,
        sort: '-id',
        excludedFields: 'factory_id,growing_phases,growing_events,start_date,end_date',
      },
    }
  );

  const sortedPrograms = computed(() => {
    const cP = currentGrowingProgram.value;
    return cP && [...cP].sort((a, b) => a.growing_room.digit - b.growing_room.digit);
  });

  const getCurrentMushroomType = (roomDigit: number) => {
    const val = sortedPrograms.value?.find(
      (program) => program.growing_room.digit === roomDigit
    )?.mushroom_type;

    if (!val) console.log(val, roomDigit, 'dayum');

    return val;
  };

  const today = new Date();

  const params = computed(() => ({
    queryParams: {
      from: date.formatDate(date.subtractFromDate(today, { months: 1 }), 'DD/MM/YYYY'),
      to: date.formatDate(date.addToDate(today, { months: 1 }), 'DD/MM/YYYY'),
    },
  }));

  const { data: harvestDays, suspense: harvestDaysSuspense } = useTypedQuery('/v2/harvest-days', {
    queryKey: [params],
  });

  const harvestForecastsDict = computed(() => {
    if (
      !latestConsensus.value ||
      !harvestDays.value?.length ||
      !currentGrowingProgram.value?.length
    )
      return {} as Record<string, number>;

    const dict: Record<string, { value: number; date: Date }> = {};

    latestConsensus.value.harvest_day_forecasts.forEach((hdf) => {
      const shroomType = getCurrentMushroomType(hdf.growing_room);

      const harvestDay = harvestDays.value?.find((hd) => hd.id == hdf.harvest_day);
      if (!harvestDay?.date) return;

      const key = `${harvestDay?.date}-${shroomType?.mushroom_color}-${shroomType?.bio}#${hdf.growing_room}}`;
      if (!dict[key]) {
        dict[key] = { value: hdf.target_weight, date: new Date(harvestDay.date) };
      }
    });

    const returnDict: Record<string, number> = {};

    Object.keys(dict).forEach((key) => {
      const noRoomKey = key.split('#')[0];
      if (!returnDict[noRoomKey]) returnDict[noRoomKey] = 0;
      returnDict[noRoomKey] += dict[key].value;
    });

    return returnDict;
  });

  const harvestDaysByRoomDigitDict = computed(() => {
    const dict: { [key: string]: HarvestDayAPI } = {};
    if (!harvestDays.value) return dict;

    for (const hd of harvestDays.value) {
      if (hd.growing_room.digit) dict[hd.growing_room.digit] = hd;
    }

    return dict;
  });

  return {
    getters: { harvestForecastsDict, harvestDays, harvestDaysByRoomDigitDict },
    supense: () =>
      Promise.allSettled([latestConsensusSupense(), harvestDaysSuspense(), cgPSuspense()]),
  };
});
