<template>
  <div :class="{ 'bg-white border-r-solid border-r-accent border-r-1px': !$q.platform.is.mobile }">
    <Tab.define #="{ colors: { shroom, status }, digit, label }">
      <div>
        <q-tab
          :name="digit.toString()"
          class="flex flex-col relative items-center border-b-solid border-b-1px border-accent mb-3 room-vertical-tab"
          no-caps
        >
          <!-- Room status indicator -->
          <q-badge
            v-if="status"
            class="absolute top-3px left--5px w-15px h-15px rounded-5px"
            :color="status"
            :outline="status === 'grey'"
          />
          <IconsMushroom class="text-2xl" :style="{ color: shroom }" />
          <span>{{ label || digit }}</span>
        </q-tab>
      </div>
    </Tab.define>
    <Tabs.define #="{ class: cls }">
      <q-tabs
        v-model="model"
        vertical
        active-class="active-room"
        :class="cls"
        class="text-gray bg-white pt-4 px-2 override"
      >
        <div v-for="gr in growingRooms" :key="gr.digit.toString()">
          <Tab.reuse
            :label="growingRoomsByDigitDict?.[gr.digit]?.label || ''"
            :digit="gr.digit"
            :colors="getColors(gr.digit)"
          />
        </div>
      </q-tabs>
    </Tabs.define>

    <q-scroll-area
      :thumb-style="{
        zIndex: '2',
        backgroundColor: 'var(--q-primary)',
        opacity: '0.7',
        width: '6px',
      }"
      :bar-style="{ zIndex: '2', backgroundColor: 'gray', opacity: '0.2', width: '6px' }"
      v-if="!$q.platform.is.mobile"
      class="h-[calc(100vh-70px)] w-24 bg-white"
    >
      <Tabs.reuse class="" />
    </q-scroll-area>
    <Tabs.reuse
      v-else
      class="vertical-room-navigation border-r-solid border-r-accent border-r-1px sticky top-60px"
    />
  </div>
</template>

<script setup lang="ts">
  import { RoomcheckGrowingRoomsType } from 'src/stores/allocation-forecast-store';

  const model = defineModel<string>({ required: true });
  defineProps<{ growingRooms: RoomcheckGrowingRoomsType[] }>();
  defineOptions({ name: 'RoomCheckTabs' });

  const { growingRoomsByDigitDict } = storeToRefs(useGrowingRoomsStore());

  const Tab = createReusableTemplate<{
    digit: number;
    label: string;
    colors: {
      shroom: string;
      status: string;
    };
  }>();

  const Tabs = createReusableTemplate<{ class: string }>();

  const { getCurrentMushroomType } = useGrowingProgramsStore();
  const { getters } = useRoomCheckStoreV2();
  const { statusColor } = toRefs(getters);

  watch(model, () => window.scrollTo(0, 0));

  function getColors(roomDigit: number) {
    const shroom = getCurrentMushroomType(roomDigit)?.hex_color || '';
    const status = statusColor.value('t_' + roomDigit).color;
    return { shroom, status };
  }
</script>

<style lang="scss">
  .mushroom-triangle {
    width: 0;
    height: 0;

    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  .small-text {
    font-size: 0.6rem;
    line-height: 1rem;
    text-align: center;
  }

  .vertical-room-navigation {
    height: calc(100vh - 60px - 70px);
  }

  .active-room {
    border-right: unset;
    border: 2px solid $primary;
    border-radius: 8px;
    color: $primary;

    .q-tab__indicator {
      display: none;
    }
  }

  .override {
    .q-tab__content {
      span {
        text-wrap: wrap;
      }
    }
  }
</style>
