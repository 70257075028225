import { extend } from 'quasar';

export const useExpandableList = <T extends object>(list: Ref<T[]>) => {
  if (!list.value.length) throw new Error('List must be initialized with at least one item');

  type TWithID = { value: T; id: string };

  const emptyItem = extend<T>(true, {}, list.value[0]);
  const wrappedList = ref<TWithID[]>();

  function add() {
    const el = extend<T>(true, {}, emptyItem);
    list.value.push(el);
    wrappedList.value?.push({ value: el, id: Math.random().toString() });
  }

  function remove(item: TWithID) {
    const index = wrappedList.value?.indexOf(item);
    if (index === undefined || index === -1) return;

    list.value.splice(index, 1);
    wrappedList.value?.splice(index, 1);
  }

  watch(
    list,
    (newVal) => {
      if (!newVal.length) return;
      wrappedList.value = newVal.map((item) => ({ value: item, id: Math.random().toString() }));
    },
    { immediate: true }
  );

  watch(
    wrappedList,
    (newVal) => {
      for (let i = 0; i < (newVal?.length || 0); i++) {
        if (newVal?.[i].value === undefined) continue;
        list.value[i] = newVal[i].value;
      }
    },
    { deep: true }
  );

  return { add, remove, wrappedList };
};
