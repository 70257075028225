import { MushroomType } from 'src/domain/models/types';
import { computed, ComputedRef } from 'vue';



type RoomCheckValueType = [number, number, number];

interface UseTotalsOptions {
  roomcheck: ComputedRef<Record<string, RoomCheckValueType>>;
  growingRoomsDict: ComputedRef<Record<string, { mushroom_type: MushroomType }>>;
}

export const useTotals = ({ roomcheck, growingRoomsDict: growingRoomsDict }: UseTotalsOptions) => {

  const totalByDayByMushroomType = computed(() => {
    return (date: string | undefined, mushroomType: MushroomType) => {
      return Object.entries(roomcheck.value).filter(([key]) => {
        return growingRoomsDict.value[key]?.mushroom_type?.id == mushroomType.id;
      }).reduce(
        (accumulator, currentValue) => {
          if (date && date in currentValue[1]) {
            accumulator = Number(accumulator) + Number(currentValue[1][date][2])
          }
          return accumulator
        },
        0
      );
    };
  });

  const totalPerMushroomType = computed(() => {
    return (mushroomType: any) =>
      Object.entries(roomcheck.value).filter(([key]) => {
        return growingRoomsDict.value[key]?.mushroom_type?.id == mushroomType.id;
      }).reduce(
        (accumulator, currentValue) => {
          for (const roomCheckValue in currentValue[1]) {
            accumulator = Number(accumulator) + Number(currentValue[1][roomCheckValue][2]);
          }
          return accumulator;
        },
        0
      );
  });

  const total = computed(() =>
    Object.entries(roomcheck.value).reduce(
      (accumulator, currentValue) => {
        for (const roomCheckValue in currentValue[1]) {
          accumulator = Number(accumulator) + Number(currentValue[1][roomCheckValue][2]);
        }
        return accumulator;
      },
      0
    )
  );

  const totalMushroomTypes = computed(() => {
    const currentColors = new Set()
    const result = Object.values(growingRoomsDict.value).filter((gr) => isNaN(gr)).reduce((accumulator, next) => {
      if (next.mushroom_type) {
        const mushroomType = next.mushroom_type.mushroom_color + (next.mushroom_type.bio ? 'Bio' : '')
        if (currentColors.has(mushroomType)) {
          return accumulator
        }
        currentColors.add(mushroomType)
        accumulator.push(next.mushroom_type)
      }
      return accumulator

    }, [])
    return result as MushroomType[]
  });

  return {
    totalByDayByMushroomType,
    totalPerMushroomType,
    totalMushroomTypes,
    total,
  };
}
