<template>
  <div class="hidden i-solar:minus-circle-bold-duotone i-solar:add-circle-bold-duotone" />
  <Button.define #="{ icon, type, disable, handler }">
    <div>
      <q-btn :disable="disable" flat class="p-0" @click="!disable ? handler() : null">
        <q-icon color="primary" size="md" :name="icon" />
      </q-btn>
    </div>
  </Button.define>

  <div class="flex flex-col">
    <div class="text-subtitle2 text-xs">{{ label }}</div>
    <q-input dense v-model="model" class="q-my-sm dense-control rounded-lg"
      :class="`${bgColor ? 'bg-' + bgColor : 'bg-info'}`" input-class="text-center " borderless>
      <template v-slot:prepend v-if="edit">
        <Button.reuse :handler="() => model--" :disable="model <= 0" icon="i-solar:minus-circle-bold-duotone"
          type="min" />
      </template>
      <template v-slot:append v-if="edit">
        <Button.reuse :handler="() => model++" icon="i-solar:add-circle-bold-duotone" type="min" />
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
const model = defineModel<number>({ required: true });
defineProps<{ label: string; edit: boolean; bgColor?: string }>();

const Button = createReusableTemplate<{
  icon: string;
  type: 'min' | 'max';
  disable?: boolean;
  handler: () => void;
}>();
</script>

<style lang="scss">
.dense-control {
  .q-field__control {
    padding: 0 4px !important;
  }
}
</style>
