<template>
  <div>
    <!-- Room status dialog -->
    <StatusDialog @click="(id) => setState(id)" v-model="dialog" />

    <!-- Harvest info dialog -->
    <HarvestInfo v-for="(_, i) in harvestInfoDialog" :key="i" v-model="harvestInfoDialog[i]"
      :growing-phases="harvestInfo[i]" :days-info="phaseByDaysDict" />

    <!-- Page content -->
    <div v-if="roomcheckGrowingRooms.length > 0" class="flex relative flex-nowrap w-screen sm:w-auto">
      <!-- Vertical room navigation -->
      <Tabs :growing-rooms="roomcheckGrowingRooms" v-model="activeTab" />

      <!-- Room info -->
      <div class="relative room-info-container">
        <q-tab-panels v-model="activeTab" :swipeable="false" animated transition-prev="jump-right" ref="tabPanels"
          transition-next="jump-left" class="w-full">
          <q-tab-panel class="p-0 w-full" v-for="gr in roomcheckGrowingRooms" :key="gr.digit.toString()"
            :name="gr.digit.toString()">
            <!-- ROOM HEADING -->
            <q-item-section class="flex flex-row top-0 z-20 sticky justify-between items-center px-4 py-2 md:py-0.75">
              <div :class="{
                'text-h6': (growingRoomsByDigitDict?.[gr.digit]?.label?.length || 7) <= 6,
              }" class="text-gray-6 w-14">
                {{ growingRoomsByDigitDict?.[gr.digit]?.label || ' - ' }}
              </div>
              <span class="flex flex-col items-center text-grey small-text">
                <div v-for="h in harvestInfo[gr.id]" v-bind:key="h.label">
                  {{ h.label }}: {{ h.value.total }} kg
                </div>

                <div v-if="currentRoom?.id">
                  <SetVariable :data="{
                    filled: growFillingEventsTotalM2(
                      currentRoom.id,
                      currentRoomHarvestDaysDict[gr.id].growing_program_id
                    ),
                    total: harvestDaysByRoomDigitDict[gr.digit]?.growing_room?.growing_surface,
                  }" #="{ data: { filled, total } }">
                    <div class="flex flex-wrap gap-1">
                      <div>{{ filled }}/{{ total }}m2</div>
                      <div class="font-bold" v-if="total">
                        ({{ ((filled / total) * 100).toFixed(0) }}%)
                      </div>
                    </div>
                  </SetVariable>
                </div>
                <RoomCheckTargetWeightBtn class="lg:hidden" @confirm="overwriteNewKgWithTargetKg" />
              </span>

              <div>
                <div class="flex items-center gap-4">
                  <RoomCheckTargetWeightBtn size="sm" class="hidden lg:block! rounded-lg! bg-red/20 py-1! pl-1!"
                    @confirm="overwriteNewKgWithTargetKg" />
                  <q-btn class="p-0" flat :color="statusColor('t_' + gr.digit).color" @click="dialog = !dialog"
                    v-if="edit">
                    <q-icon name="i-solar:question-square-bold-duotone"
                      :color="statusColor('t_' + gr.digit).color || 'grey-7'" />
                  </q-btn>

                  <q-btn @click="harvestInfoDialog[gr.id] = true" flat class="p-0" v-if="edit">
                    <q-icon name="i-solar:cart-5-bold-duotone" color="primary" />
                  </q-btn>
                </div>
              </div>
            </q-item-section>
            <q-separator></q-separator>
            <!-- Room body -->

            <div
              class="bg-info p-2 pb-80px overflow-x-hidden flex flex-col gap-2 md:p-4 md:flex-row md:flex-wrap md:gap-4">
              <Card class="md:p-1.75 md:flex-1 md:min-w-300px md:max-w-300px sm:min-w-auto" v-for="dow in daysOfTheWeek"
                :key="dow.dayName" :name="dow.dayName">
                <template #header>
                  <CardHeader :room-harvast-day="currentRoomHarvestDaysDict[gr.id][dow.dateString]" :date="dow"
                    :is-by-pickers="isByPickers">
                    <template #right-content v-if="isDayEmpty(gr.digit, dow.dateString)">
                      <q-btn :loading="plusButtonLoading" :disable="!isOnline" flat class="p-0"
                        @click="() => createNewHarvestDay(gr, dow.date)"
                        v-if="[-1, 1].includes(dayBeforeOrAfter(gr.digit, dow.date))">
                        <q-icon color="primary" size="md" name="i-solar:add-circle-bold-duotone" />
                      </q-btn>
                      <div v-else />
                    </template>

                    <template #after>
                      <!-- @vue-skip -->
                      <RoomCheckSlider class="mt-1" v-if="!isDayEmpty(gr.digit, dow.dateString)"
                        v-model="roomcheck[gr.id][dow.dateString][3]" />
                    </template>
                  </CardHeader>
                </template>

                <div v-if="!isDayEmpty(gr.digit, dow.dateString)" class="text-grey">
                  <div v-if="isByPickers" class="flex gap-1 flex-nowrap">
                    <!-- min pickers -->

                    <IncrementBtn :label="$t('roomcheck.minpickers')" :edit="edit"
                      v-model="roomcheck[gr.id][dow.dateString][0]"
                      @update:model-value="() => updatePickers('min', gr.id, dow.dateString)" />
                    <!-- max pickers -->
                    <IncrementBtn :label="$t('roomcheck.maxpickers')" :edit="edit"
                      v-model="roomcheck[gr.id][dow.dateString][1]"
                      @update:model-value="() => updatePickers('max', gr.id, dow.dateString)" />
                  </div>

                  <!-- weight -->

                  <div class="flex flex-nowrap gap-1 w-full">
                    <div>
                      <div class="text-xs text-subtitle2 mb-1">
                        {{ $t('roomcheck.targetKg') }}
                      </div>

                      <q-input dense readonly :model-value="formatNumber(
                        currentRoomHarvestDaysDict[gr.id][dow.dateString]?.target_weight
                      )
                        " class="bg-gray/30 px-2 rounded-lg" borderless />
                    </div>
                    <div>
                      <div class="text-xs text-subtitle2 mb-1">{{ $t('roomcheck.consensus') }}</div>
                      <q-input dense readonly :model-value="getConsensusValue(dow.dateString, gr.digit)"
                        class="bg-gray/30 px-2 rounded-lg" borderless />
                    </div>
                    <div>
                      <div class="text-xs text-subtitle2 mb-1">{{ $t('roomcheck.newKg') }}</div>
                      <q-input type="number" dense v-model.number="roomcheck[gr.id][dow.dateString][2]"
                        @focus="onInputFocus(gr.id, dow.dateString, 2)" @blur="onInputBlur(gr.id, dow.dateString, 2)"
                        class="bg-info px-2 rounded-lg" @keypress="validateKeypress" borderless />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center h-90vh text-gray-6 text-center mx-8">
      {{ $t('roomcheck.nodata') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { LocalStorage, date } from 'quasar';
import { formatDate, formatNumber } from 'src/utils/utils';
import { RoomStatus } from 'src/components/models';
import { useIsStaleHarvestDay } from '../composables/is-stale-harvestday';

const model = ref<'Morning' | 'Blank' | 'Evening'>('Blank');

defineProps<{ title?: string; edit: boolean }>();
const emits = defineEmits(['room-status-change', 'room-check-change']);

const { t } = useI18n();
const { isOnline } = useNetwork();

const { getters } = useRoomCheckStoreV2();
const { roomStates, statusColor } = toRefs(getters);
const { roomcheckGrowingRooms, roomcheck } = storeToRefs(useAllocationForecastStore());
const {
  currentRoomHarvestDaySearchPrepared,
  currentRoomHarvestDaysDict,
  harvestInfo,
  phaseByDaysDict,
} = storeToRefs(useGrowingPhaseStore());
const { addHarvestDay } = useGrowingPhaseStore();
const { user } = useAuthStore();

const { checkIfDataIsStale, queryGPC } = useIsStaleHarvestDay();
const { growingRoomsByDigitDict } = storeToRefs(useGrowingRoomsStore());

const { data: latesConsensus } = useTypedQuery('/v2/harvest-forecasts/latest/consensus');

const { getters: hfGetters } = useHarvestForecastStoreV2();
const { harvestDays, harvestDaysByRoomDigitDict } = toRefs(hfGetters);

function getConsensusValue(dateString: string, growingRoomDigit: number) {
  return formatNumber(
    latesConsensus.value?.harvest_day_forecasts?.find(
      (e) =>
        e.growing_room == growingRoomDigit &&
        harvestDays.value?.find((hd) => hd.id == e.harvest_day)?.date ==
        date.formatDate(date.extractDate(dateString, 'DD-MM-YYYY'), 'DD/MM/YYYY')
    )?.target_weight || 0
  );
}

const { getters: factoryGetters } = useFactoryStore();
const { isByPickers } = toRefs(factoryGetters);

const { growFillingEventsTotalM2 } = useGrowingFillingEventsStore();

const activeTab = ref('');

const dialog = ref(false);
const tabPanels = ref(null);
const plusButtonLoading = ref(false);
const harvestInfoDialog = ref<{ [key: string]: boolean }>({});

onMounted(() => {
  if (roomcheckGrowingRooms.value && roomcheckGrowingRooms.value.length > 0) {
    activeTab.value = roomcheckGrowingRooms.value[0].digit.toString();
  }
});

const isDayEmpty = (roomDigit: number, date: string) =>
  !(
    roomDigit in currentRoomHarvestDaySearchPrepared.value &&
    currentRoomHarvestDaySearchPrepared.value[roomDigit].harvest_days.has(date)
  );

function updatePickers(type: 'min' | 'max', grId: string, dateString: string) {
  const picker = roomcheck.value[grId][dateString];
  if (type === 'min' && picker[0] > picker[1]) {
    picker[1] = picker[0];
  }
  if (type === 'max' && picker[1] < picker[0]) {
    picker[0] = picker[1];
  }
}

function onInputFocus(grId: string, dateString: string, arrId: number) {
  if (roomcheck.value[grId][dateString][arrId] === 0)
    roomcheck.value[grId][dateString][arrId] = null as unknown as number;
}

function onInputBlur(grId: string, dateString: string, arrId: number) {
  if (roomcheck.value[grId][dateString][arrId] === null)
    roomcheck.value[grId][dateString][arrId] = 0;

  roomcheck.value[grId][dateString][arrId] = +roomcheck.value[grId][dateString][arrId];
}

function overwriteNewKgWithTargetKg() {
  for (const gr of roomcheckGrowingRooms.value) {
    for (const day of Object.keys(roomcheck.value[gr.id])) {
      roomcheck.value[gr.id][day][2] =
        currentRoomHarvestDaysDict.value[gr.id][day]?.target_weight || 0;
    }
  }
}

const validateKeypress = (event: KeyboardEvent) => {
  // prevent the keypress event if the key is not a number or an apostrophe
  if (!(event.charCode >= 48 && event.charCode <= 57) && event.charCode !== 39) {
    event.preventDefault();
  }
};

const localStorageRoomStates: any = LocalStorage.getItem('roomstates');
if (localStorageRoomStates) {
  if (
    localStorageRoomStates.date == formatDate(new Date()) &&
    user &&
    localStorageRoomStates.factory == user?.factoryId
  ) {
    roomStates.value = localStorageRoomStates.state;
    emits('room-status-change', getReservesNeedHelp());
  }
}

// Day of the week
const daysOfTheWeek = computed(() => {
  const current_day = new Date();

  const days_week = [
    {
      dayName: t('daynames.today'),
      dateString: formatDate(current_day),
      date: new Date(current_day),
    },
  ];

  for (let i = 0; i <= 5; i++) {
    let date = current_day.setDate(current_day.getDate() + 1);
    days_week.push({
      dayName: getDayName(date),
      dateString: formatDate(current_day),
      date: new Date(current_day),
    });
  }
  return days_week;
});

function dayBeforeOrAfter(roomDigit: string | number, day: Date): number {
  const today = new Date();
  today.setHours(-1, 0, 0, 0);
  for (const value of [-1, 1]) {
    const date = new Date(day);
    date.setDate(day.getDate() + value);
    if (
      roomDigit in currentRoomHarvestDaySearchPrepared.value &&
      currentRoomHarvestDaySearchPrepared.value[roomDigit].harvest_days.has(formatDate(date))
    ) {
      if (value == 1 && today >= day) return Number.MIN_SAFE_INTEGER;
      return value;
    }
  }
  return Number.MIN_SAFE_INTEGER;
}

async function createNewHarvestDay(gr: any, day: Date) {
  plusButtonLoading.value = true;

  const isStale = await checkIfDataIsStale();
  if (isStale) {
    plusButtonLoading.value = false;
    return;
  }

  const date = new Date(day);
  const dba = dayBeforeOrAfter(gr.digit, day);
  const edgeDate = new Date();
  edgeDate.setDate(day.getDate() + dba);
  const gp = currentRoomHarvestDaysDict.value[gr.id][formatDate(edgeDate)];
  await addHarvestDay(gp.growing_phase_id, formatDate(date), dba == 1 ? true : false);
  await queryGPC();
  plusButtonLoading.value = false;
}

function getReservesNeedHelp(this: any) {
  const need_help = [];
  const reserves = [];
  const check_later = [];

  for (let key in roomStates.value) {
    const room = roomcheckGrowingRooms.value.find((r) => r.id === key);
    if (room?.digit) {
      switch (roomStates.value[key]) {
        case RoomStatus.CheckLater:
          check_later.push(room.digit);
          break;
        case RoomStatus.Reserves:
          reserves.push(room.digit);
          break;
        case RoomStatus.NeedHelp:
          need_help.push(room.digit);
          break;
      }
    }
  }
  return [need_help, reserves, check_later];
}

const currentRoom = computed(() => {
  for (const growingRoom in roomcheckGrowingRooms.value) {
    if (
      roomcheckGrowingRooms.value[growingRoom].digit === Number(activeTab.value.replace('t_', ''))
    ) {
      return roomcheckGrowingRooms.value[growingRoom];
    }
  }
  return undefined;
});

function setState(state: number) {
  if (!currentRoom.value) return;

  roomStates.value[currentRoom.value.id] = state;
  dialog.value = false;
  LocalStorage.set('roomstates', {
    date: formatDate(new Date()),
    factory: user?.factoryId,
    state: roomStates.value,
  });

  emits('room-status-change', getReservesNeedHelp());
}

function getDayName(dateStr: number) {
  var date = new Date(dateStr);
  const str = date.toLocaleDateString('en-US', { weekday: 'long' });
  var date = new Date(dateStr);
  return t('daynames.' + str.toLowerCase());
}
</script>

<style lang="scss"></style>
