import { api } from 'src/boot/axios';
import { handleApiError } from 'src/utils/errorHandler';
import { defineStore } from 'pinia';
import { commonActions } from './mixins/commonActions';
import { commonState } from './mixins/commonState';

import { GrowingRoomTypeAPI } from 'src/composables/useApi';

const RESOURCE_NAME = 'crud/growing-room';
const ca = commonActions(RESOURCE_NAME, 'growing-rooms');

export const useGrowingRoomsStoreKeepForBackwards = defineStore({
  id: RESOURCE_NAME,

  state: () => ({
    ...commonState(),
    growingPrograms: [],
    _growingRooms: [] as GrowingRoomTypeAPI[],
    fetchError: '',
    fetching: false,
  }),
  getters: {
    getGrowingRooms: (state) => {
      return state._growingRooms;
    },
    growingRoomsByDigitDict: (state) =>
      state._growingRooms.reduce((acc, room) => {
        acc[room.digit] = room;
        return acc;
      }, {} as Record<string, GrowingRoomTypeAPI>),
  },
  actions: {
    ...ca,
    async fetchAllByFactory() {
      try {
        const { data } = await api.get('v2/growing-rooms');
        this._growingRooms = data;
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },
  },
});

export const useGrowingRoomsStore = defineStore(RESOURCE_NAME, () => {
  console.log('new store: useGrowingRoomsStore in use.');
  const { data: growingRooms } = useTypedQuery('/v2/growing-rooms');

  const getters = {
    getGrowingRooms: computed(() => growingRooms.value),
    growingRoomsByDigitDict: computed(() =>
      growingRooms.value?.reduce((acc, room) => {
        acc[room.digit] = room;
        return acc;
      }, {} as Record<number, GrowingRoomTypeAPI>)
    ),
  };
  const actions = {
    ...ca,
    async fetchAllByFactory() {
      //noop

      console.log('noop NEW STORE HIT');
    },
  };

  return { ...getters, ...actions };
});
