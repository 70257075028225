import { Product } from 'src/components/models';

export const useMushroomTypesStoreV2 = defineStore('mushroom-types-v2', () => {
  const { data, isFetching: isFetchingMushroomTypes } = useTypedQuery('/crud/mushroom-type');

  const getMushroomTypeByProduct = computed(
    () => (p?: Product) =>
      p && data.value?.find((mt) => mt.bio === p.bio && mt.mushroom_color === p.mushroom_color)
  );

  return { getters: { getMushroomTypeByProduct, mushroomTypes: data, isFetchingMushroomTypes } };
});
