<template>
  <div class="text-gray-6 mb-2">{{ $t('fridge.filters') }}</div>
  <div class="flex items-stretch flex-wrap gap-4 mb-4">
    <q-btn
      :ripple="false"
      color="white"
      v-if="showDateFilter"
      no-caps
      flat
      class="bg-white rounded-lg px-4"
      dense
    >
      <div class="flex gap-2 items-center">
        <q-icon color="primary" name="i-solar:calendar-bold-duotone" />
        <div class="text-gray-6">{{ filters.date.from }} - {{ filters.date.to }}</div>
      </div>
      <q-popup-proxy
        class="rounded-lg overflow-hidden shadow-lg"
        transition-show="fade"
        transition-hide="fade"
      >
        <!-- :options="(e) => new Date(e) <= today" -->
        <q-date
          class="relative"
          flat
          mask="DD/MM/YYYY"
          :model-value="filters.date.from === filters.date.to ? filters.date.from : filters.date"
          @update:model-value="
            (e) => (e.from ? (filters.date = e) : (filters.date = { from: e, to: e }))
          "
          range
        >
          <div class="absolute top-2 right-2">
            <q-btn
              @click="filters.date = { from: backendGETFormat(today), to: backendGETFormat(today) }"
              color="white"
              dense
              flat
              icon="i-solar:restart-circle-bold-duotone"
            />
          </div>
        </q-date>
      </q-popup-proxy>
    </q-btn>
    <FormSelect
      v-model="filters.storageRoom"
      clearable
      :options="storageRooms"
      :disable="disable"
      bgColor="white"
      :label="$t('fridge.byFridge')"
      label-slot
      dense
      class="text-xs text-nowrap flex-auto lg:flex-1"
    />
    <FormSelect
      v-model="filters.mushroomType"
      clearable
      :options="mushroomTypes"
      :disable="disable"
      dense
      bgColor="white"
      :label="$t('fridge.byType')"
      class="text-xs flex-auto lg:flex-1"
    />

    <slot name="after" />
  </div>
</template>

<script setup lang="ts">
  import { MushroomType, ProductStorageRoom } from 'src/domain/models/types';
  import { backendGETFormat } from 'src/utils/utils';

  const today = new Date();

  const filters = defineModel<{
    mushroomType?: Pick<MushroomType, 'label' | 'bio' | 'mushroom_color'> | null;
    storageRoom?: ProductStorageRoom | null;
    date: { from: string; to: string };
  }>({
    required: true,
  });

  defineProps<{
    storageRooms?: ProductStorageRoom[];
    mushroomTypes?: Pick<MushroomType, 'label' | 'bio' | 'mushroom_color'>[];
    disable?: boolean;
    showDateFilter?: boolean;
  }>();

  defineSlots<{
    after?: () => any;
  }>();
</script>

<style scoped></style>
