import { ProductStorageRoom } from 'src/domain/models/types';

export const useStorageRoomsStore = defineStore('storage-rooms', () => {
  const {
    data: storageRooms,
    suspense: suspenseStorageRooms,
    isFetching: isFetchingStorageRooms,
  } = useTypedQuery('/v2/product-storage-rooms');

  const storageRoomsByIdDict = computed(() =>
    storageRooms.value?.reduce((acc, e) => {
      acc[e.id] = e;
      return acc;
    }, {} as Record<number, ProductStorageRoom>)
  );

  return {
    suspenseStorageRooms,
    getters: { storageRooms, storageRoomsByIdDict, isFetchingStorageRooms },
  };
});
