import { useQuery } from '@tanstack/vue-query';
import { notifyWarning } from 'src/utils/notify';
import { hash } from 'src/utils/utils';

export const useIsStaleHarvestDay = createSharedComposable(() => {
  const { fetchCurrentGrowingPhases } = useGrowingPhaseStore();

  const hashGPC = (val: unknown) => hash(JSON.stringify(val));

  const queryGPC = async () => {
    const data = await fetchCurrentGrowingPhases({}, 0);
    const newHash = hashGPC(data);
    lastUpdatedHash.value = newHash;
    return data;
  };

  async function checkIfDataIsStale() {
    const data = await fetchCurrentGrowingPhases({}, 0);
    const newHash = hashGPC(data);
    if (lastUpdatedHash.value !== newHash) {
      notifyWarning('Day was already added by someone else, please try again...');
      await queryGPC();
      return true;
    }
    return false;
  }

  // Possible performance issue as it rerenders every 3 seconds
  useQuery({
    queryKey: ['growing-phases/current'],
    queryFn: queryGPC,
    refetchInterval: 1000 * 3,
    refetchOnReconnect: 'always',
    throwOnError: false,
  });

  const lastUpdatedHash = ref<number>();

  return { checkIfDataIsStale, queryGPC };
});
