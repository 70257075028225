import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import routes from './routes';
import { useAuthStore } from 'src/stores/auth-store';
import eventBus from 'src/utils/eventBus';
import { delay } from 'src/utils/utils';
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });
  Router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
      auth.returnUrl = to.fullPath;
      return '/login';
    }
  });

  Router.options.scrollBehavior = (to, from, savedPosition) => {
    if (from.name == undefined) {
      eventBus.$off_all('triggerScroll');
      if (savedPosition) return savedPosition;
      if (to.hash) return { el: to.hash };
      return;
    }

    return new Promise((resolve, reject) => {
      if (savedPosition) return resolve(savedPosition);

      eventBus.$on('triggerScroll', function triggerScroll() {
        eventBus.$off_all('triggerScroll');
        if (savedPosition) return resolve(savedPosition);
        return resolve({ top: 0, left: 0 });
      });
    });
  };

  return Router;
});
