export default {
  language: 'Français',
  failed: 'Échec',
  success: "L'action a échouée",
  authentication: {
    login: 'Login',
    email: 'Email',
    password: 'Mot de Passe',
    logout: 'Déconnection',
    introducevalidpassword: 'Merci de renseigner un mot de passe valable',
    introducevalidemail: 'Merci de renseigner un email valable',
  },
  settings: {
    farmStructure: {
      title: 'Structure de la ferme',
      description: 'Configuration de la ferme et de son infrastructure.',
      image: 'https://foodtank.com/wp-content/uploads/2020/04/COVID-19-Relief_Small-Farms-.jpg',
      route: 'settings/farm-structure',
      children: {
        growingRoom: {
          title: 'Salles',
          label: 'Salles',
          description: 'Configuration des salles de culture.',
          route: '/settings/farm-structure/growing-rooms',
        },
        // shelfTypes: {
        //   title: 'Étagères',
        //   label: 'Étagères',
        //   description: 'Définir les différents types de champignons produits',
        //   route: '/settings/farm-structure/shelf-types'
        // }
      },
    },
    growingProgram: {
      title: 'Culture de champignons',
      description: 'Configuration des programmes de culture de champignons.',
      image:
        'https://images.immediate.co.uk/production/volatile/sites/30/2017/05/Chesnut-mushrooms-f2497d2.jpg?quality=90&webp=true&resize=600,545',
      route: 'settings/growing-program',
      children: {
        mushroomTypes: {
          title: 'Types de champignons',
          label: 'Types de champignons',
          description: 'Définir les différents types de champignons produits',
          route: '/settings/growing-program/mushroom-types',
        },
        growingProgramTypes: {
          title: 'Types de programmes de culture',
          label: 'Types de programmes de culture',
          description: 'Définir les différents types de programmes de culture',
          route: '/settings/growing-program/growing-program-types',
        },
        growingPhaseTypes: {
          title: 'Types de phases de culture',
          label: 'Types de phases de culture',
          description: 'Définir les différents types de phases de culture',
          route: '/settings/growing-program/growing-phase-types',
        },
      },
    },
    people: {
      title: 'Personnes',
      description: 'Configuration du personnel de la ferme.',
      image: 'https://mainlinetoday.com/wp-content/uploads/2021/04/mlt_image001_1916964.jpg',
      route: 'settings/people',
      children: {
        mushroomTypes: {
          title: 'Cueilleurs',
          label: 'Cueilleurs',
          description: 'Gestion des cueilleurs et cueilleuses de la ferme',
          route: '/settings/growing-program/pickers',
        },
      },
    },
  },
  profile: {
    settings: {
      title: 'Paramètres',
      admin: 'Seulement ADMIN',
      user: {
        title: 'Compte',
        factory: 'Ferme',
        email: 'Email',
        firstName: 'Prénom',
        lastName: 'Nom',
        intenalId: "Numéro d'employé",
      },
      app: {
        title: "Information de l'app",
        frontend: {
          version: 'Frontend version',
        },
        backend: {
          service: 'Backend service',
          version: 'Backend version',
          environement: 'Backend environement',
          database: 'Database version',
        },
      },
      workspace: {
        title: 'Switch workspace',
      },
    },
  },
  // Page translations
  roomcheck: {
    downloadReport: 'Télécharger rapport',
    minpickers: 'Min. cueilleurs',
    maxpickers: 'Max. cueilleurs',
    kilograms: 'Kilogrammes',
    total: 'Total',
    room: 'Salle',
    pickers: 'Cueilleurs',
    weight: 'Poids',
    status: {
      helpneeded: { title: "Besoin d'aide", noelements: "Pas besoin d'aide" },
      reserves: { title: 'Réserves', noelements: 'Pas de réserves' },
      checklater: { title: 'Verif. plus tard', noelements: 'Rien à vérifier' },
    },
    nostate: "Pas d'état",
    roomstatus: 'État de la salle',
    submit: 'Soumettre',
    submited: 'Soummis',
    nodata: "Il n'y a pas de salle avec un cycle de croissance dans les prochains 7 jours.",
    logs: 'Logs',
    afternoon: 'Après-midi',
    morning: 'Matin',
    targetKg: 'Cible Kg',
    consensus: 'Consensus',
    newKg: 'Nouveau Kg',
    noPreviousDays: 'Pas de jours précédents',
    harvestInfo: 'Info Cueillette',
  },
  allocationforecasts: {
    forecast: 'Prévisions',
    alreadyUsed: 'Déjà utilisé',
    copy: 'Copier',
    addForcast: 'Ajout Prévision',
    createConsensus: 'Créer consensus',
    filter: {
      label: 'Filtrer par label',
      author: 'Filtrer par auteur',
      date: 'Filtrer par date',
    },
    nodata: "Pas de prévision d'allocation.",
    printForecast: 'Imprimer prévision',
  },
  // Fridge check page
  fridge: {
    selectFridge: 'Choisir Frigo',
    filters: 'Filtres',
    byFridge: 'Par frigo',
    byType: 'Par type',
    productsPerPage: 'Produits par page:',
    todayReport: 'Poids total:',
    fridgeNotSelected: 'Frigo non sélectionné',
    name: 'Nom',
    trays: 'Plateau',
    weight: 'Poids',
    today: "Aujourd'hui (Poids/Correction)",
    type: 'Type',
    changeFridge: 'Changer frigo',
    lastSubmit: 'Dernière soumission par:',
    morning: 'Matin',
    evening: 'Soir',
    reported: 'Déclaré',
    correction: 'Correction',
    noData: 'Aucune donnée avec ces filtres:',
    total: 'Total',
  },
  // Growing plan page
  growingPlan: {
    harvestedWeight: 'Poids cueilli',
    targetWeight: 'Poids cible',
    selectYear: 'Choisir année',
    addGrowingPlan: 'Ajout stratégie de cueillette',
    addingNewGrowingPlan: 'Ajoutant stratégie de cueillette',
    growingProgramType: 'Type de programme de cueillette',
    selectRoom: 'Sélectionner salle',
    startDate: 'Date départ',
    endDate: 'Date fin',
    pickDateToChange: 'Choix date à changer',
    changeStartDate: 'Changer date départ',
    changeEndDate: 'Changer date de fin',
    indicators: 'Indicateurs',
    phaseID: 'Numéro de phase',
    areYouSure: 'Êtes-vous certain de vouloir effacer cette phase?',
    events: {
      title: 'Événements',
      areYouSure: 'Êtes-vous certain de vouloir effacer cet événement?',
      airing: 'Ajout événement aération',
      watering: 'Ajout événement arrosage',
      ruffling: 'Ajouter une note',
      steaming: 'Ajouter événement steaming',
    },
    time: 'Temps',
    volume: 'Volume',
    nematodes: 'Nématodes',
    duration: 'Durée',
    notes: 'Notes',
    statistics: 'Statistiques',
    activeFlushes: "Chasses d'eau actives",
    filling: 'Remplissage',
    week: 'Semaine',
  },
  // Default demand page
  defaultDemand: {
    addSeason: 'Ajout saison',
    addingNewSeason: 'Ajoutant une saison',
    seasonLabel: 'Étiquette de la saison',
    weekFrom: 'Semaine du',
    weekTo: 'Semaine au',
    inOrderToAdd: 'Pour accéder au planning par défaut, ajouter au moins une saison...',
    fromTo: 'De - à',
    deleteSeason: 'Effacer saison',
    areYouSure: 'Êtes-vous certain de vouloir effacer?',
    sortProducts: 'Trier produits',
  },
  // Active products page
  activeProducts: {
    importProduct: 'Importer produits',
    addProduct: 'Ajout produit',
    active: 'Actif',
    inactive: 'Inactif',
    productName: 'Nom du produit',
    selectProduct: 'Sélection produit',
    companyProductID: "Produit d'Entreprise ID",
    mushroomColor: 'Couleur champignon',
    mushroomSize: 'Type des tailles de champignons',
    punnetWeight: 'Poids barquette (kg)',
    punnetCount: 'Nombre de barquettes',
    totalProducts: 'Total produits',
    whiteNonBio: 'Blanc Non-Bio:',
    whiteBio: 'Blanc Bio:',
    brownNonBio: 'Brun Non Bio:',
    brownBio: 'Brun Bio:',
    dialog: {
      importing: 'Importation produit',
      addingNew: 'Ajout nouveau produit',
      updating: 'Mise à jour produit',
      addNew: 'Ajouter nouveau produit',
    },
  },
  // Filling data page
  fillingData: {
    createEvent: 'Créer événement',
    updateEvent: 'Mettre à jour événement',
    startDate: 'Date début',
    growingRoom: 'Salle',
    growingProgram: 'Programme de croissance',
    time: 'Temps',
    casingSupplier: 'Fournisseur terreau',
    substrateSupplier: 'Fournisseur substrat',
    strainType: 'Souche',
    caccingType: 'Rudoiement',
    tunnel: 'Tunnel',
    daysSinceSpawning: "Jours depuis l'ensemencement",
    fillingWeightPerM2: 'Poids remplissage par m2',
    phBeforeDelivery: 'Ph avant livraison',
    relativeHumidity: 'Humidité à la livraison',
    deliveryWeight: 'Poids livré',
    structure: 'Structure',
    fillingHeight: 'Épaisseur',
    filledSurface: 'Superficie remplie',
    dialog: {
      creating: 'Créer un nouvel événement',
      updating: 'Mise à jour événement',
    },
    total_harvested_of_period: 'Total cueilli sur la période',
    kg_harvested_per_square_meter: 'Kg cueilli par m2',
    kg_harvested_per_kg_substrate: 'Kg cueilli par kg/t de substrat',
    airing_after_filling: 'Aération après remplissage',
    airing_after_spawining: 'Aération après ensemencement',
    showFilters: 'Afficher les filtres',
    select: 'Sélectionner...',
    range: {
      title: 'Plage',
      currentYear: 'Année en cours',
      currentMonth: 'Mois en cours',
      currentQuarter: 'Trimestre en cours',
      from: 'De',
      to: 'À',
    },
    total: 'Total',
    watering: 'Arrosage',
    growing: 'Croissance',
    discarded: 'Terreau',
  },
  // Component translations
  menu: {
    profile: {
      label: 'Profil',
      icon: 'i-solar:user-bold-duotone',
      route: '/profile',
    },
    fridge: {
      label: 'Frigo',
      icon: 'i-solar:fridge-bold-duotone',
      route: '/fridge-check',
    },
    roomcheck: {
      label: 'Inspection Salles',
      icon: 'i-solar:checklist-line-duotone',
      route: '/',
    },
    notifications: {
      label: 'Notifications',
      icon: 'notifications',
      route: '/notifications',
    },
    roomcheckreview: {
      label: 'Consensus',
      icon: 'checklist',
      route: '/room-check-review',
    },
    roomcycles: {
      label: 'Cycles salles',
      icon: 'compost',
      route: '/room-cycles',
    },
    productionoverview: {
      label: 'Aperçu production',
      icon: 'compost',
      route: '/production-overview',
    },
    productionoverviewvertical: {
      label: 'Apperçu production Ver',
      icon: 'compost',
      route: '/production-overview-vertical',
    },
    growingprograms: {
      label: 'Programme de croissance',
      icon: 'compost',
      route: '/growing-programs',
    },
    assignpickers: {
      label: 'Sélection Cueilleurs',
      icon: 'person',
      route: '/assign-pickers',
    },
    harvestregions: {
      label: 'Régions',
      icon: 'table_chart',
      route: '/harvest-regions',
    },
    defaultregions: {
      label: 'Régions par défaut',
      icon: 'table_chart',
      route: '/default-harvest-regions',
    },
    statistics: {
      label: 'Statistiques',
      icon: 'show_chart',
      route: '/statistics',
    },
    pickinginstructions: {
      label: 'Instructions de cueillette',
      icon: 'more_horiz',
      route: '/picking-instructions',
    },
    programschedules: {
      label: 'Planification',
      icon: 'schedule',
      route: '/program-schedules',
    },
    growingprogramtypecreation: {
      label: 'Création des types de programme de croissance',
      icon: 'programmes',
      route: '/growing-program-type',
    },
    settings: {
      label: 'Paramètres',
      icon: 'i-solar:settings-bold-duotone',
      route: '/settings',
    },
    allocationForecasts: {
      label: 'Logs',
      icon: 'i-solar:clipboard-list-bold-duotone',
      route: '/allocation-forecasts',
    },
    forecast: {
      label: 'Allocation forecast',
      icon: 'person',
      route: '/forecast',
    },
  },
  // Unique dialogs
  dialog: {
    delete: {
      confirm: 'Confirmer suppression',
      title: 'Vous allez supprimer:',
    },
  },
  // Table collumns
  table: {
    color: 'Couleur',
    bio: 'Bio',
    name: 'Nom',
    weight: 'Poids',
    demand: 'Demande',
    carry: 'Report',
    carryOver: 'Report',
    fridge: 'Frigo',
    harvest: 'Cueillette',
    declass: 'Déclass',
    purchase: 'Achat',
    diff: 'Diff',
    code: 'Code',
    active: 'Actif',
    companyProduct: "Produit d'Entreprise",
    products: 'Produits',
    total: 'Total',
    dayTable: 'Table jours',
    order: 'Ordre',
  },
  mushrooms: {
    white: 'Blanc',
    whiteBio: 'Blanc Bio',
    brown: 'Brun',
    brownBio: 'Brun Bio',
  },
  // Unique buttons
  btn: {
    export: 'Export',
    edit: 'édition',
    save: 'Sauvegarde',
    cancel: 'Annuler',
    submit: 'Soumettre',
    submited: 'Soummis',
    add: 'Ajout',
    addNew: 'Ajout nouveau',
    update: 'Mise à jour',
    confirm: 'Confirmer',
    import: 'Import',
    inactive: 'Inactif',
    duplicate: 'Dupliquer',
    delete: 'Effacer',
    active: 'Actif',
    close: 'Fermer',
  },
  // Unique input labels and placeholders
  input: {
    search: 'Recherche',
    factory: 'Usine',
  },
  // Page titles used in the header of the screen
  pageTitle: {
    default: 'Defaut',
    dashboard: 'Tableau de Bord',
    profile: 'Profil',
    growingPlan: 'Plan de Croissance',
    fridgeCheck: 'Inspection Frigo',
    productionInput: 'Production Quotidienne',
    harvestForecastsList: 'Prévisions',
    harvestForecastsEdit: 'Éditer Prévisions',
    activeProducts: 'Produits Actifs',
    fillingData: 'Remplissage',
    forecasting: 'Prévisions',
    defaultDemand: 'Prévision Ventes',
    pickingInstructions: 'Instructions de cueillette',
    programSchedules: 'Planif. programmes',
    assignPickers: 'Assigner cueilleurs',
    harvestRegions: 'Régions de cueillette',
    defaultRegions: 'Régions par défaut',
    statistics: 'Statistiques',
    notifications: 'Notifications',
  },
  daynames: {
    day: 'Jour',
    days: 'Jours',
    week: 'Semaine',
    weeks: 'Semaines',
    today: "Aujourd'hui",
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    short: {
      mon: 'Lun',
      tue: 'Mar',
      wed: 'Mer',
      thu: 'Jeu',
      fri: 'Ven',
      sat: 'Sam',
      sun: 'Dim',
    },
  },
  monthnames: {
    month: 'Mois',
    months: 'Mois',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    short: {
      jan: 'Jan',
      feb: 'Fév',
      mar: 'Mar',
      apr: 'Avr',
      may: 'Mai',
      jun: 'Jun',
      jul: 'Jui',
      aug: 'Aoû',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Déc',
    },
  },
  mushroom: {
    'Brown Bio': 'Brun Bio',
    'White Bio': 'Blanc Bio',
    Brown: 'Brun',
    White: 'Blanc',
  },
};
