import { GrowingFillingEvent } from 'src/composables/useApi';

export function calculateTotalFilledM2(
  growFillingEvents: Ref<GrowingFillingEvent[]>,
  currentRoomId: string | undefined,
  growingProgramId: number,
  currentRoomDigit?: number
) {
  let total = 0;
  for (const g of growFillingEvents.value) {
    const gfe = g as unknown as {
      time: string;

      filled_surface: number;
      growing_program: {
        id: number;
        growing_room: { id: string; digit: number };
      };
    };

    const gp = gfe?.growing_program;

    if (!gp) continue;

    if (
      currentRoomDigit &&
      currentRoomDigit == gp.growing_room.digit &&
      gp.id == growingProgramId
    ) {
      total += gfe.filled_surface || 0;
    } else if (currentRoomId == gp.growing_room.id && gp.id == growingProgramId) {
      total += gfe.filled_surface || 0;
    }
  }
  return total;
}

export const useGrowingFillingEventsStore = defineStore('growing-filling-events-store', () => {
  const { data: growFillingEvents } = useTypedQuery('/crud/growing-filling-event');

  const growFillingEventsTotalM2 = computed(
    () => (currentRoomId: string, growingProgramId: number) => {
      if (!growFillingEvents.value) return 0;

      return calculateTotalFilledM2(growFillingEvents, currentRoomId, growingProgramId);
    }
  );
  return { growFillingEventsTotalM2, growFillingEvents };
});
