<template>
  <div>
    <TrayCheckDialog
      v-model="trayDialog"
      v-model:trays="selectedProductTrays"
      :loading="isMutatingSingleRecrod"
      @save="saveTraysToWeight"
    />
    <ChangeFridgeDialog
      v-model="tableDialog"
      :product="selectedItem.product"
      :record="selectedItem.record"
      :storageRooms="storageRooms"
      :storageRoomsById="storageRoomsById"
      :disable="isFetchingStorageRooms"
      :loading="isMutatingStorageUnit"
      @save="mutateStorageUnit"
    />
    <!-- Page header -->
    <div
      class="fixed top-0 w-full z-55 bg-white py-1 text-gray-6 border-b-1px border-b-solid border-accent flex justify-between px-4 items-center"
    >
      <h1 class="text-xl font-500 text-gray-6">
        <div>{{ $t('menu.fridge.label') }}</div>

        <NetworkStatus />
      </h1>

      <div class="flex items-center gap-4">
        <q-btn
          :disable="isFetchingRecords || !selectedFridge?.id || isSubmited"
          :loading="isSubmitting"
          @click="() => submit(toRef(newProductRecords))"
          :color="isSubmited ? 'green' : 'primary'"
          no-caps
          class="rounded-lg"
          :label="isSubmited ? '' : $t('btn.submit')"
          :icon="
            isSubmited ? 'i-solar:check-circle-bold-duotone' : 'i-solar:file-text-bold-duotone'
          "
        />
      </div>
    </div>

    <div class="bg-info p-2 pt-20" :class="{ 'pt-24': !isOnline }">
      <div class="flex gap-4 justify-between items-center mb-4">
        <FormSelect
          :model-value="selectedFridge"
          @update:model-value="
            (e) => {
              submitAsync(newProductRecords).then(() => {
                selectedFridge = e;
              });
            }
          "
          :loading="isSubmitting"
          :disable="isFetchingStorageRooms"
          :options="storageRooms"
          bgColor="white"
          label="Select fridge"
          label-slot
          class="text-xs flex-auto lg:flex-1"
        >
          <template #prepend>
            <q-icon color="primary" name="i-solar:fridge-bold-duotone" />
          </template>
        </FormSelect>
        <div class="bg-white rounded-lg p-2 flex flex-col justify-center items-center">
          <ToggleIcons
            v-model="isEvening"
            :colors="['orange', 'blue-9']"
            icon-color="yellow"
            :icons="['wb_sunny', 'i-solar:moon-sleep-bold']"
          />
          <div class="text-xs w-12">
            {{ !isEvening ? 'Morning' : 'Evening' }}
          </div>
        </div>
        <q-btn
          @click="isExpanded = !isExpanded"
          color="primary"
          flat
          dense
          icon="i-solar:filter-bold-duotone"
        />
      </div>

      <div
        :class="{
          'h-auto': isExpanded && (filters.mushroomType || filters.storageRoom),
          'h-22': isExpanded,
          'h-0': !isExpanded,
        }"
        class="overflow-hidden transition-all"
      >
        <TableFilters
          :disable="isFetchingMushroomTypes || isFetchingStorageRooms"
          v-model="filters"
          :storageRooms="storageRooms"
          :mushroomTypes="mushroomTypes"
        />
      </div>

      <div v-if="newProductRecords.length">
        <q-table
          :rows-per-page-options="[25, 50, 100, 200, 300]"
          :pagination="{
            rowsPerPage: 200,
          }"
          wrap-cells
          dense
          flat
          :rows="sortedNewProductRecords"
          :columns="columns"
          class="rounded-lg py-2 text-gray-6"
        >
          <template v-slot:body="props">
            <q-tr
              :class="{
                'opacity-70 bg-gray/20': isDisabled(props.row),
              }"
              v-show="filterRows(props.row)"
              :props="props"
            >
              <TableProductRow
                class="text-xs max-w-[calc(100vw-60vw)] xs:max-w-unset"
                @click="openTableDialog(productById?.[props.row.product_id], props.row)"
                :="{
                props,
                product: productById?.[props.row.product_id]!,
                record: {...props.row, product_storage_room_id: props.row.product_storage_room_id || selectedFridge.id},
                todayRecord: getTodayRecord(props.row),
              }"
              />

              <q-td
                class="w-min"
                @click="
                  isDisabled(props.row)
                    ? () => null
                    : openTrayDialog(productByIdDict?.[props.row.product_id]!, props.row)
                "
                key="trays"
                :props="props"
              >
                <TrayBtn
                  :="{
                    disable: isDisabled(props.row),
                    weight: props.row.weight,
                    value: calcTraySum(props.row),
                  }"
                />
              </q-td>

              <q-td key="weight" :props="props">
                <TableProductInput
                  :readonly="isDisabled(props.row)"
                  @update:model-value="addToAlreadySubmitted(props.row), (isSubmited = false)"
                  v-model.number="props.row.weight"
                />
              </q-td>
            </q-tr>
          </template>

          <template v-slot:bottom="scope">
            <TableFooter :is-no-data-available="isNoDataAvailable" :scope="scope" />
          </template>
        </q-table>

        <FridgeCheckSubbmited
          class="mt-4"
          :user="lastUser"
          :date-string="
            sortedRecords?.[0]?.modified_at
              ? new Date(sortedRecords?.[0]?.modified_at).toLocaleString()
              : ''
          "
        />
      </div>
      <div v-else class="flex justify-center mt-12">
        <q-spinner-puff size="lg" color="primary" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { QTableColumn, date } from 'quasar';
  import { ProductRecord } from 'src/domain/models/types';
  import { useCheckFidgePageService } from '../composables/service';

  import { useTrays } from '../composables/trays-composable';

  defineOptions({ name: 'FridgeCheck' });

  const { isOnline } = useNetwork();

  const columns: QTableColumn[] = [
    {
      name: 'product_id',
      align: 'left',
      field: 'product_id',
      label: 'Name',
      sortable: true,
      headerClasses: '',
    },
    {
      name: 'trays',
      align: 'center',
      field: 'trays',
      label: 'Trays',
      sortable: false,
      headerClasses: 'max-w-50px',
    },
    { name: 'weight', align: 'center', field: 'weight', label: 'Weight', sortable: true },
  ];

  const {
    today,
    isEvening,
    todayRef,
    filters,
    selectedItem,
    tableDialog,
    sortedRecords,
    isSubmitting,
    isFetchingRecords,
    isMutatingStorageUnit,
    isMutatingSingleRecrod,
    storageRoomsByIdDict,
    isFetchingStorageRooms,
    storageRooms,
    productByIdDict,
    selectedFridge,
    openTableDialog,
    initRecords,
    mutateStorageUnit,
    submit,
    addToAlreadySubmitted,
    filterRows,
    submitAsync,
    isFetchingMushroomTypes,
    mushroomTypes,
    isSubmited,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTodayRecord,
    lastUser,
    suspenseRecords,
    suspenseProducts,

    records,
  } = useCheckFidgePageService();

  const currentDay = new Date(today.value);

  const isDisabled = (row: ProductRecord) => {
    return (
      !!row.product_storage_room_id &&
      !!selectedFridge?.value.id &&
      selectedFridge?.value.id !== row.product_storage_room_id
    );
  };

  const { suspenseStorageRooms } = useStorageRoomsStore();

  const isExpanded = ref(false);
  const newProductRecords = ref<Omit<ProductRecord, 'id'>[]>([]);

  const sortedNewProductRecords = computed(() =>
    newProductRecords.value.sort((a, b) => {
      const productA = productByIdDict.value?.[a.product_id];
      const productB = productByIdDict.value?.[b.product_id];
      return (productA?.display_order || 0) - (productB?.display_order || 0);
    })
  );

  const { openTrayDialog, saveTraysToWeight, trayDialog, productTrays, selectedProductTrays } =
    useTrays(newProductRecords, selectedFridge);

  // was cached
  const productById = computed(() => productByIdDict.value);
  const storageRoomsById = computed(() => storageRoomsByIdDict.value);

  const isNoDataAvailable = computed(
    () =>
      newProductRecords.value.length ===
      newProductRecords.value.map((e) => filterRows(e)).filter((e) => !e).length
  );

  // it is used...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const calcTraySum = (row: ProductRecord) =>
    productTrays.value[row.product_id]?.trays?.reduce((acc, e) => (acc += e.weight || 0), 0);

  watch(
    isEvening,
    () => {
      isEvening.value
        ? (todayRef.value = date.formatDate(date.addToDate(currentDay, { days: 1 }), 'DD/MM/YYYY'))
        : (todayRef.value = date.formatDate(currentDay, 'DD/MM/YYYY'));
    },
    { immediate: true }
  );

  watch(
    [records, todayRef],
    async () => {
      initRecords(newProductRecords);
      await Promise.allSettled([suspenseProducts(), suspenseRecords(), suspenseStorageRooms()]);
      initRecords(newProductRecords);
    },
    { immediate: true }
  );
</script>
