<template>
  <DayInfoItem.define #="{ day }">
    <q-item class="px-0 border-b-solid border-b-1px border-accent mb-2">
      <q-item-section>
        <q-item-label class="font-500"
          >{{ day.growing_phase.label }} - D{{
            daysInfo[day.growing_phase.id]?.[formatDate(new Date(day.date))]?.day_number
          }}
        </q-item-label>
        <q-item-label caption class="flex items-center text-orange">
          <q-icon name="i-solar:calendar-bold-duotone" size="xs" class="mr-2" />
          {{ formatDate(new Date(day.date)) }}
        </q-item-label>
      </q-item-section>

      <q-item-section side top>
        <q-item-label caption>
          {{ formatNumber(day.harvested_weight ? +day.harvested_weight : 0) }} kg</q-item-label
        >
      </q-item-section>
    </q-item>
  </DayInfoItem.define>

  <DayInfo.define #="{ phase, isEmpty }">
    <template v-if="!isEmpty">
      <DayInfoItem.reuse v-for="day of sortDays(phase)" :day="day" :key="day.date" />
    </template>
    <template v-else>
      <q-item class="px-0 border-b-solid border-b-1px border-accent mb-2">
        <q-item-section>
          <q-item-label>{{ phase.label }}</q-item-label>
          <q-item-label>{{ $t('roomcheck.noPreviousDays') }}</q-item-label>
        </q-item-section>
        <q-item-section side top> </q-item-section>
      </q-item>
    </template>
  </DayInfo.define>

  <q-dialog v-model="model">
    <q-card class="!rounded-xl text-gray-6">
      <q-card-section>
        <div class="text-h6">{{ $t('roomcheck.harvestInfo') }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-list class="min-w-300px">
          <DayInfo.reuse
            v-for="phase of growingPhases"
            :key="phase.label"
            :phase="phase"
            :isEmpty="!phase.value.previousDays.length"
          />
        </q-list>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Close" no-caps color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
  import { HarvestInfo, PreviousDay } from 'src/stores/growing-phase-store';
  import { formatDate, formatNumber } from 'src/utils/utils';

  type DaysInfo = { [key: number]: { [key: string]: { day_number: number } } };

  const model = defineModel<boolean>();
  defineProps<{ growingPhases: HarvestInfo[]; daysInfo: DaysInfo }>();

  const DayInfoItem = createReusableTemplate<{ day: PreviousDay }>();
  const DayInfo = createReusableTemplate<{ phase: HarvestInfo; isEmpty: boolean }>();

  const sortDays = ({ value: { previousDays } }: HarvestInfo) =>
    previousDays.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
</script>
