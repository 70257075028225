<script setup lang="ts">
import { ref } from 'vue';
import SelectorWorkspace from './SelectorWorkspace.vue';
const modal = ref(false);

function onOpenDialog() {
  modal.value = true;
}
</script>

<template>
  <q-item clickable v-ripple @click="onOpenDialog()">
    <q-item-section avatar>
      <q-icon color="gray-6" name="i-solar:circle-top-up-line-duotone" />
    </q-item-section>
    <q-item-section>{{ $t('profile.settings.workspace.title') }}</q-item-section>
  </q-item>
  <q-dialog v-model="modal" full-width>
    <q-card>
      <SelectorWorkspace />
    </q-card>
  </q-dialog>
</template>
