<template>
  <div v-if="ready">
    <Teleport :to="to">
      <slot />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
  type PropsType = { delay?: number; to: string };
  const props = withDefaults(defineProps<PropsType>(), { delay: 10 });

  const ready = ref(false);
  onMounted(() => {
    nextTick(() => {
      setTimeout(() => {
        ready.value = true;
      }, props.delay);
    });
  });
</script>
