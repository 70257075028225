<template>
  <div class="mb-4 pb-2 border-b-solid border-accent border-b-1px">
    <div class="text-h6 flex justify-between items-center">
      <div class="text-gray-6">
        <div>{{ date.dayName }}</div>
        <div class="text-2.5 text-orange-9 leading-3">
          {{ date.dateString }}
        </div>
      </div>
      <slot v-if="!emptyDay" name="right-content">
        <span>
          <div class="justify-center">
            <div class="text-grey small-text">
              {{ roomHarvastDay?.growing_phase }}
            </div>
            <div class="text-grey small-text">D{{ roomHarvastDay?.day_number }}</div>
          </div>
        </span>
        <span v-if="isByPickers" class="text-caption">
          <q-icon color="gray-6" name="i-solar:users-group-rounded-line-duotone" size="xs" />
          {{ totalFn ? totalFn(date.dateString, 'min') : total('roomcheck', date.dateString, 0) }}
          -
          {{
            totalFn ? totalFn(date.dateString, 'max') : total('roomcheck', date.dateString, 1)
          }}</span
        >
      </slot>
    </div>
    <slot name="after" />
  </div>
</template>

<script setup lang="ts">
  import { RoomHarvastDay } from 'src/stores/growing-phase-store';
  const { total } = useAllocationForecastStore();

  defineProps<{
    isByPickers?: boolean;
    emptyDay?: boolean;
    roomHarvastDay?: Pick<RoomHarvastDay, 'growing_phase' | 'day_number'>;
    totalFn?: (date: string, type: 'min' | 'max') => number;
    date: {
      dayName: string;
      dateString: string;
    };
  }>();
</script>

<style lang="scss"></style>
