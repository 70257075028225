<template>
  <div>
    <!-- Dynamicaly used icons stil need to be staticaly referenced. This is safe list  -->
    <div
      class="max-w-10px hidden i-solar:trash-bin-minimalistic-bold-duotone i-solar:close-circle-line-duotone i-solar:database-bold-duotone i-solar:list-check-line-duotone i-solar:plate-bold-duotone i-solar:user-bold-duotone i-solar:checklist-line-duotone i-solar:clipboard-list-bold-duotone i-solar:fridge-bold-duotone i-solar:settings-bold-duotone i-solar:home-angle-bold-duotone i-solar:notification-unread-bold-duotone i-solar:user-check-bold-duotone i-solar:layers-minimalistic-bold-duotone i-solar:lightbulb-bolt-bold-duotone i-solar:layers-minimalistic-line-duotone i-solar:square-sort-vertical-bold-duotone i-solar:pie-chart-2-bold-duotone i-solar:menu-dots-square-broken i-solar:documents-minimalistic-bold-duotone i-solar:rewind-back-bold-duotone i-solar:rewind-forward-bold-duotone"
    />

    <router-view />
  </div>
</template>

<script setup lang="ts">
  import { useQuasar } from 'quasar';
  import 'uno.css';

  const $q = useQuasar();
  $q.iconMapFn = (iconName) =>
    iconName.startsWith('i-solar') ? { cls: iconName } : { icon: iconName };
</script>
