import { useMutation } from '@tanstack/vue-query';
import { date } from 'quasar';
import { api } from 'src/boot/axios';
import { Product } from 'src/components/models';
import { MushroomType, ProductRecord, ProductStorageRoom } from 'src/domain/models/types';
import { backendGETFormat } from 'src/utils/utils';
import { MaybeRef } from 'vue';

export const useCheckFidgePageService = createSharedComposable(() => {
  const { getters: srGetters, suspenseStorageRooms } = useStorageRoomsStore();
  const { storageRoomsByIdDict, isFetchingStorageRooms, storageRooms } = toRefs(srGetters);

  const { getters: pGetters, suspenseProducts } = useProductStore();
  const { products, productByIdDict } = toRefs(pGetters);

  const { getters: mushroomTypeGetters } = useMushroomTypesStoreV2();
  const { mushroomTypes, isFetchingMushroomTypes } = toRefs(mushroomTypeGetters);

  const todayRef = ref(date.formatDate(date.addToDate(new Date(), { days: 0 }), 'DD/MM/YYYY'));
  const today = computed(() => date.extractDate(todayRef.value, 'DD/MM/YYYY'));

  const { getters: fcGetters, factoryConfigSuspesnse } = useFactoryStore();
  const { factoryWithConfig } = toRefs(fcGetters);

  factoryConfigSuspesnse().then(() => {
    isEvening.value = !!factoryWithConfig.value?.factory_config?.execute_fridge_check_in_morning;
  });

  const from = new Date(today.value);
  from.setDate(from.getDate() - 3);

  const isEvening = ref(false);
  const isMorning = computed(() => !isEvening.value);

  const filters = reactive({
    mushroomType: null as null | MushroomType,
    storageRoom: null as null | ProductStorageRoom,
    date: {
      from: backendGETFormat(from),
      to: backendGETFormat(today.value),
    },
  });

  const selectedFridge = useLocalStorage('selectedFridge', {} as ProductStorageRoom);
  suspenseStorageRooms().then(() => {
    if (storageRooms.value?.length && !selectedFridge.value.id)
      selectedFridge.value = storageRooms.value[0];
  });

  const {
    data: records,
    refetch: refetchProductRecords,
    isFetching: isFetchingRecords,
    suspense: suspenseRecords,
  } = useTypedQuery('/v2/product-records', {
    queryKey: [
      computed(() => ({
        queryParams: {
          from: filters.date.from,
          to: backendGETFormat(
            date.addToDate(date.extractDate(filters.date.to, 'DD/MM/YYYY'), { days: 1 })
          ),
        },
      })),
    ],
  });

  const selectedItem = ref<{ product: Product | undefined; record: ProductRecord | undefined }>({
    product: undefined,
    record: undefined,
  });
  const tableDialog = ref(false);
  const alreadySubmittedChangedIds = ref(new Set<number>());

  function openTableDialog(item?: Product, record?: ProductRecord) {
    if (!item || !record) return;
    tableDialog.value = true;
    selectedItem.value.product = { ...item };
    selectedItem.value.record = { ...record };
  }

  function addToAlreadySubmitted(productRecord: ProductRecord) {
    const todayRecord = getTodayRecord(productRecord);
    if (todayRecord) {
      alreadySubmittedChangedIds.value.add(todayRecord.id);
    }
  }

  const getMushroomTypesFromProducts = computed(() =>
    products.value?.reduce((acc, e) => {
      const label = `${e.mushroom_color}${e.bio ? ' - Bio' : ''}`;
      if (!acc.find((e) => e.label === label))
        acc.push({
          label,
          mushroom_color: e.mushroom_color,
          bio: e.bio,
        });
      return acc;
    }, [] as Pick<MushroomType, 'label' | 'bio' | 'mushroom_color'>[])
  );

  const sortedRecords = computed(() =>
    // sort by modified_at desc
    records.value?.concat().sort((a, b) => {
      const aDate = new Date(a.modified_at);
      const bDate = new Date(b.modified_at);
      return bDate.getTime() - aDate.getTime();
    })
  );

  const lastUser = computedAsync(async () => {
    const lastRecord = sortedRecords.value?.[0];
    if (!lastRecord || !lastRecord.user_id) return null;
    return (await api.get<string>(`/crud/user/${lastRecord.user_id}/username`)).data;
  });

  const todayRecords = computed(() =>
    sortedRecords.value?.filter((e) => e.date === backendGETFormat(today.value))
  );

  const getTodayRecord = (productRecord?: Pick<ProductRecord, 'product_id'>) =>
    todayRecords.value?.find((e) => e.product_id === productRecord?.product_id);

  const order: { [key: string]: number } = {
    'White-false': 0,
    'White-true': 1,
    'Brown-false': 2,
    'Brown-true': 3,
  };

  function initRecords(newProductRecords: Ref<Omit<ProductRecord, 'id'>[]>, forceNew = false) {
    if (!products.value?.length) return;
    const date = backendGETFormat(today.value);

    newProductRecords.value = products.value
      .concat()
      .sort((a, b) => {
        const aKey = `${a.mushroom_color}-${a.bio}`;
        const bKey = `${b.mushroom_color}-${b.bio}`;

        const orderByTypeBio = order[aKey] - order[bKey];
        if (orderByTypeBio !== 0) {
          return orderByTypeBio;
        } else {
          return a.label.localeCompare(b.label);
        }
      })
      .map((e) => {
        const oldProduct = newProductRecords.value.find((r) => r.product_id === e.id);

        let weight = oldProduct?.date === date ? oldProduct?.weight : null;
        let correction = null;
        let product_storage_room_id = oldProduct?.product_storage_room_id;

        const todayRecord = getTodayRecord({ product_id: e.id });

        if (todayRecord) {
          weight = todayRecord.weight;
          product_storage_room_id = todayRecord.product_storage_room_id;
          correction = todayRecord?.correction;
        }

        if (forceNew) {
          weight = null;
          correction = null;
        }

        return { correction, product_id: e.id, date, product_storage_room_id, weight } as Omit<
          ProductRecord,
          'id'
        >;
      });
  }

  const queryInvlidator = useApiQueryInvalidationByRoute();

  const { mutate: mutateStorageUnit, isPending: isMutatingStorageUnit } = useMutation({
    mutationFn: async () => {
      if (!selectedItem.value.record) return Promise.resolve(null);

      const record = { ...selectedItem.value.record, morning: isMorning.value };
      const todayRecord = getTodayRecord(record);

      if (!todayRecord) {
        await api.post('/v2/product-records', record);
      } else {
        await api.put(`/v2/product-records/${todayRecord.id}`, {
          ...record,
          id: todayRecord.id,
        });
      }

      return await queryInvlidator(['/v2/product-records']);
    },
    onSuccess() {
      tableDialog.value = false;
    },
  });

  const { mutateAsync: mutateProductRecord, isPending: isMutatingSingleRecrod } = useMutation({
    mutationFn: (pr: Partial<ProductRecord>) => {
      const record = { ...pr, morning: isMorning.value };

      if (!record.id) {
        if (!record.weight) return Promise.resolve({ data: record });
        delete record.id;
        return api.post<ProductRecord>('/v2/product-records', record);
      }

      return api.put<ProductRecord>(`/v2/product-records/${record.id}`, {
        ...record,
        user_id: authStore.authUser?.id,
      });
    },
    onSuccess() {
      queryInvlidator(['/v2/product-records']);
    },
  });

  const authStore = useAuthStore();
  const isSubmited = ref(false);
  const {
    mutate: submit,
    isPending: isSubmitting,
    mutateAsync: submitAsync,
  } = useMutation({
    mutationFn: (newProductRecords: MaybeRef<Omit<ProductRecord, 'id'>[]>) => {
      const request = Promise.allSettled(
        unref(newProductRecords)
          .filter((e) => e.product_id > 0)
          .map((r) => {
            const record = { ...r, morning: isMorning.value };
            record.weight = typeof record.weight === 'number' ? record.weight : 0;
            const todayRecord = getTodayRecord(record);

            if (!record.product_storage_room_id) {
              record.product_storage_room_id = selectedFridge.value.id;
            }

            if (todayRecord) {
              if (alreadySubmittedChangedIds.value.has(todayRecord.id)) {
                isSubmited.value = true;
                return api.put(`/v2/product-records/${todayRecord.id}`, {
                  ...record,
                  user_id: authStore.authUser?.id,
                  id: todayRecord.id,
                });
              } else {
                return Promise.resolve();
              }
            }
            if (!record.weight) return Promise.resolve();
            isSubmited.value = true;
            return api.post('/v2/product-records', record);
          })
      );

      return request;
    },

    onSuccess: () => {
      queryInvlidator(['/v2/product-records']);
      alreadySubmittedChangedIds.value.clear();
    },
  });

  function updateStorage(newProductRecords: Ref<Omit<ProductRecord, 'id'>[]>) {
    if (!selectedItem.value.record) return;

    const r = newProductRecords.value.find((e) => e.product_id === selectedItem.value.product?.id);
    if (!r) return;
    r.product_storage_room_id = selectedItem.value.record.product_storage_room_id;
  }

  function filterRows(
    record: Pick<
      ProductRecord & { proxyId?: number },
      'product_storage_room_id' | 'product_id' | 'proxyId'
    >
  ) {
    const product = productByIdDict.value?.[record.proxyId || record.product_id];
    if (!product) return true;

    let show = true;

    if (filters.mushroomType) {
      show =
        show &&
        product.bio === filters.mushroomType.bio &&
        product.mushroom_color === filters.mushroomType.mushroom_color;
    }

    if (filters.storageRoom) {
      show = show && record.product_storage_room_id === filters.storageRoom.id;
    }

    return show;
  }

  return {
    isMutatingSingleRecrod,
    mutateProductRecord,
    selectedFridge,
    getMushroomTypesFromProducts,
    filterRows,
    filters,
    submitAsync,
    suspenseProducts,
    suspenseRecords,
    selectedItem,
    tableDialog,
    records,
    isFetchingRecords,
    sortedRecords,
    todayRecords,
    isMutatingStorageUnit,
    storageRoomsByIdDict,
    isFetchingStorageRooms,
    storageRooms,
    products,
    productByIdDict,
    mushroomTypes,
    isFetchingMushroomTypes,
    updateStorage,
    mutateStorageUnit,
    openTableDialog,
    refetchProductRecords,
    isSubmited,
    initRecords,
    getTodayRecord,
    today,
    addToAlreadySubmitted,
    submit,
    isSubmitting,
    lastUser,
    todayRef,
    alreadySubmittedChangedIds,
    isEvening,
  };
});
