import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance } from 'axios';
import { useCookies } from '@vueuse/integrations/useCookies';
import { handleApiError, notifyError } from 'src/utils/errorHandler';

export const TOKEN_COOKIE_NAME = '_Secure-auth.session-token';
const { get } = useCookies([TOKEN_COOKIE_NAME]);

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
  baseURL: process.env.API,
  //withCredentials: false,
  /*
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })*/
});

api.interceptors.request.use((config) => {
  const token = get(TOKEN_COOKIE_NAME);
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  /*config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Methods'] = '*';
  config.headers['Access-Control-Allow-Headers'] = '*';*/
  return config;
});

// add global error handler
api.interceptors.response.use(
  (r) => r,
  (err) => {
    const e = handleApiError(err);
    // Dirty fix for duplicate entry errors, look at post-put-runner.ts for more info
    // if (!('' + (err?.response?.data?.error?.message || e)).includes('Existing'))
    const { isOnline } = useNetwork();
    if (isOnline.value) {
      notifyError(err?.response?.data?.error?.message || e);
    }

    return Promise.reject(err);
  }
);

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
