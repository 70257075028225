import { Dialog, QDialogOptions } from 'quasar';

const sharedDialogOptions: QDialogOptions = {
  html: true,
  title: /* html*/ `
  <div class="flex items-center gap-2">
    <div class="text-3xl i-un-exclamation-circle text-negative">.</div>
    <div> Confirm Action? </div>
  </div>
 `,
  ok: {
    label: 'Yes',
    color: 'primary',
    noCaps: true,
    padding: '0.35rem 1rem',
  },
  cancel: {
    flat: true,
    color: 'defaultText',
    noCaps: true,
    padding: '0.35rem 1rem',
    class: 'rounded-lg',
  },
};

const dialogs = {
  danger: {
    ...sharedDialogOptions,
    title: /* html*/ `
    <div class="flex items-center gap-2">
      <div class="text-3xl i-solar:trash-bin-minimalistic-bold-duotone text-red">.</div>
      <div>Confirm Action? </div>
    </div>`,
    ok: {
      label: 'Delete',
      color: 'red',
      noCaps: true,
      class: 'rounded-lg',
      padding: '0.35rem 1rem',
    },
  },
  positive: {
    ...sharedDialogOptions,
    title: /* html*/ `
    <div class="flex items-center gap-2">
      <div class="text-3xl i-un-check-circle text-positive">.</div>
      <div> Confirm Action? </div>
    </div>`,
  },
};

export const useConfirmModal = (
  message: string,
  prebuiltDialogs: keyof typeof dialogs,
  opts: QDialogOptions = {}
) => Dialog.create({ message, ...dialogs[prebuiltDialogs], ...opts });
