export interface Todo {
  id: number;
  content: string;
}

export interface Meta {
  totalCount: number;
}
export interface GrowingProgram {
  label: string;
  mushroomType: string;
  growingRoom: number;
  growingPhases: Array<GrowingPhase>;
}

export interface GrowingPhase {
  id: number;
  label: string;
  start: string;
  end: string;
}

export interface Room {
  id: string;
  digit: number;
  roomLength?: number;
  roomWidth?: number;
  roomHeight?: number;
  label?: string;
}

export interface Forecast {
  id: string;
  date: Date;
  author: string;
}

export enum RoomStatus {
  Empty,
  NeedHelp,
  Reserves,
  CheckLater,
}

export interface SettingListItem {
  label: string;
  description: string;
  route: string;
}

export interface Product {
  id: number;
  label: string;
  code: string;
  active: boolean;
  weight: number;
  display_order: number;
  bio: boolean;
  mushroom_color: string;
  company_product_id: number;
}
