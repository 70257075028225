<template>
  <div class="cursor-pointer" :class="{ 'flex flex-nowrap items-center gap-1': flex }">
    <q-btn
      :disable="disable"
      @click="$emit('click')"
      padding="0"
      flat
      dense
      :color="isTraysWeightMissmatch ? 'red' : value ? 'primary' : ''"
      icon="i-solar:clipboard-list-bold-duotone"
    />
    <div
      :class="{
        'text-red': isTraysWeightMissmatch,
        'text-green-9': !isTraysWeightMissmatch,
        'mt-3px': flex,
      }"
      class="text-center font-bold text-2.4"
    >
      {{ value ? formatNumber(value) + ' kg' : '' }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { formatNumber } from 'src/utils/utils';

  const props = defineProps<{
    disable?: boolean;
    weight?: number;
    value?: number;
    flex?: boolean;
  }>();

  const isTraysWeightMissmatch = computed(() => {
    return props.weight && props.value && props.weight !== props.value;
  });
</script>

<style scoped></style>
