import { defineStore } from 'pinia';

import { useJwt } from '@vueuse/integrations/useJwt';
import { useCookies } from '@vueuse/integrations/useCookies';
import { JwtPayload } from 'jwt-decode';
import { TOKEN_COOKIE_NAME, api } from '../boot/axios'; // Import the axios instance created in the boot file
import { commonState } from './mixins/commonState';
import { Screen } from 'quasar';
import { FactoryConfig } from 'src/domain/models/types';

const cookies = useCookies(['locale']);

type UserJWTPayload = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  factory_id: string;
  roles: string[];
};

function getUserFromCookie(payload: JwtPayload | null) {
  const p = payload as UserJWTPayload;

  return p
    ? {
        id: p.id,
        username: p.username,
        firstName: p.first_name,
        lastName: p.last_name,
        factoryId: p.factory_id,
        roles: p.roles,
      }
    : null;
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: getUserFromCookie(useJwt(cookies.get(TOKEN_COOKIE_NAME)).payload.value),
    returnUrl: null,
    ...commonState(),
  }),
  getters: {
    authUser: (state) => {
      return state.user;
    },

    authUsername: (state) => {
      if (state.user) {
        if (state.user.firstName == null || state.user.lastName == null) {
          return state.user.username;
        } else {
          return state.user.firstName.toUpperCase() + ' ' + state.user.lastName.toUpperCase();
        }
      }
      return null;
    },
    isAdmin: (state) => {
      if (state.user && state.user.roles) {
        return state.user.roles.includes('ROLE_ADMIN');
      }
      return false;
    },
  },
  actions: {
    async login(username: string, password: string) {
      this.fetching = true;
      this.fetchError = null;
      try {
        const { data } = await api.post('login_check', {
          username,
          password,
        });
        // const data = {
        //   token: cookie
        // }
        const dev = import.meta.env.MODE === 'development';
        cookies.set(TOKEN_COOKIE_NAME, data.token, {
          secure: dev ? false : true,
          sameSite: dev ? 'lax' : 'strict',
        });
        const { payload } = useJwt(data.token);
        this.user = getUserFromCookie(payload.value);
        console.log(this.user);
        if (this.returnUrl) {
          this.router.push({ path: this.returnUrl });
        } else {
          if (Screen.lt.sm) {
            this.router.push({ path: '/' });
          } else {
            this.router.push({ path: '/desktop/room-check' });
          }
        }
      } catch (error: any) {
        this.fetchError = error.message;
      }
      this.fetching = false;
    },
    logout() {
      this.user = null;
      cookies.set(TOKEN_COOKIE_NAME, null);
      localStorage.removeItem('selectedFridge');
      localStorage.removeItem('submitButton');
      localStorage.removeItem('roomcheck');
      localStorage.removeItem('local-cache/product-trays');
      this.router.push({ path: '/login' });
      window.location.replace('/#/login');
      window.location.reload();
    },
  },
});
