import { RouteRecordRaw } from 'vue-router';

import FridgeMobile from 'pages/fridge-check/views/mobile.vue';
import ProfilePage from 'src/pages/ProfilePage.vue';
import RoomCheckPage from 'pages/room-check/index.vue';
import AllocationPage from 'pages/allocation-forecasts/index.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'Fridge Check',
        path: 'fridge-check',
        component: FridgeMobile,
      },
      {
        name: 'RoomCheck',
        path: '',
        component: RoomCheckPage,
      },
      { path: 'assign-pickers', component: () => import('pages/AssignPickersPage.vue') },
      {
        path: 'room-check-review',
        component: () => import('src/pages/room-check/preview/index.vue'),
        meta: { returnpage: '/allocation-forecasts' },
      },
      {
        path: 'allocation-forecasts',
        name: 'AllocationForecasts',
        component: AllocationPage,
      },
      {
        path: 'forecast/:forecastid',
        component: () => import('src/pages/room-check/index.vue'),
        meta: { returnpage: '/allocation-forecasts' },
      },
      { path: 'profile', component: ProfilePage },
      {
        path: 'consensus',
        component: () => import('src/pages/ConsensusPage.vue'),
      },
    ],
  },
  {
    path: '/desktop',
    component: () => import('layouts/DesktopLayout.vue'),
    children: [
      {
        path: 'pickers-list',
        component: () => import('pages/pickers-list/index.vue'),
      },
      {
        path: 'room-check',
        component: () => import('pages/room-check/index.vue'),
      },
      {
        path: 'profile',
        component: () => import('pages/ProfileV2Page.vue'),
      },
      {
        path: 'forecasting',
        component: () => import('src/pages/forecasting/index.vue'),
      },
      {
        path: 'growing-cycle-data',
        component: () => import('src/pages/filling-data/index.vue'),
      },
      {
        path: 'active-products',
        component: () => import('src/pages/active-products/index.vue'),
      },
      {
        path: 'default-demand',
        component: () => import('src/pages/default-demand/index.vue'),
      },
      {
        path: 'growing-plan-v1',
        component: () => import('src/pages/growing-plan/views/desktop.vue'),
      },
      {
        path: 'growing-plan',
        component: () => import('src/pages/growing-plan-v2/index.vue'),
      },
      {
        path: 'growing-plan-old',
        component: () => import('src/pages/GrowingProgramsOverviewPage.vue'),
      },
      { path: 'production-input', component: () => import('src/pages/ProductionInputPage.vue') },

      { path: 'room-cycles', component: () => import('pages/RoomCyclesPage.vue') },
      { path: 'harvest-regions', component: () => import('src/pages/HarvestRegionsPage.vue') },
      { path: 'settings', component: () => import('src/pages/settings/SettingsPage.vue') },
      {
        path: 'fridge-check',
        component: () => import('src/pages/fridge-check/views/desktop.vue'),
      },
      {
        path: 'settings/:mainSetting',
        component: () => import('src/pages/settings/SettingsListPage.vue'),
      },
      {
        path: 'settings/:mainSetting/:crudSetting',
        component: () => import('src/pages/settings/SettingsCrudPage.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('pages/LoginPage.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
