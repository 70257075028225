import { backendGETFormat } from 'src/utils/utils';

export const useGrowingPhaseStoreV2 = defineStore('growing-phase-store', () => {
  const {
    data,
    suspense: currentGrowingPhaseSuspense,
    isFetching: isFetchingGP,
  } = useTypedQuery('/growing-phases/current');

  const getCurrentGrowingPhaseByRoom = computed(
    () => (factoryId: string, roomDigit: number) =>
      data.value?.find(
        (e) =>
          e.growing_program?.growing_room.digit === roomDigit &&
          e.growing_program.factory_id === factoryId
      )
  );

  const dayBioColorHarvestWeightDict = computed(() => {
    const dict: Record<string, number> = {};
    data.value?.forEach((e) => {
      const mushroom_type = e.growing_program?.mushroom_type;

      const color = mushroom_type?.mushroom_color;
      const bio = mushroom_type?.bio;

      e.harvest_days?.forEach((harvest_day) => {
        const key = `${backendGETFormat(harvest_day.date)}-${color}-${bio}`;

        if (!(key in dict)) {
          dict[key] = 0;
        }

        dict[key] += harvest_day.harvested_weight || 0;
      });
    });

    return dict;
  });

  const getHarvestWeightByColorBio = ({
    mushroom_color,
    bio,
  }: {
    mushroom_color: string;
    bio: boolean;
  }) => {
    return data.value
      ?.filter(
        (e) =>
          e.growing_program?.mushroom_type.bio === bio &&
          e.growing_program?.mushroom_type.mushroom_color === mushroom_color
      )
      .flatMap((e) => e.harvest_days)
      .reduce((acc, curr) => acc + (curr?.harvested_weight || 0), 0);
  };

  return {
    getHarvestWeightByColorBio,
    currentGrowingPhaseSuspense,
    getters: { getCurrentGrowingPhaseByRoom, dayBioColorHarvestWeightDict, isFetchingGP },
  };
});
