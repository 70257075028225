<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useAuthStore } from 'stores/auth-store';
  const { isAdmin } = storeToRefs(useAuthStore());
  import SettingsUser from './SettingsUser.vue';
  import SettingsApp from './SettingsApp.vue';
  import SettingsAdmin from './SettingsAdmin.vue';
  import SettingWorkspace from './SettingsWorkspace.vue';
</script>

<template>
  <q-item-label header>{{ $t('profile.settings.title') }}</q-item-label>
  <q-list class="bg-white rounded-lg p-2 border border-solid border-accent text-gray-6">
    <q-list separator>
      <settings-user />
      <settings-app />
      <setting-workspace />
      <settings-admin v-if="isAdmin" />
    </q-list>
  </q-list>
</template>
