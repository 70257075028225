<template>
  <q-dialog v-model="model" position="bottom">
    <q-card class="rounded-t-2xl! px-4 py-6 w-full">
      <div class="flex justify-end">
        <q-btn flat icon="i-solar:close-circle-line-duotone" @click="model = false" />
      </div>
      <q-list separator>
        <q-item v-for="s in states" :key="s.id" clickable v-ripple @click="$emit('click', s.id)">
          <div class="flex items-center gap-4">
            <q-badge class="w-4 h-4" :color="s.color" :outline="s.id === 0" />
            <span>{{ s.title }}</span>
          </div>
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const model = defineModel<boolean>();
  defineEmits<{ click: [id: number] }>();

  const states = [
    {
      id: 0,
      color: 'grey',
      title: t('roomcheck.nostate'),
    },
    {
      id: 1,
      color: 'red',
      title: t('roomcheck.status.helpneeded.title'),
    },
    {
      id: 2,
      color: 'green',
      title: t('roomcheck.status.reserves.title'),
    },
    {
      id: 3,
      color: 'orange',
      title: t('roomcheck.status.checklater.title'),
    },
  ].reverse();
</script>
