<template>
  <div class="flex flex-col text-gray-6 min-w-150px">
    <q-select :="{ ...$props, ...$attrs }" class="rounded-lg px-4" :class="`${bgColor ? 'bg-' + bgColor : 'bg-info'}`">
      <template v-if="hideDropdownIcon && !hideIconSlot" #append>
        <q-icon name="i-solar:round-alt-arrow-down-bold-duotone" color="primary"></q-icon>
      </template>
      <template #prepend>
        <slot name="prepend" />
      </template>

      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-italic text-grey"> No options </q-item-section>
        </q-item>
      </template>

      <template v-for="(_, name) in slots" #[name]="scope">
        <!-- @vue-skip -->
        <slot :name="name" v-bind="scope || {}" />
      </template>
    </q-select>
  </div>
</template>

<script setup lang="ts" generic="T">
import { QSelectProps, QSelectSlots } from 'quasar';

defineOptions({ name: 'FormSelect' });
const slots = defineSlots<QSelectSlots>();

withDefaults(defineProps<QSelectProps & { hideIconSlot?: boolean }>(), {
  borderless: true,
  hideDropdownIcon: true,
  hideBottomSpace: true,
});
</script>

<style>
.q-field--dense .q-field__prepend:empty {
  padding: 0;
}
</style>
