<template>
  <!-- Quasar has rendering issues with multiple QHeader components -->
  <div
    class="fixed top-0 w-full z-55 bg-white py-1 text-gray-6 border-b-1px border-b-solid border-accent flex justify-between px-4 items-center"
  >
    <h1 class="text-xl font-500 text-gray-6">
      <div>{{ $t('roomcheck.logs') }}</div>
      <NetworkStatus v-if="$q.platform.is.mobile" />
    </h1>
    <div class="flex items-center gap-4">
      <q-btn flat class="p-0">
        <q-icon
          size="sm"
          color="primary"
          @click="dialog = !dialog"
          name="i-solar:filter-bold-duotone"
        />
      </q-btn>
    </div>
  </div>
  <q-page class="full-page flex column full-width q-ma-0 q-px-0 bg-info">
    <AllocationItem.define #="{ hf: item }">
      <q-item
        tag="router-link"
        flatten
        dense
        class="q-item-hover rounded-lg flex items-start mb-2 py-2 px-4 border-solid border-accent border"
        :style="getForecastStyle(lastForecastsPerUser?.[item.user?.username || '']!, item)"
        @contextmenu.prevent="openContextMenu($event, item)"
      >
        <q-item-section avatar>
          <q-checkbox v-model="selectedForecasts" :val="item.id" color="primary" />
        </q-item-section>

        <q-item-section>
          <q-item-label class="font-bold text-gray-6 flex items-center flex-nowrap mb-1">
            <q-icon
              name="i-solar:calendar-bold-duotone"
              size="xs"
              color="text-gray-6"
              class="mr-2 mb-1px"
            />
            <span>{{ new Date(item.created_at).toLocaleString() }}</span>
          </q-item-label>
          <q-item-label class="text-orange text-caption flex items-center">
            <q-icon
              name="i-solar:user-bold-duotone"
              size="xs"
              color="text-gray-6"
              class="mr-2 mb-1px"
            />
            <div class="font-bold">{{ item.user?.first_name ?? item.user?.username }}</div>
          </q-item-label>
        </q-item-section>

        <q-item-section side>
          <div class="flex flex-wrap gap-1">
            <q-btn
              :disable="!isOnline"
              :label="item.is_consensus ? $t('btn.edit') : $t('allocationforecasts.copy')"
              :disabled="isCopyClicked"
              @click="itemClick(item.id, item.is_consensus)"
              :color="item.is_consensus ? 'orange' : 'primary'"
              outline
              dense
              no-caps
              padding="0.35rem 0.5rem"
              size="sm"
              class="rounded-lg capitalize text-xs!"
              :icon="item.is_consensus ? 'i-solar:pen-bold-duotone' : 'i-solar:copy-bold-duotone'"
            />
            <q-btn
              :disable="!isOnline"
              v-if="item.is_consensus"
              :label="$t('allocationforecasts.copy')"
              :disabled="isCopyClicked"
              @click="itemClick(item.id, item.is_consensus, true)"
              color="primary"
              outline
              dense
              no-caps
              padding="0.25rem 0.4rem"
              size="sm"
              class="rounded-lg capitalize text-xs!"
              icon="i-solar:copy-bold-duotone"
            />
          </div>

          <div class="flex gap-2 mt-1">
            <div class="flex items-center gap-1" v-if="item.is_consensus">
              <q-icon name="i-solar:users-group-rounded-bold-duotone" color="orange" size="sm" />
              <div v-if="!item.is_used_in_consensus" class="text-xs lowercase">
                {{ $t('menu.roomcheckreview.label') }}
              </div>
            </div>

            <div class="flex items-center gap-1" v-if="item.is_used_in_consensus">
              <q-icon name="i-solar:multiple-forward-right-bold" color="orange" size="sm" />
              <div v-if="!item.is_consensus" class="text-xs lowercase">
                {{ $t('allocationforecasts.alreadyUsed') }}
              </div>
            </div>
          </div>
        </q-item-section>
      </q-item>
    </AllocationItem.define>

    <q-menu class="min-w-100px" v-model="contextMenu.show" :target="contextMenu.target">
      <q-list dark bordered separator>
        <q-item clickable v-ripple @click="printForecast">
          <q-item-section avatar>
            <q-icon color="orange" name="print" />
          </q-item-section>
          <q-item-section class="text-black">
            {{ $t('allocationforecasts.printForecast') }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>

    <q-dialog v-model="dialog" position="bottom">
      <q-card class="flex justify-center items-center full-width q-gutter-md q-pa-md">
        <q-input
          filled
          v-model="selectedID"
          :label="$t('allocationforecasts.filter.label')"
          @input="filterList"
          clearable
          class="full-width"
          style="min-width: 300px"
        />

        <q-select
          filled
          v-model="selectedAuthor"
          :options="authors"
          :label="$t('allocationforecasts.filter.author')"
          @input="filterList"
          clearable
          multiple
          class="full-width"
          style="min-width: 300px"
        />
        <q-input
          :label="$t('allocationforecasts.filter.date')"
          class="full-width"
          filled
          style="min-width: 300px"
          v-model="selectedDate"
          mask="date"
          :rules="['date']"
          clearable
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date @input="filterList" v-model="selectedDate">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup :label="$t('btn.close')" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-card>
    </q-dialog>

    <q-page-sticky position="bottom-right" :offset="[18, 18]" style="z-index: 2000">
      <q-btn
        color="primary"
        v-if="selectedForecasts.length > 0"
        :to="'/room-check-review?forecast=' + selectedForecasts.join('&forecast=')"
        ref="morphRef"
        :label="$t('allocationforecasts.createConsensus')"
        no-caps
        icon="i-solar:user-plus-rounded-bold-duotone"
        :disable="!isOnline"
        @click="selectedForecasts = []"
      />
    </q-page-sticky>

    <q-infinite-scroll
      v-if="filteredForecasts && filteredForecasts.length > 0"
      ref="infiniteScrollRef"
      :virtual-scroll-item-size="60"
      :offset="40"
      @load="onLoad"
      class="mt-16 md:mt-0"
      :class="{ 'mt-20 ': !isOnline }"
      separator
    >
      <AllocationItem.reuse v-for="hf in filteredForecasts" :key="hf.id" :hf="hf" />

      <template v-slot:loading>
        <div class="row justify-center">
          <q-spinner-dots color="primary" size="40px" />
        </div>
      </template>
    </q-infinite-scroll>
    <div v-else class="full-width h-100vh flex justify-center items-center">
      <NetworkStatus v-if="!isOnline" />
      <Loader v-else-if="isLoading" />
      <span v-else class="mt-30 text-gray-5">
        {{ $t('allocationforecasts.nodata') }}
      </span>
    </div>
  </q-page>
</template>
<script lang="ts" setup>
  import { QBtn, QInfiniteScroll } from 'quasar';
  import { HarvestForecast } from 'src/domain/models/types';
  import { formatDate } from 'src/utils/utils';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  defineOptions({ name: 'AllocationForecastPage' });

  const { isOnline } = useNetwork();
  const { roomCheckAllocationForecast } = storeToRefs(useAllocationForecastStore());
  const { fetchItems, generatePDF, fetchItem, createForecastState } = useAllocationForecastStore();

  const maxItemsToLoad = 40;

  const AllocationItem = createReusableTemplate<{ hf: HarvestForecast }>();

  const allocationForecasts = ref<HarvestForecast[]>();
  const selectedAuthor = ref<string[]>([]);
  const selectedDate = ref('');
  const selectedID = ref('');
  const dialog = ref(false);
  const isLoading = ref(true);

  const contextMenu = ref({
    show: false,
    target: false,
    item: null as null | HarvestForecast,
  });
  const infiniteScrollRef = ref<QInfiniteScroll>();
  const selectedForecasts = ref([]);
  const router = useRouter();

  const lastForecastsPerUser = computed(() => {
    if (!filteredForecasts.value) return;
    const lastForecastsPerUser: { [key: string]: HarvestForecast } = {};

    const sorted = filteredForecasts.value.concat().sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB > dateA ? 1 : -1;
    });

    for (const forecast of sorted) {
      if (!forecast.user) continue;
      if (!(forecast.user.username in lastForecastsPerUser) && !forecast.consensus) {
        lastForecastsPerUser[forecast.user.username] = forecast;
      }
    }

    return lastForecastsPerUser;
  });

  async function refetchItems() {
    const result: HarvestForecast[] = await fetchItems({
      limit: maxItemsToLoad,
      cacheDuration: 0,
      offset: 0,
      depth: 3,
      excludedFields: 'harvest_day_forecasts,harvest_forecasts,roles',
      sort: '-id',
    });

    if (result.length) {
      allocationForecasts.value = result;
      filteredAllocationForecasts.value = result;
    }

    isLoading.value = false;
  }

  watch(isOnline, (online, wasOnline) => {
    if (!wasOnline && online) {
      refetchItems();
    }
  });

  const filteredAllocationForecasts = ref<HarvestForecast[]>();

  onActivated(() => {
    window.scroll(0, 0);
    refetchItems();

    isCopyClicked.value = false;
  });

  onMounted(() => {
    refetchItems();
  });

  function getForecastStyle(forecast?: HarvestForecast, cf?: HarvestForecast) {
    const userName = forecast?.user?.username;
    if (!userName) return { 'background-color': 'white' };

    const f = lastForecastsPerUser.value?.[userName];

    return {
      'background-color': f?.id == cf?.id && !cf?.consensus ? 'var(--q-warning)' : 'white',
    };
  }

  async function printForecast() {
    const allocationForecast = await fetchItem(contextMenu.value.item?.id + '');
    const forecastState = createForecastState(allocationForecast);
    (<any>generatePDF)(forecastState, allocationForecast.label, false, t);
    contextMenu.value.show = false;
  }

  const authors = computed(() => [
    ...new Set(
      allocationForecasts.value?.map(
        (forecast) => forecast.user?.first_name ?? forecast.user?.username
      )
    ),
  ]);

  const filteredForecasts = computed(() => {
    const af = allocationForecasts.value?.filter((forecast) => {
      const hasSelectedAuthor =
        !selectedAuthor.value ||
        selectedAuthor.value.length <= 0 ||
        selectedAuthor.value.includes(forecast.user?.first_name ?? forecast.user?.username ?? '');
      const hasSelectedDate =
        !selectedDate.value || intoDate(forecast.created_at) === intoDate(selectedDate.value);
      const hasSelectedID = !selectedID.value || forecast.label.includes(selectedID.value);
      return hasSelectedAuthor && hasSelectedDate && hasSelectedID;
    });

    af?.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB > dateA ? 1 : -1;
    });

    return af;
  });

  const filterList = () => {
    let filteredForecasts = allocationForecasts.value?.filter(
      (forecast) =>
        (!selectedAuthor.value ||
          selectedAuthor.value.includes(
            forecast.user?.first_name ?? forecast.user?.username ?? ''
          )) &&
        (!selectedDate.value || intoDate(forecast.created_at) === intoDate(selectedDate.value))
    );
    if (filteredForecasts) filteredAllocationForecasts.value = filteredForecasts;
  };

  const isCopyClicked = ref(false);
  async function itemClick(id: number, isConsensus: boolean, isConsensusCopy = false) {
    isCopyClicked.value = true;
    roomCheckAllocationForecast.value = id;

    if (isConsensusCopy) {
      router.push({ path: '/', query: { fid: id } });
      return;
    }

    if (!isConsensus) {
      router.push({ path: '/', query: { fid: id } });
    } else {
      router.push({ path: '/room-check-review', query: { forecast: id } });
    }
  }

  function createNewForecast() {
    router.push({
      name: 'RoomCheck',
      query: { new: 'true' },
    });
  }

  function onLoad(index: number, done: () => void) {
    fetchItems({
      limit: maxItemsToLoad,
      cacheDuration: 0,
      offset: index * maxItemsToLoad,
      depth: 3,
      excludedFields: 'harvest_day_forecasts,harvest_forecasts,roles',
      sort: '-id',
    }).then((result: HarvestForecast[]) => {
      if (result.length > 0) {
        allocationForecasts.value?.push(...result);
      }
      if (result.length < maxItemsToLoad) {
        infiniteScrollRef.value?.stop();
        done();
      }
    });
  }

  const intoDate = (dateString: string) => formatDate(new Date(dateString));

  const openContextMenu = (event: { target: boolean }, item: HarvestForecast) => {
    contextMenu.value.show = true;
    contextMenu.value.target = event.target;
    contextMenu.value.item = item;
  };

  function stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = [];
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color.push(value);
    }
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.2)`;
  }
</script>

<style scoped>
  .full-page {
    padding: 16px;
    background-color: #f5f5f5;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.12);
  }

  .filter-select,
  .filter-date {
    flex: 1;
  }

  .filter-select {
    margin-right: 16px;
  }

  .filter-date {
    margin-left: 8px;
  }

  .virtual-scroll {
    height: calc(100vh - 100px);
    border-radius: 8px;
    overflow: hidden;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
  }

  .q-item-hover {
    transition: background-color 0.3s ease;
  }

  .q-item:hover {
    background-color: #f0f0f0;
  }

  .marked-forecast {
    background-color: rgba(37, 203, 42, 0.208) !important;
  }
</style>
