<template>
  <q-dialog v-model="isOpen">
    <div class="p-2 bg-white text-gray-6 shadow">
      <div class="px-2 pt-2 flex flex-nowrap justify-between items-center">
        <div class="font-bold">{{ trayModel.product.label }}</div>
        <q-btn @click="isOpen = false" flat dense icon="i-solar:close-circle-line-duotone" />
      </div>
      <div class="px-2 mt-4 flex flex-nowrap gap-2">
        <div class="w-4">{{ ' ' }}</div>
        <div class="w-80px">{{ $t('fridge.trays') }}</div>
        <div class="">{{ $t('fridge.weight') }}</div>
      </div>
      <div class="overflow-hidden">
        <div class="mt-1 flex flex-col gap-1">
          <div v-auto-animate="{ duration: 150 }">
            <div
              v-for="(t, i) in wrappedList"
              :key="t.id"
              class="px-2 flex flex-nowrap items-center gap-2 border-b-solid border-info pb-1"
            >
              <div class="xs:w-4">{{ i + 1 }}.</div>
              <FridgeCheckInput
                @update:model-value="
                  (e) => (t.value.weight = +(e * trayModel.product.weight).toFixed(2))
                "
                :min="0"
                v-model="t.value.num"
                :disable="!!t.value.weight && !t.value.num"
                class="w-80px! pr-2"
                hide-append
              />
              <FridgeCheckInput
                class="w-105px!"
                @update:model-value="(e) => (t.value.num = 0)"
                :min="0"
                v-model="t.value.weight"
              />
              <q-btn
                :disable="loading"
                @click="remove(t)"
                color="red"
                dense
                flat
                icon="i-solar:trash-bin-trash-bold-duotone"
              />
            </div>
          </div>
          <q-btn
            :disable="loading"
            color="primary"
            class="w-50px rounded-lg bg-primaryHex/20"
            dense
            flat
            icon="i-solar:add-circle-bold-duotone"
            @click="add"
          />
          <div class="border-b-solid border-info w-full h-1px" />
          <div class="px-2 flex flex-nowrap items-center gap-2 pb-1 font-bold text-xs">
            <div class="w-4 font-medium">{{ $t('fridge.total') }}:</div>
            <div class="w-80px pl-3 flex items-center gap-1">
              <div>{{ trayModel.trays.reduce((acc, e) => (acc += e.num || 0), 0) }}</div>
            </div>
            <div class="xs:w-20px">
              {{ ' ' }}
            </div>
            <div class="w-full pl-4 flex flex-nowrap items-center justify-between">
              <div class="text-green-9">
                {{ formatNumber(trayModel.trays.reduce((acc, e) => (acc += e.weight || 0), 0)) }}
                kg
              </div>
              <q-btn
                :loading="loading"
                @click="$emit('save')"
                class="rounded-lg text-xs xs:text-unset mr-1 xs:mr-unset"
                no-caps
                color="primary"
                :label="$t('btn.save')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
  import { Product } from 'src/components/models';
  import { formatNumber } from 'src/utils/utils';

  export type Tray = { weight: number | null; num: number | null };
  const isOpen = defineModel<boolean>({ required: true });
  const trayModel = defineModel<{ product: Product; trays: Tray[] }>('trays', { required: true });
  defineProps<{ loading?: boolean }>();

  const { add, remove, wrappedList } = useExpandableList(computed(() => trayModel.value.trays));
</script>

<style scoped></style>
