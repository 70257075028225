<script setup lang="ts">
  import { onMounted, computed } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useFactoriesStore } from 'stores/factories-store';
  import { useUsersStore } from 'stores/users-store';

  const { switchFactory } = useUsersStore();
  const { fetchCurrent, fetchItems } = useFactoriesStore();
  const { current: factory, items: factories } = storeToRefs(useFactoriesStore());

  onMounted(() => {
    fetchCurrent();
    fetchItems();
  });

  const selectedFactory = computed({
    get() {
      return factory.value;
    },
    set(newFactory) {
      // your setter
      //console.log(newFactory)
      switchFactory(newFactory.id);
    },
  });
</script>

<template>
  <q-select
    filled
    v-model="selectedFactory"
    use-input
    hide-selected
    fill-input
    autofocus
    input-debounce="0"
    label="Factory"
    :options="factories"
    color="red-4"
    outlined
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> No results </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
