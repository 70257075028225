<script setup lang="ts">
  import { ref } from 'vue';
  import { version } from 'app/package.json';
  import { storeToRefs } from 'pinia';
  import { useInfosStore } from 'stores/infos-store';

  const { fetchVersions } = useInfosStore();
  const { gcpService, gcpVersion, symfonyEnv, databaseVersion } = storeToRefs(useInfosStore());
  const appVersion = ref(version);
  const modal = ref(false);

  function onOpenDialog() {
    // Lazy fetch of the current backend info
    fetchVersions();
    modal.value = true;
  }
</script>

<template>
  <q-item clickable v-ripple @click="onOpenDialog()">
    <q-item-section avatar>
      <q-icon color="gray-6" name="i-solar:code-bold-duotone" />
    </q-item-section>
    <q-item-section>{{ $t('profile.settings.app.title') }}</q-item-section>
  </q-item>
  <q-dialog v-model="modal" full-width>
    <q-card class="text-gray-6">
      <q-list class="q-py-sm">
        <q-item v-ripple>
          <q-item-section class="borderBottomAccent pb-2">
            <q-item-label class="font-500">{{
              $t('profile.settings.app.frontend.version')
            }}</q-item-label>
            <q-item-label class="text-orange">{{ version }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-ripple>
          <q-item-section class="borderBottomAccent pb-2">
            <q-item-label class="font-500">{{
              $t('profile.settings.app.backend.service')
            }}</q-item-label>
            <q-item-label class="text-orange">{{ gcpService }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-ripple>
          <q-item-section class="borderBottomAccent pb-2">
            <q-item-label class="font-500">{{
              $t('profile.settings.app.backend.version')
            }}</q-item-label>
            <q-item-label class="text-orange">{{ gcpVersion }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-ripple>
          <q-item-section class="borderBottomAccent pb-2">
            <q-item-label class="font-500">{{
              $t('profile.settings.app.backend.environement')
            }}</q-item-label>
            <q-item-label class="text-orange">{{ symfonyEnv }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-ripple>
          <q-item-section>
            <q-item-label class="font-500">{{
              $t('profile.settings.app.backend.database')
            }}</q-item-label>
            <q-item-label class="text-orange">{{ databaseVersion }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
</template>
