export default {
  language: 'English',
  failed: 'Action failed',
  success: 'Action was successful',

  authentication: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    logout: 'Logout',
    introducevalidpassword: 'Please introduce a valid password',
    introducevalidemail: 'Please introduce a valid email',
  },
  settings: {
    farmStructure: {
      title: 'Farm structure',
      description: 'Configuration of the farm and its infrastructure.',
      image: 'https://foodtank.com/wp-content/uploads/2020/04/COVID-19-Relief_Small-Farms-.jpg',
      route: 'settings/farm-structure',
      children: {
        growingRoom: {
          title: 'Rooms',
          label: 'Rooms',
          description: 'Define the rooms and their associated organization.',
          route: '/settings/farm-structure/growing-rooms',
        },
        // shelfTypes: {
        //   title: 'Shelfs',
        //   label: 'Shelfs',
        //   description: 'Define the different mushroom types produced',
        //   route: '/settings/farm-structure/shelf-types'
        // }
      },
    },

    growingProgram: {
      title: 'Mushroom growing',
      description: 'Configuration for the growing programs of the farm.',
      image:
        'https://images.immediate.co.uk/production/volatile/sites/30/2017/05/Chesnut-mushrooms-f2497d2.jpg?quality=90&webp=true&resize=600,545',
      route: 'settings/growing-program',
      children: {
        mushroomTypes: {
          title: 'Mushroom types',
          label: 'Mushroom types',
          description: 'Define the different mushroom types produced',
          route: '/settings/growing-program/mushroom-types',
        },
        growingProgramTypes: {
          title: 'Growing program types',
          label: 'Growing program types',
          description: 'Define the different growing program types',
          route: '/settings/growing-program/growing-program-types',
        },
        growingPhaseTypes: {
          title: 'Growing phase types',
          label: 'Growing phase types',
          description: 'Define the different mushroom types produced',
          route: '/settings/growing-program/growing-phase-types',
        },
      },
    },
    people: {
      title: 'People',
      description: 'Configuration for the growing programs of the farm.',
      image: 'https://mainlinetoday.com/wp-content/uploads/2021/04/mlt_image001_1916964.jpg',
      route: 'settings/people',
      children: {
        mushroomTypes: {
          title: 'Pickers',
          label: 'Pickers',
          description: 'Management of pickers in the farm',
          route: '/settings/growing-program/pickers',
        },
      },
    },
  },
  profile: {
    settings: {
      title: 'Settings',
      admin: 'Only ADMIN',
      user: {
        title: 'Account',
        factory: 'Factory',
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        intenalId: 'Badge number',
      },
      app: {
        title: 'App information',
        frontend: {
          version: 'Frontend version',
        },
        backend: {
          service: 'Backend service',
          version: 'Backend version',
          environement: 'Backend environement',
          database: 'Database version',
        },
      },
      workspace: {
        title: 'Switch workspace',
      },
    },
  },

  // Page translations
  roomcheck: {
    downloadReport: 'Download report',
    minpickers: 'Min. pickers',
    maxpickers: 'Max. pickers',
    kilograms: 'Kilograms',
    total: 'Total',
    room: 'Room',
    pickers: 'Pickers',
    weight: 'Weight',
    status: {
      helpneeded: { title: 'Help needed', noelements: 'No help needed' },
      reserves: { title: 'Reserves', noelements: 'No reserves' },
      checklater: { title: 'Check later', noelements: 'Nothing to check' },
    },
    nostate: 'No state',
    roomstatus: 'Box status overview',
    submit: 'Submit',
    submited: 'Submited',
    nodata: 'There is no room with active growing cycles for the following 7 days.',
    logs: 'Logs',
    afternoon: 'Afternoon',
    morning: 'Morning',
    targetKg: 'Target Kg',
    consensus: 'Consensus',
    newKg: 'New Kg',
    noPreviousDays: 'No previous days',
    harvestInfo: 'Harvest info',
  },

  allocationforecasts: {
    forecast: 'Forecast',
    alreadyUsed: 'Already used',
    copy: 'Copy',
    addForcast: 'Add forecast',
    createConsensus: 'Create consensus',
    filter: {
      label: 'Filter by label',
      author: 'Filter by author',
      date: 'Filter by date',
    },
    nodata: 'No allocation forecasts.',
    printForecast: 'Print forecast',
  },

  // Fridge check page
  fridge: {
    selectFridge: 'Select fridge',
    filters: 'Filters',
    byFridge: 'By fridge',
    byType: 'By type',
    productsPerPage: 'Products per page:',
    todayReport: 'Today weight:',
    fridgeNotSelected: 'Fridge not selected',
    name: 'Name',
    trays: 'Trays',
    weight: 'Weight',
    today: 'Today (Weight/Correction)',
    type: 'Type',
    changeFridge: 'Change fridge',
    lastSubmit: 'Last submit by',
    morning: 'Morning',
    evening: 'Evening',
    reported: 'Reported',
    correction: 'Correction',
    noData: 'No data by selected filters:',
    total: 'Total',
  },

  // Growing plan page
  growingPlan: {
    harvestedWeight: 'Harvested weight',
    targetWeight: 'Target weight',
    selectYear: 'Select year',
    addGrowingPlan: 'Add growing plan',
    addingNewGrowingPlan: 'Adding new growing plan',
    growingProgramType: 'Growing program type',
    selectRoom: 'Select room',
    startDate: 'Start date',
    endDate: 'End date',
    pickDateToChange: 'Pick date to change',
    changeStartDate: 'Change start date',
    changeEndDate: 'Change end date',
    indicators: 'Indicators',
    phaseID: 'Phase id',
    areYouSure: 'Are you sure you want to delete this phase',
    events: {
      title: 'Events',
      areYouSure: 'Are you sure you want to delete this event?',
      airing: 'Add new airing event',
      watering: 'Add new watering event',
      ruffling: 'Add new note',
      steaming: 'Add new steaming event',
    },
    time: 'Time',
    volume: 'Volime',
    nematodes: 'Nematodes',
    duration: 'Duration',
    notes: 'Notes',
    statistics: 'Statistics',
    activeFlushes: 'Active flushes',
    filling: 'Filling',
    week: 'Week',
  },

  // Default demand page
  defaultDemand: {
    addSeason: 'Add season',
    addingNewSeason: 'Adding new season',
    seasonLabel: 'Season label',
    weekFrom: 'Week from',
    weekTo: 'Week to',
    inOrderToAdd: 'In order to access default demand, please add at least 1 season...',
    fromTo: 'From - To',
    deleteSeason: 'Delete season',
    areYouSure: 'Are you sure you want to delete',
    sortProducts: 'Sort products',
  },

  // Active products page
  activeProducts: {
    importProduct: 'Import product',
    addProduct: 'Add product',

    active: 'Active',
    inactive: 'Inactive',
    productName: 'Product name',
    selectProduct: 'Select product',
    companyProductID: 'Company product ID',
    mushroomColor: 'Mushroom color',
    mushroomSize: 'Mushroom size type',
    punnetWeight: 'Punnet weight (kg)',
    punnetCount: 'Punnet count',
    totalProducts: 'Total products',
    whiteNonBio: 'White Non-Bio:',
    whiteBio: 'White/Bio:',
    brownNonBio: 'Brown Non Bio:',
    brownBio: 'Brown/Bio:',
    dialog: {
      importing: 'Importing product',
      addingNew: 'Adding new product',
      updating: 'Updating product',
      addNew: 'Add new product',
    },
  },

  // Filling data page
  fillingData: {
    createEvent: 'Create event',
    updateEvent: 'Update event',
    startDate: 'Start date',
    growingRoom: 'Growing room',
    growingProgram: 'Growing program',
    time: 'Time',
    casingSupplier: 'Casing supplier',
    substrateSupplier: 'Substrate supplier',
    strainType: 'Strain type',
    caccingType: 'Caccing/ Ruffling',
    tunnel: 'Tunnel',
    daysSinceSpawning: 'Days since spawning',
    fillingWeightPerM2: 'Filling weight per m2',
    phBeforeDelivery: 'Ph before delivery',
    relativeHumidity: 'Relative humidity',
    deliveryWeight: 'Delivery weight',
    structure: 'Structure',
    fillingHeight: 'Filling height',
    filledSurface: 'Filled surface',
    dialog: {
      creating: 'Creating new event',
      updating: 'Updating event',
    },
    total_harvested_of_period: 'Total yield in kg',
    kg_harvested_per_square_meter: 'Yield in kg/m2',
    kg_harvested_per_kg_substrate: 'Yield in kg/t substrate',
    airing_after_filling: 'Airing after filling',
    airing_after_spawining: 'Airing after spawning',
    showFilters: 'Show filters',
    select: 'Select...',
    range: {
      title: 'Range',
      currentYear: 'Current year',
      currentMonth: 'Current month',
      currentQuarter: 'Current quarter',
      from: 'From',
      to: 'To',
    },
    total: 'Total',
    watering: 'Watering',
    growing: 'Growing',
    discarded: 'Discarded',
  },

  // Component translations
  menu: {
    profile: {
      label: 'Profile',
      icon: 'i-solar:user-bold-duotone',
      route: '/profile',
    },

    fridge: {
      label: 'Fridge',
      icon: 'i-solar:fridge-bold-duotone',
      route: '/fridge-check',
    },
    roomcheck: {
      label: 'Room check',
      icon: 'i-solar:checklist-line-duotone',
      route: '/',
    },

    notifications: {
      label: 'Notifications',
      icon: 'notifications',
      route: '/notifications',
    },
    roomcheckreview: {
      label: 'Consensus',
      icon: 'checklist',
      route: '/room-check-review',
    },
    roomcycles: {
      label: 'Room cycles',
      icon: 'compost',
      route: '/room-cycles',
    },
    productionoverview: {
      label: 'Production overview',
      icon: 'compost',
      route: '/production-overview',
    },
    productionoverviewvertical: {
      label: 'Production overview Ver',
      icon: 'compost',
      route: '/production-overview-vertical',
    },
    growingprograms: {
      label: 'Growing programs',
      icon: 'compost',
      route: '/growing-programs',
    },
    assignpickers: {
      label: 'Assign pickers',
      icon: 'person',
      route: '/assign-pickers',
    },
    harvestregions: {
      label: 'Harvest regions',
      icon: 'table_chart',
      route: '/harvest-regions',
    },
    defaultregions: {
      label: 'Default regions',
      icon: 'table_chart',
      route: '/default-harvest-regions',
    },
    statistics: {
      label: 'Statistics',
      icon: 'show_chart',
      route: '/statistics',
    },
    pickinginstructions: {
      label: 'Picking instructions',
      icon: 'more_horiz',
      route: '/picking-instructions',
    },
    programschedules: {
      label: 'Program schedules',
      icon: 'schedule',
      route: '/program-schedules',
    },
    growingprogramtypecreation: {
      label: 'Growing program type creation',
      icon: 'schedule',
      route: '/growing-program-type',
    },
    settings: {
      label: 'Settings',
      icon: 'i-solar:settings-bold-duotone',
      route: '/settings',
    },

    allocationForecasts: {
      label: 'Logs',
      icon: 'i-solar:clipboard-list-bold-duotone',
      route: '/allocation-forecasts',
    },
    forecast: {
      label: 'Allocation forecast',
      icon: 'person',
      route: '/forecast',
    },
  },

  // Unique dialogs
  dialog: {
    delete: {
      confirm: 'Confirm delete',
      title: 'You are about to delete:',
    },
  },

  // Table collumns
  table: {
    color: 'Color',
    bio: 'Bio',
    name: 'Name',
    weight: 'Weight',
    demand: 'Demand',
    carry: 'Carry',
    carryOver: 'Carry',
    fridge: 'Fridge',
    harvest: 'Harvest',
    declass: 'Declass',
    purchase: 'Purchase',
    diff: 'Diff',
    code: 'Code',
    active: 'Active',
    companyProduct: 'Company Product',
    products: 'Products',
    total: 'Total',
    dayTable: 'Day table',
    order: 'Order',
  },

  mushrooms: {
    white: 'White',
    whiteBio: 'White Bio',
    brown: 'Brown',
    brownBio: 'Brown Bio',
  },

  // Unique buttons
  btn: {
    export: 'Export',
    edit: 'edit',
    save: 'Save',
    cancel: 'Cancel',
    submit: 'Submit',
    submited: 'Submited',
    add: 'Add',
    addNew: 'Add new',
    update: 'Update',
    confirm: 'Confirm',
    import: 'Import',
    inactive: 'Inactive',
    duplicate: 'Duplicate',
    delete: 'Delete',
    active: 'Active',
    close: 'Close',
  },

  // Unique input labels and placeholders
  input: {
    search: 'Search',
    factory: 'Factory',
  },

  // Page titles used in the header of the screen
  pageTitle: {
    default: 'Default',
    dashboard: 'Dasboard',
    profile: 'Profile',
    growingPlan: 'Growing plan',
    fridgeCheck: 'Fridge check',
    productionInput: 'Daily production input',
    harvestForecastsList: 'Forecasts',
    harvestForecastsEdit: 'Edit forecasts',
    activeProducts: 'Active Products',
    fillingData: 'Growing cycle data',
    forecasting: 'Forecasting',
    defaultDemand: 'Default demand',
    pickingInstructions: 'Picking instructions',
    programSchedules: 'Program schedules',
    assignPickers: 'Assign pickers',
    harvestRegions: 'Harvest regions',
    defaultRegions: 'Default regions',
    statistics: 'Statistics',
    notifications: 'Notifications',
  },
  daynames: {
    day: 'Day',
    days: 'Days',
    week: 'Week',
    weeks: 'Weeks',
    today: 'Today',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    short: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun',
    },
  },

  monthnames: {
    month: 'Month',
    months: 'Months',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    short: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Apr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Aug',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dec',
    },
  },
  mushroom: {
    'Brown Bio': 'Brown Bio',
    'White Bio': 'White Bio',
    Brown: 'Brown',
    White: 'White',
  },
};
