<template>
  <q-input
    v-if="!loading"
    :disable="disable"
    placeholder="0"
    borderless
    class="rounded-lg h-30px text-xs py-0 w-24"
    :class="`${
      field === 'correction' && correction !== undefined
        ? correction == 0 || correction === null
          ? 'bg-info'
          : correction < 0
          ? 'bg-red/20'
          : 'bg-green/20'
        : 'bg-info'
    }`"
    input-class="pl-4 h-30px"
    dense
    hide-bottom-space
    hide-hint
    type="number"
    v-model.number="model"
  >
    <template v-if="!hideAppend" #append>
      <div class="text-subtitle2 text-xs text-grey pt-0.5 pr-4 h-30px">kg</div>
    </template>
  </q-input>
  <q-skeleton class="w-24 h-7 rounded-lg" v-else />
</template>

<script setup lang="ts">
  import { ProductRecord } from 'src/domain/models/types';

  defineProps<{
    hideAppend?: boolean;
    loading?: boolean;
    disable?: boolean;
    correction?: number;
    field?: keyof ProductRecord;
  }>();
  const model = defineModel<number | string | null>();
</script>

<style scoped></style>
