export default {
  language: 'Deutsch',
  failed: 'Aktion fehlgeschlagen',
  success: 'Aktion erfolgreich',
  authentication: {
    login: 'Einloggen',
    email: 'E-Mail',
    password: 'Passwort',
    logout: 'Ausloggen',
    introducevalidpassword: 'Bitte geben Sie ein gültiges Passwort ein',
    introducevalidemail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  },
  settings: {
    farmStructure: {
      title: 'Produktionsstätte',
      description: 'Konfiguration der Produktionsstätte und seiner Infrastruktur.',
      image: 'https://foodtank.com/wp-content/uploads/2020/04/COVID-19-Relief_Small-Farms-.jpg',
      route: 'settings/farm-structure',
      children: {
        growingRoom: {
          title: 'Zuchträume',
          label: 'Zuchträume',
          description: 'Definition der Zuchträume und ihre zugehörige Organisation.',
          route: '/settings/farm-structure/growing-rooms',
        },
        // shelfTypes: {
        //   title: 'Gestell',
        //   label: 'Gestell',
        //   description: 'Definition der verschiedenen Gestelle, in denen produziert wird',
        //   route: '/settings/farm-structure/shelf-types',
        // },
      },
    },
    growingProgram: {
      title: 'Zuchtprogramm',
      description: 'Konfiguration der Zuchtprogramme der Produktionsstätte',
      image:
        'https://images.immediate.co.uk/production/volatile/sites/30/2017/05/Chesnut-mushrooms-f2497d2.jpg?quality=90&webp=true&resize=600,545',
      route: 'settings/growing-program',
      children: {
        mushroomTypes: {
          title: 'Pilzarten',
          label: 'Pilzarten',
          description: 'Definition der verschiedenen Pilzarten, die produziert werden',
          route: '/settings/growing-program/mushroom-types',
        },
        growingProgramTypes: {
          title: 'Zuchtprogramme',
          label: 'Zuchtprogramme',
          description: 'Definition der verschiedenen Zuchtprogramme',
          route: '/settings/growing-program/growing-program-types',
        },
        growingPhaseTypes: {
          title: 'Wachstumsphasen',
          label: 'Wachstumsphasen',
          description: 'Definition der verschiedenen Wachstumsphasen',
          route: '/settings/growing-program/growing-phase-types',
        },
      },
    },
    people: {
      title: 'Personen',
      description: 'Konfiguration für die Zuchtprogramme der Produktionsstätte.',
      image: 'https://mainlinetoday.com/wp-content/uploads/2021/04/mlt_image001_1916964.jpg',
      route: 'settings/people',
      children: {
        mushroomTypes: {
          title: 'Pflücker',
          label: 'Pflücker',
          description: 'Verwaltung der Pflücker im Betrieb',
          route: '/settings/growing-program/pickers',
        },
      },
    },
  },
  profile: {
    settings: {
      title: 'Einstellungen',
      admin: 'Nur ADMIN',
      user: {
        title: 'Konto',
        factory: 'Produktionsstätte',
        email: 'E-Mail',
        firstName: 'Vorname',
        lastName: 'Nachname',
        intenalId: 'Ausweisnummer',
      },
      app: {
        title: 'App-Informationen',
        frontend: {
          version: 'Frontend-Version',
        },
        backend: {
          service: 'Backend-Service',
          version: 'Backend-Version',
          environement: 'Backend-Umgebung',
          database: 'Datenbank-Version',
        },
        workspace: {
          title: 'Arbeitsbereich wechseln',
        },
      },
    },
  },
  roomcheck: {
    downloadReport: 'Bericht herunterladen',
    minpickers: 'Min. Pflücker',
    maxpickers: 'Max. Pflücker',
    kilograms: 'Kilogramm',
    total: 'Gesamt',
    room: 'Zuchtraum',
    pickers: 'Pflücker',
    weight: 'Gewicht',
    status: {
      helpneeded: { title: 'Hilfe benötigt', noelements: 'Keine Hilfe benötigt' },
      reserves: { title: 'Reserve', noelements: 'Keine Reserve' },
      checklater: { title: 'Später überprüfen', noelements: 'Nichts zu überprüfen' },
    },
    nostate: 'Zustand undefiniert',
    roomstatus: 'Status Zuchtraum',
    submit: 'Speichern',
    submited: 'Gespeichert',
    nodata: 'In den folgenden 7 Tagen gibt es keinen Raum mit aktiven Wachstumsperioden.',
    logs: 'Logs',
    afternoon: 'Nachmittag',
    morning: 'Vormittag',
    targetKg: 'Ziel (kg)',
    consensus: 'Konsens',
    newKg: 'Neu (kg)',
    noPreviousDays: 'Keine vorgängigen Tage',
    harvestInfo: 'Ernte Information',
  },
  allocationforecasts: {
    forecast: 'Prognose',
    alreadyUsed: 'Schon Benutzt',
    copy: 'Kopieren',
    addForcast: 'Prognose hinzufügen',
    createConsensus: 'Konsens erstellen',
    filter: {
      label: 'Filtern nach Etikett',
      author: 'Filtern nach Autor',
      date: 'Filtern nach Datum',
    },
    nodata: 'Keine Zuweisungsprognosen',
    printForecast: 'Druck Vorschau',
  },
  fridge: {
    selectFridge: 'Kühlraum',
    filters: 'Filter',
    byFridge: 'pro Kühlraum',
    byType: 'Wahl Champignon Typ',
    productsPerPage: 'Produkte pro Seite:',
    todayReport: 'heutiges Erntegewicht:',
    fridgeNotSelected: 'Kühlraum nicht ausgewählt',
    name: 'Name',
    trays: 'Gestell',
    weight: 'Gewicht',
    today: 'Heute (Gewicht/Korrektur)',
    type: 'Typ',
    changeFridge: 'Kühlraum wechseln',
    lastSubmit: 'zuletzt gespeichert durch',
    morning: 'Morgens',
    evening: 'Abends',
    reported: 'Reported',
    correction: 'Korrektur',
    noData: 'keine Daten für den gewählten Filter:',
    total: 'Total',
    watering: 'Giessen',
    growing: 'Wachstum',
    discarded: 'Verworfen',
  },
  // Growing plan page
  growingPlan: {
    harvestedWeight: 'Erntegewicht',
    targetWeight: 'Zielgewicht',
    selectYear: 'Jahr',
    addGrowingPlan: 'Hinzufügen eines Zuchtplans',
    addingNewGrowingPlan: 'neuen Zuchtplan hinzufügen',
    growingProgramType: 'Zuchtprogramm',
    selectRoom: 'Wahl Zuchtraum',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    pickDateToChange: 'Wahl Datum Änderung',
    changeStartDate: 'Datum ändern',
    indicators: 'Indicatoren',
    phaseID: 'Phase Id',
    areYouSure: 'Soll diese Phase wirklich gelöscht werden?',
    // TODO: New translations added
    events: {
      title: 'Ereignisse',
      areYouSure: 'Soll dieses Ereignis wirklich gelöscht werden?',
      airing: 'Ablüften hinzufügen',
      watering: 'Giessen hinzufügen',
      ruffling: 'Notiz hinzufügen',
      steaming: 'Dämpfen hinzufügen',
    },
    time: 'Zeit',
    volume: 'Menge',
    nematodes: 'Nematoden',
    duration: 'Dauer',
    notes: 'Notizen',
    statistics: 'Statistik',
    activeFlushes: 'Aktive Spülungen',
    filling: 'Füllung',
    week: 'Woche',
  },
  // Default demand page
  defaultDemand: {
    addSeason: 'Hinzufügen Nachfragezyklus',
    addingNewSeason: 'Hinzufügen neuer Nachfragezyklus',
    seasonLabel: 'Name Nachfragezyklus',
    weekFrom: 'von Woche',
    weekTo: 'bis Woche',
    inOrderToAdd:
      'Bitte mindestens einen Nachfragezyklus hinzufügen, um auf eine Standard-Nachfrage zuzugreifen...',
    fromTo: 'von - bis',
    deleteSeason: 'Löschen eines Nachfragezyklus',
    areYouSure: 'Soll der Nachfragezyklus wirklich gelöscht werden?',
    sortProducts: 'Produkte sortieren',
  },
  // Active products page
  activeProducts: {
    importProduct: 'Import Produkt',
    addProduct: 'Hinzufügen Produkt',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    productName: 'Produktname',
    selectProduct: 'Auswahl Produkt',
    companyProductID: 'Company Produkt ID',
    mushroomColor: 'Farbe Champignon',
    mushroomSize: 'Grössenordnung Champignon',
    punnetWeight: 'Gewicht Schale (kg)',
    punnetCount: 'Anzahl Schalen pro Gebinde',
    totalProducts: 'Anzahl Produkte',
    whiteNonBio: 'Weiss Non-Bio:',
    whiteBio: 'Weiss Bio:',
    brownNonBio: 'Braun Non-Bio:',
    brownBio: 'Braun Bio:',
    dialog: {
      importing: 'Produkt wird importiert',
      addingNew: 'Neues Produkt wird hinzugefügt',
      updating: 'Produkt wird aktualisiert',
      addNew: 'Neues Produkt hinzufügen',
    },
  },
  // Filling data page
  fillingData: {
    createEvent: 'Definiere Ereignis',
    updateEvent: 'Aktualisiere Ereignis',
    startDate: 'Startdatum',
    growingRoom: 'Zuchtraum',
    growingProgram: 'Zuchtprogramm',
    time: 'Zeit',
    casingSupplier: 'Lieferant Deckerde',
    substrateSupplier: 'Lieferant Substrat',
    strainType: 'Sorte (strain type)',
    caccingType: 'Caccing/Aufrauhen',
    tunnel: 'Tunnel Substratproduktion',
    daysSinceSpawning: 'Tage seit spawning',
    fillingWeightPerM2: 'Füllgewicht pro m2',
    phBeforeDelivery: 'PH Wert vor Auslieferung',
    relativeHumidity: 'Rel.Feuchte vor Auslieferung',
    deliveryWeight: 'Liefergewicht',
    structure: 'Struktur',
    fillingHeight: 'Füllhöhe Substrat',
    filledSurface: 'Füllfläche',
    dialog: {
      creating: 'neues Ereignis wird eröffnet',
      updating: 'Ereignis wird aktualisiert',
    },
    total_harvested_of_period: 'Ertrag in kg',
    kg_harvested_per_square_meter: 'Ertrag in kg/m2',
    kg_harvested_per_kg_substrate: 'Ertrag in kg/t Substrat',
    airing_after_filling: 'Ablüften nach Füllung',
    airing_after_spawining: 'Ablüften nach spawning',
    showFilters: 'Filter anzeigen',
    select: 'Auswählen...',
    range: {
      title: 'Bereich',
      currentYear: 'Aktuelles Jahr',
      currentMonth: 'Aktueller Monat',
      currentQuarter: 'Aktuelles Quartal',
      from: 'Von',
      to: 'Bis',
    },
    total: 'Gesamt',
  },
  menu: {
    profile: {
      label: 'Profil',
      icon: 'i-solar:user-bold-duotone',
      route: '/profile',
    },
    fridge: {
      label: 'Kühlraum',
      icon: 'i-solar:fridge-bold-duotone',
      route: '/fridge-check',
    },
    roomcheck: {
      label: 'Überprüfung Zuchtraum',
      icon: 'i-solar:checklist-line-duotone',
      route: '/',
    },
    notifications: {
      label: 'Benachrichtigungen',
      icon: 'notifications',
      route: '/notifications',
    },
    roomcheckreview: {
      label: 'Konsens',
      icon: 'checklist',
      route: '/room-check-review',
    },
    roomcycles: {
      label: 'Raumzyklus',
      icon: 'compost',
      route: '/room-cycles',
    },
    productionoverview: {
      label: 'Übersicht Produktion',
      icon: 'compost',
      route: '/production-overview',
    },
    productionoverviewvertical: {
      label: 'Übersicht Produktion vertikal',
      icon: 'compost',
      route: '/production-overview-vertical',
    },
    growingprograms: {
      label: 'Zuchtprogramm',
      icon: 'compost',
      route: '/growing-programs',
    },
    assignpickers: {
      label: 'Pflücker zuweisen',
      icon: 'person',
      route: '/assign-pickers',
    },
    harvestregions: {
      label: 'Erntebereiche',
      icon: 'table_chart',
      route: '/harvest-regions',
    },
    defaultregions: {
      label: 'Standardbereiche',
      icon: 'table_chart',
      route: '/default-harvest-regions',
    },
    statistics: {
      label: 'Statistiken',
      icon: 'show_chart',
      route: '/statistics',
    },
    pickinginstructions: {
      label: 'Pflückanweisungen',
      icon: 'more_horiz',
      route: '/picking-instructions',
    },
    programschedules: {
      label: 'Programmpläne',
      icon: 'schedule',
      route: '/program-schedules',
    },
    growingprogramtypecreation: {
      label: 'Definition Zuchtprogramm',
      icon: 'schedule',
      route: '/growing-program-type',
    },
    settings: {
      label: 'Einstellungen',
      icon: 'i-solar:settings-bold-duotone',
      route: '/settings',
    },
    allocationForecasts: {
      label: 'Prognose Zuordnung',
      icon: 'i-solar:clipboard-list-bold-duotone',
      route: '/allocation-forecasts',
    },
    forecast: {
      label: 'Prognose',
      icon: 'person',
      route: '/forecast',
    },
  },
  // Unique dialogs
  dialog: {
    delete: {
      confirm: 'Löschen bestätigen',
      title: 'Achtung: wird gelöscht:',
    },
  },
  // Table collumns
  table: {
    color: 'Farbe',
    bio: 'Bio',
    name: 'Name',
    weight: 'Gewicht',
    demand: 'Bedarf',
    carry: 'Übertrag',
    carryOver: 'Übertrag',
    fridge: 'Kühlraum',
    harvest: 'Ernte',
    declass: 'Deklassieren',
    purchase: 'Zukauf',
    diff: 'Differenz',
    code: 'Code',
    active: 'Aktiv',
    companyProduct: 'Company Produkt',
    products: 'Produkte',
    total: 'Total',
    dayTable: 'Tagestabelle',
    order: 'Bestellung',
  },
  mushrooms: {
    white: 'Weiss',
    whiteBio: 'Weiss Bio',
    brown: 'Braun',
    brownBio: 'Braun Bio',
  },
  // Unique buttons
  btn: {
    export: 'Export',
    edit: 'Bearbeiten',
    save: 'Speichern',
    cancel: 'Abbrechen',
    submit: 'Speichern',
    submited: 'Gespeichert',
    add: 'Hinzufügen',
    addNew: 'Neu hinzufügen',
    update: 'Aktualisieren',
    confirm: 'Bestätigen',
    import: 'Importieren',
    inactive: 'Inaktiv',
    duplicate: 'Duplizieren',
    delete: 'Löschen',
    active: 'Aktiv',
    close: 'Schliessen',
  },
  // Unique input labels and placeholders
  input: {
    search: 'Suche',
    factory: 'Produktionsstätte',
  },
  // Page titles used in the header of the screen
  pageTitle: {
    default: 'Default',
    dashboard: 'Dasboard',
    profile: 'Profil',
    growingPlan: 'Zuchträume',
    fridgeCheck: 'Überprüfung Kühlräume',
    productionInput: 'Tägliche Eingabe Produktionsdaten',
    harvestForecastsList: 'Prognosen',
    harvestForecastsEdit: 'Prognosen bearbeiten',
    activeProducts: 'Produkte',
    fillingData: 'Einfülldaten',
    forecasting: 'Prognose',
    defaultDemand: 'Standard Nachfrage',
    pickingInstructions: 'Pflückanweisungen',
    programSchedules: 'Zeitpläne',
    assignPickers: 'Pflücker-Zuordnung',
    harvestRegions: 'Pflückbereiche',
    defaultRegions: 'Standard Pflückbereiche',
    statistics: 'Statistik',
    notifications: 'Benachrichtigungen',
  },
  daynames: {
    day: 'Tag',
    days: 'Tage',
    week: 'Woche',
    weeks: 'Wochen',
    today: 'Heute',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    short: {
      mon: 'Mo',
      tue: 'Di',
      wed: 'Mi',
      thu: 'Do',
      fri: 'Fr',
      sat: 'Sa',
      sun: 'So',
    },
  },
  monthnames: {
    month: 'Monat',
    months: 'Monate',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    short: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Mär',
      apr: 'Apr',
      may: 'Mai',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Aug',
      sep: 'Sep',
      oct: 'Okt',
      nov: 'Nov',
      dec: 'Dez',
    },
  },
  mushroom: {
    'Brown Bio': 'Braun Bio',
    'White Bio': 'Weiß Bio',
    Brown: 'Braun',
    White: 'Weiß',
  },
};
