// src/stores/factories.js
import { defineStore } from 'pinia';
import { commonActions } from './mixins/commonActions';
import { commonState } from './mixins/commonState';
import { useUsersStore } from './users-store';

const RESOURCE_NAME = 'factories'

export const useFactoriesStore = defineStore({
    id: RESOURCE_NAME,

    state: () => ({
        ...commonState(),
        _current: null
    }),
    getters: {
        current: (state) => state._current,
    },
    actions: {
        ...commonActions(RESOURCE_NAME),
        async fetchCurrent(){
            // Load current factory via user store
            const userStore = useUsersStore()
            await userStore.fetchCurrent()
            this._current = userStore._current.factory
        }
    },
});
