import { GrowingProgram } from 'src/domain/models/types';

type Params = Ref<{
  queryParams: {
    from: string;
    to: string;
  };
}>;
export const useGrowingProgramsStoreV2 = (params?: Params) => {
  const { data, suspense: currentGrowingProgramSuspense } = useTypedQuery(
    '/v2/growing-programs/current'
  );

  const { data: growingPrograms, suspense: growingProgramsSuspense } = useTypedQuery(
    '/v2/growing-programs',
    {
      queryKey: [params],

      enabled: computed(() => !!params?.value),
    }
  );

  const sortedProgramsCurrent = computed(() => {
    const cP = data.value;
    return cP && [...cP].sort((a, b) => a.growing_room.digit - b.growing_room.digit);
  });

  // GrowingProgramsByIdDict
  const growingProgramsByIdDict = computed(() => {
    const gps = growingPrograms.value;
    const result: Record<string, GrowingProgram> = {};

    if (!gps) return result;
    gps.forEach((p) => (result[p.id] = p));
    return result;
  });

  return {
    currentGrowingProgramSuspense,
    growingProgramsSuspense,
    getters: { sortedPrograms: sortedProgramsCurrent, growingProgramsByIdDict },
  };
};
