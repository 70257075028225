<template>
  <q-table
    dense
    ref="table"
    :rows="currentGrowingRooms"
    :rows-per-page-options="[0]"
    class="custom-scroll fit py-4 shadow-none text-gray-6 relative overflow-x-visible"
    row-key="digit"
  >
    <template v-slot:header>
      <q-tr class="py-2">
        <q-th align="items-center" :label="$t('roomcheck.room')"> </q-th>
        <q-th
          v-for="week_day in getDaysOfTheWeek()"
          :key="week_day.ab"
          align="center"
          :label="week_day"
        >
          <div class="flex items-center justify-center">
            <span>{{ week_day.ab }}</span>
            <span class="font-thin text-2.5">{{ week_day.dateddmm }}</span>
          </div>
        </q-th>
      </q-tr>
    </template>
    <template #body="props">
      <q-tr :props="props" :key="props.row">
        <q-td class="justify-center !sticky left-0 bg-white z-1">
          <div class="flex items-center text-xs gap-1">
            <span
              :style="{ 'background-color': props.row.mushroom_type.hex_color }"
              class="w-3 h-3 rounded-4px border border-solid border-accent"
            />
            <div class="flex items-center">R{{ props.row.digit }}</div>
          </div>
        </q-td>
        <q-td
          v-for="week_day in getDaysOfTheWeek()"
          :key="props.row.digit + week_day.ab"
          class="text-center"
        >
          <span v-if="getRoomCheck(props.row.id, week_day.date)?.[0] && !kg">
            {{ getRoomCheck(props.row.id, week_day.date)?.[0] }} -
            {{ getRoomCheck(props.row.id, week_day.date)?.[1] }}
          </span>
          <span v-else-if="kg">
            {{
              getRoomCheck(props.row.id, week_day.date)?.[2]
                ? formatNumber(getRoomCheck(props.row.id, week_day.date)?.[2])
                : ' - '
            }}
          </span>
          <span v-else> - </span>
        </q-td>
      </q-tr>
    </template>
    <template #bottom-row>
      <template v-if="roomcheck && Object.keys(roomcheck).length > 0 && !kg">
        <q-tr v-for="(element, minMaxIndex) in ['Min', 'Max']" :key="element">
          <q-td
            v-for="(col, index) in columns"
            :key="index"
            class="bg-info text-gray-6 font-500r"
            :class="index === 0 ? '!sticky left-0 z-1' : ''"
          >
            <span v-if="index > 0" class="flex justify-center text-xs">
              {{ formatNumber(computeTotal(col.date, minMaxIndex)) }}
            </span>
            <span v-else class="text-xs text-left">
              {{ element }}
            </span>
          </q-td>
        </q-tr>
      </template>
      <template v-else-if="roomcheck && kg">
        <q-tr>
          <q-td
            v-for="(col, index) in columns"
            :key="index"
            class="bg-info text-gray-6 font-500"
            :class="index === 0 ? '!sticky left-0 z-1' : ''"
          >
            <span v-if="index > 0" class="flex justify-center text-xs">
              {{ formatNumber(computeTotal(col.date, 2)) }}
            </span>
            <div v-else class="flex justify-center flex-col items-start">
              <span class="text-xs text-left"> Total </span>
              <span class="text-xs text-left"> {{ formatNumber(total) }} </span>
            </div>
          </q-td>
        </q-tr>
        <q-tr v-for="mushroomType in totalMushroomTypes" v-bind:key="mushroomType.id">
          <q-td
            v-for="(col, index) in columns"
            :key="index"
            class="bg-info text-gray-6 font-500"
            :class="index === 0 ? '!sticky left-0 z-1' : ''"
          >
            <span v-if="index > 0" class="flex justify-center text-xs">
              {{ formatNumber(totalByDayByMushroomType(col.date, mushroomType)) }}
            </span>
            <div v-else class="flex justify-center flex-col items-start">
              <div class="flex flex-row">
                <span
                  :style="{ 'background-color': mushroomType.hex_color }"
                  class="w-3 h-3 block rounded-4px border border-solid border-accent"
                />
                <span class="font-thin text-2.3">{{ mushroomType.bio ? 'Bio' : '' }}</span>
              </div>
              <span class="h-3">
                {{ formatNumber(totalPerMushroomType(mushroomType)) }}
              </span>
            </div>
          </q-td>
        </q-tr>
      </template>
    </template>
  </q-table>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import { formatDate, formatNumber, formatDateDDMM } from 'src/utils/utils';
  import { Room } from 'src/components/models';
  import { useTotals } from '../composables/use-totals';

  const { t } = useI18n();
  const { currentGrowingRooms, currentRoomHarvestDaysDict } = storeToRefs(useGrowingPhaseStore());
  const { roomcheck, growingRooms } = storeToRefs(useAllocationForecastStore());

  const { totalByDayByMushroomType, totalPerMushroomType, totalMushroomTypes, total } = useTotals({
    roomcheck: computed(() => roomcheck.value) as any,
    growingRoomsDict: computed(() => growingRooms.value),
  });

  defineProps<{ kg: boolean }>();

  const computeTotal = (date: string | undefined, idx: number) => {
    if (!date) return 0;

    return Object.values(roomcheck.value).reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + (date ? Number(currentValue?.[date]?.[idx]) : 0),
      0
    );
  };

  const getRoomCheck = computed(
    () => (rowId: number, date: string) => roomcheck.value?.[rowId]?.[date]
  );

  const columns = ref([
    {
      name: 'room',
      required: true,
      align: 'center',
      label: 'Room',
      date: undefined,
      field: (row: Room) => row.digit,
      sortable: true,
    },
    ...getDaysOfTheWeek().map((dayOfWeek, index) => {
      return {
        name: dayOfWeek.ab,
        required: true,
        date: dayOfWeek.date,
        align: 'center',
        label: dayOfWeek.ab,
        field: (row: number[]) => row[index],
        sortable: true,
      };
    }),
  ]);

  function getDaysOfTheWeek() {
    const current_day = new Date();

    const days_week = [
      { ab: 'Today', date: formatDate(current_day), dateddmm: formatDateDDMM(current_day) },
    ];

    for (let i = 0; i <= 5; i++) {
      const datestr = current_day.setDate(current_day.getDate() + 1);
      const abr = getDayName(datestr).substring(0, 3);
      days_week.push({
        ab: abr,
        date: formatDate(current_day),
        dateddmm: formatDateDDMM(current_day),
      });
    }
    return days_week;
  }
  function getDayName(dateStr: number) {
    var date = new Date(dateStr);
    const str = date.toLocaleDateString('en-US', { weekday: 'long' });
    var date = new Date(dateStr);
    return t('daynames.' + str.toLowerCase());
  }
</script>
<style lang="scss">
  :deep .q-table__bottom {
    min-height: 0 !important;
  }

  .custom-scroll {
    /* width */
    ::-webkit-scrollbar {
      height: 0.3em;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 2em;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
  }
</style>
