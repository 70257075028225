<script setup lang="ts">
  import { useAuthStore } from 'stores/auth-store';

  const { logout } = useAuthStore();
</script>

<template>
  <q-btn
    no-caps
    class="full-width q-ma-md rounded-lg"
    :label="$t('authentication.logout')"
    color="negative"
    @click="logout"
  />
</template>
