<script setup lang="ts">
  import { ref } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';
  import { useAuthStore } from 'stores/auth-store';
  import { useUsersStore } from 'stores/users-store';

  const { fetchCurrent } = useUsersStore();
  const { authUsername } = storeToRefs(useAuthStore());
  const { current: user } = storeToRefs(useUsersStore());
  const modal = ref(false);

  function onOpenDialog() {
    // Lazy fetch of the current user info
    fetchCurrent();
    modal.value = true;
  }
</script>

<template>
  <q-item clickable v-ripple @click="onOpenDialog()">
    <q-item-section avatar>
      <q-icon color="gray-6" name="i-solar:user-hands-bold-duotone" />
    </q-item-section>
    <q-item-section>
      <q-item-label>{{ $t('profile.settings.user.title') }}</q-item-label>
      <q-item-label caption>{{ authUsername }}</q-item-label>
    </q-item-section>
  </q-item>
  <q-dialog v-model="modal" full-width>
    <q-card>
      <q-card-section>
        <div v-if="user">
          <q-input
            borderless
            flat
            v-model="user.factory.label"
            :label="$t('profile.settings.user.factory')"
            stack-label
            disable
          />
          <q-input
            borderless
            v-model="user.email"
            :label="$t('profile.settings.user.email')"
            stack-label
            disable
          />
          <q-input
            borderless
            v-model="user.first_name"
            :label="$t('profile.settings.user.firstName')"
            stack-label
            disable
          />
          <q-input
            borderless
            v-model="user.last_name"
            :label="$t('profile.settings.user.lastName')"
            stack-label
            disable
          />
          <q-input
            borderless
            v-model="user.intenal_id"
            :label="$t('profile.settings.user.intenalId')"
            stack-label
            disable
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
