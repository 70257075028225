import { Room, RoomStatus } from 'src/components/models';

export const useRoomCheckStoreV2 = defineStore('room-check-store', () => {
  const state = reactive({
    isLoaded: false,
  });

  const { currentGrowingProgramSuspense, getters: growingProgramGetters } =
    useGrowingProgramsStoreV2();
  const { currentGrowingPhaseSuspense, getters: growingPhaseGetters } = useGrowingPhaseStoreV2();

  const { roomcheckGrowingRooms } = storeToRefs(useAllocationForecastStore());

  const suspense = () =>
    Promise.allSettled([currentGrowingProgramSuspense(), currentGrowingPhaseSuspense()]);

  const { sortedPrograms } = toRefs(growingProgramGetters);
  const { getCurrentGrowingPhaseByRoom } = toRefs(growingPhaseGetters);

  const roomStatesTemp: Record<string, RoomStatus> = {};
  roomcheckGrowingRooms.value.forEach((currElement) => {
    roomStatesTemp[currElement.id] = RoomStatus.Empty;
  });
  const roomStates = ref(roomStatesTemp);

  const programPhasesByRoom = computed(() => {
    return sortedPrograms.value?.map((p) => {
      const phase = getCurrentGrowingPhaseByRoom.value(
        p.mushroom_type.factory_id,
        p.growing_room.digit
      );
      return { program: p, phase };
    });
  });

  const roomFromIndex = computed(() => (tab: string) => {
    for (const growingRoom in roomcheckGrowingRooms.value) {
      if (roomcheckGrowingRooms.value[growingRoom].digit === Number(tab.replace('t_', ''))) {
        return roomcheckGrowingRooms.value[growingRoom] as Room;
      }
    }
  });

  const statusColor = computed(() => {
    return (room: string) => {
      const roomIndex = roomFromIndex.value(room);

      if (roomIndex && roomIndex.id in roomStates.value) {
        switch (roomStates.value[roomIndex.id]) {
          case RoomStatus.Empty:
            return { color: '', text: '' };
          case RoomStatus.NeedHelp:
            return { color: 'red', text: 'white' };
          case RoomStatus.Reserves:
            return { color: 'green', text: 'white' };
          case RoomStatus.CheckLater:
            return { color: 'orange', text: 'black' };
        }
      }
      return { color: '', text: '' };
    };
  });

  return {
    suspense,
    getters: { programPhasesByRoom, statusColor, roomStates },
    state,
  };
});
