<script setup lang="ts">
  import { ref } from 'vue';
  import SelectorFactory from './SelectorFactory.vue';
  const modal = ref(false);
</script>

<template>
  <q-item clickable v-ripple @click="modal = true" class="">
    <q-item-section avatar>
      <q-icon name="i-solar:lock-keyhole-bold-duotone" color="red-4" />
    </q-item-section>
    <q-item-section>{{ $t('profile.settings.admin') }}</q-item-section>
  </q-item>
  <q-dialog v-model="modal" full-width>
    <q-card>
      <selector-factory class="bg-red-4 text-white" label-color="white" input-class="text-white" />
    </q-card>
  </q-dialog>
</template>
