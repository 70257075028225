import { AxiosError } from 'axios';
import { Notify } from 'quasar';
import { useAuthStore } from 'src/stores/auth-store';

export function handleApiError(error: AxiosError): string {
  let errorMessage: string;
  const authStore = useAuthStore(); // Add this line to access the auth store

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data, status, headers } = error.response;
    errorMessage = `Server error: Status ${status}, Data: ${JSON.stringify(
      data
    )}, Headers: ${JSON.stringify(headers)}`;
    console.log(errorMessage);

    // Check for a 401 status code and call the logout function
    if (status === 401) {
      errorMessage = 'You session has expired. Please log in again.';
      authStore.logout();
    }
  } else if (error.request) {
    // The request was made but no response was received

    if (error.request.status === 0) {
      errorMessage =
        'Problem with the server. Please try again later or contact with the server administrator.';
      console.log(error);
    } else {
      errorMessage = `Request error: ${JSON.stringify(error.request)}`;
    }
    console.log(errorMessage);
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = `Error: ${error.message}`;
    console.log(errorMessage);
  }

  return errorMessage;
}

export function notifyError(message?: string) {
  const m =
    (message && message.substring && message.substring(0, 100)) ||
    'Something went wrong. Please try again later.';

  console.error(message);

  Notify.create({
    type: 'negative',
    message: m,
    position: 'bottom',
    actions: [
      {
        label: 'Dismiss',
        color: 'white',
        handler: () => {
          return null;
        },
      },
    ],
  });

  throw new Error(m);
}
