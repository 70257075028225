<template>
  <div v-if="!isNoDataAvailable" class="flex w-full items-center justify-between">
    <div class="text-gray-6 text-xs flex items-center">
      {{ $t('fridge.productsPerPage') }}

      <q-select
        :options="[25, 50, 100, 200, 300]"
        v-model="scope.pagination.rowsPerPage"
        dense
        flat
        borderless
        hide-dropdown-icon
        class="rounded-lg ml-4"
      >
        <template #append>
          <q-icon name="i-solar:round-alt-arrow-down-bold-duotone" color="primary"></q-icon>
        </template>
      </q-select>
    </div>
    <div>
      <q-btn
        v-if="scope.pagesNumber > 2"
        icon="first_page"
        color="grey-8"
        round
        dense
        flat
        :disable="scope.isFirstPage"
        @click="scope.firstPage"
      />

      <q-btn
        icon="i-solar:round-alt-arrow-left-bold-duotone"
        color="primary"
        round
        dense
        flat
        :disable="scope.isFirstPage"
        @click="scope.prevPage"
      />

      <q-btn
        icon="i-solar:round-alt-arrow-right-bold-duotone"
        color="primary"
        round
        dense
        flat
        :disable="scope.isLastPage"
        @click="scope.nextPage"
      />

      <q-btn
        v-if="scope.pagesNumber > 2"
        icon="last_page"
        color="grey-8"
        round
        dense
        flat
        :disable="scope.isLastPage"
        @click="scope.lastPage"
      />
    </div>
  </div>
  <div v-else class="text-center text-red text-gray-6 py-4 w-full">
    {{ $t('fridge.noData') }}
  </div>
</template>

<script setup lang="ts">
  import { QTableSlots } from 'quasar';
  const scope = defineModel<Parameters<QTableSlots['bottom']>[0]>('scope', { required: true });
  defineProps<{ isNoDataAvailable?: boolean }>();
</script>

<style scoped></style>
