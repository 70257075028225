<template>
  <div
    class="text-xs"
    :class="{
      'pr-2': model === 'Afternoon',
      'pl-2': model === 'Morning',
    }"
  >
    <q-slider
      :color="
        allowedValues[model] === 0 ? 'orange-6' : allowedValues[model] === 1 ? 'grey' : 'blue-9'
      "
      :step="1"
      dense
      :model-value="allowedValues[model]"
      @update:model-value="(e) => updateValue(e)"
      label
      :min="0"
      :max="2"
      :label-value="model !== 'Empty' ? model : ''"
    />
  </div>
</template>

<script setup lang="ts">
  const allowedValues = {
    Morning: 0,
    Empty: 1,
    Afternoon: 2,
  } as const;

  const inverseAllowedValues = Object.fromEntries(
    Object.entries(allowedValues).map(([key, value]) => [value, key])
  ) as Record<number, keyof typeof allowedValues>;

  const model = defineModel<keyof typeof allowedValues>({ required: true });

  if (!model.value) {
    model.value = 'Empty';
  }

  function updateValue(value: number | null) {
    if (value === null) return;

    model.value = inverseAllowedValues[value];
  }
</script>

<style scoped></style>
