import { api } from 'src/boot/axios';
import { handleApiError } from 'src/utils/errorHandler';
import { defineStore } from 'pinia';
import { commonActions } from './mixins/commonActions';
import { commonState } from './mixins/commonState';
import { formatDate, formatNumber, transformDYtoYD } from '../utils/utils';
import { GrowingRoom, MushroomType } from 'src/domain/models/types';
import { GrowingPhase, GrowingProgram } from 'src/components/models';

const RESOURCE_NAME = 'crud/growing-phase';

export interface RoomHarvastDay {
  date: string;
  harvest_day_id: number;
  growing_phase_id: number;
  growing_phase: string;
  day_number: number;
  growing_room: GrowingRoom;
  target_weight: null | number;
  harvested_weight: null | number;
}
export interface CurrentRoomHarvestDaysDictType {
  [key: string]: {
    [key: string]: RoomHarvastDay;
    growing_program_id: number;
  };
}
export interface PreviousDay {
  growing_phase: GrowingPhase;
  factory_id: string;
  id: number;
  status: string;
  harvested_weight: string;
  declassified_weight: string;
  date: string;
}

export interface HarvestDayValue {
  total: string;
  previousDays: PreviousDay[];
  harvested_weight: string;
}

export interface HarvestInfo {
  label: string;
  value: HarvestDayValue;
}

type HarvestInfoFNReturnType = {
  [key: string]: HarvestInfo[];
};

export const useGrowingPhaseStore = defineStore({
  id: RESOURCE_NAME,

  state: () => ({
    ...commonState(),
    currentGrowingPhases: [],
    phases: [],
    fetchError: '',
    fetching: false,
  }),

  actions: {
    ...commonActions(RESOURCE_NAME, 'growing-phases'),
    async fetchCurrentGrowingPhases(
      params = {},
      cacheDuration: number = 5 * 60 * 1000,
      postProcess = (element: any) => element.filter((e: { harvest_days: any }) => e.harvest_days)
    ) {
      let result = await this.fetchItems(
        params,
        cacheDuration,
        postProcess,
        'currentGrowingPhases',
        'growing-phases/current'
      );
      result = result.filter((e: { harvest_days: string | any[] }) => e.harvest_days.length > 0);
      this.currentGrowingPhases = result;
      return result;
    },
    async addHarvestDay(growingPhaseId: string | number, dayName: string, before = false) {
      const attrToChange = before ? 'start' : 'end';

      const body = {
        id: growingPhaseId,
        [attrToChange]: transformDYtoYD(dayName),
      };

      return this.editItem(growingPhaseId.toString(), body);
    },
    async fetchByDateInterval(start: string, end: string) {
      try {
        const { data } = await api.get(`v2/growing-phases?from=${start}&to=${end}`);
        this.phases = data;
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },
    async fetchByYearMonth(year: number, month: number) {
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const firstDayStr = `${firstDay.getDate()}/${
        firstDay.getMonth() + 1
      }/${firstDay.getFullYear()}`;
      const lastDayStr = `${lastDay.getDate()}/${lastDay.getMonth() + 1}/${lastDay.getFullYear()}`;
      this.fetchByDateInterval(firstDayStr, lastDayStr);
      //this.fetchByDateInterval(firstDay.toISOString(), lastDay.toISOString())
    },
  },
  getters: {
    getPhases: (state) => {
      return state.phases;
    },

    currentRoomHarvestDays() {
      const result = {};
      this.currentGrowingPhases.forEach((item) => {
        const growingRoomDigit = item.growing_program.growing_room.digit;
        if (!result[growingRoomDigit]) {
          result[growingRoomDigit] = [];
        }
        item.harvest_days.forEach(
          (harvestDay: {
            date: string | number | Date;
            id: any;
            growing_phase: { label: any };
          }) => {
            const date = new Date(harvestDay.date);
            const formattedDate = formatDate(date);
            result[growingRoomDigit].push({
              date: formattedDate,
              harvest_day_id: harvestDay.id,
              growing_phase: harvestDay.growing_phase.label,
              growing_room: item.growing_program.growing_room,
            });
          }
        );
      });
      return result;
    },

    currentGrowingRooms() {
      const result: any[] = [];
      const seen = new Set();
      this.currentGrowingPhases.forEach((item) => {
        const growingRoom = item.growing_program.growing_room;
        if (!seen.has(growingRoom.id)) {
          result.push({
            ...growingRoom,
            mushroom_type: item.growing_program.mushroom_type,
          });
          seen.add(growingRoom.id);
        }
      });
      result.sort((a, b) => a.digit - b.digit);
      this.harvestInfo.value;

      // Needs refactoring acording to type
      return result as unknown as {
        id: string;
        digit: number;
        mushroom_type: MushroomType;
      }[];
    },

    harvestInfo(): HarvestInfoFNReturnType {
      const result: any = {};
      // set 1st moment of today
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      this.currentGrowingPhases.forEach((item: any) => {
        const growingRoom = item.growing_program.growing_room;
        if (!(growingRoom.id in result)) {
          result[growingRoom.id] = {};
        }
        if (growingRoom && growingRoom.id in result) {
          for (const harvestDayKey in item.harvest_days) {
            if (!(item.label in result[growingRoom.id])) {
              result[growingRoom.id][item.label] = {
                total: 0,
                previousDays: [],
              };
            }
            const harvestDay = item.harvest_days[harvestDayKey];
            if (new Date(harvestDay.date).getTime() < today.getTime()) {
              result[growingRoom.id][item.label].previousDays.push(harvestDay);
            }
            result[growingRoom.id][item.label].total +=
              item.harvest_days[harvestDayKey].harvested_weight;
          }
        }
      });
      const processedResult: any = {};

      for (const key in result) {
        const processedResultIn = [];
        for (const harvestAcc in result[key]) {
          result[key][harvestAcc].harvested_weight = result[key][harvestAcc].harvested_weight;
          result[key][harvestAcc].total = result[key][harvestAcc].total;

          processedResultIn.push({
            label: harvestAcc,
            value: result[key][harvestAcc],
          });
        }
        processedResultIn.sort((a, b) => a.label.localeCompare(b.label));
        processedResult[key] = processedResultIn;
      }
      return processedResult;
    },
    currentRoomHarvestDaySearchPrepared(): { [key: string]: { harvest_days: Set<string> } } {
      const result: any = {};
      this.currentGrowingPhases.forEach((item: any) => {
        const growingRoomDigit = item.growing_program.growing_room.digit;
        if (!result[growingRoomDigit]) {
          result[growingRoomDigit] = {
            harvest_days: new Set(),
          };
        }
        item.harvest_days.forEach((harvestDay: { date: string | number | Date }) => {
          const date = new Date(harvestDay.date);
          const formattedDate = formatDate(date);
          result[growingRoomDigit].harvest_days.add(formattedDate);
        });
      });
      return result;
    },
    currentRoomHarvestDayStatus() {
      const result = {};
      const today = formatDate(new Date());
      this.currentGrowingPhases.forEach((item) => {
        const growingRoomId = item.growing_program.growing_room.id;
        let statusForToday = null;
        item.harvest_days.forEach((harvestDay) => {
          const date = formatDate(new Date(harvestDay.date));
          if (date === today) {
            statusForToday = harvestDay.status;
          }
        });
        result[growingRoomId] = statusForToday;
      });
      return result;
    },
    daysByPhaseDict() {
      const daysByPhase: { [key: string]: { [key: number]: { day_number: number } } } = {
        undefined: {},
      };

      const dict = this.currentRoomHarvestDaysDict;

      for (const grId in dict) {
        const harvestDays = dict[grId];

        for (const day in harvestDays) {
          const p = harvestDays[day];
          if (!daysByPhase[p.date]) daysByPhase[p.date] = {};
          daysByPhase[p.date][p.growing_phase_id] = { day_number: p.day_number };
        }
      }
      return daysByPhase;
    },
    phaseByDaysDict() {
      const phaseByDays: { [key: number]: { [key: string]: { day_number: number } } } = {};

      const dict = this.currentRoomHarvestDaysDict;

      for (const grId in dict) {
        const harvestDays = dict[grId];

        for (const day in harvestDays) {
          const p = harvestDays[day];
          if (!phaseByDays[p.growing_phase_id]) phaseByDays[p.growing_phase_id] = {};
          phaseByDays[p.growing_phase_id][p.date] = { day_number: p.day_number };
        }
      }
      return phaseByDays;
    },
    currentRoomHarvestDaysDict(): CurrentRoomHarvestDaysDictType {
      const result: any = {};
      this.currentGrowingPhases.forEach((item: any) => {
        const growingRoomDigit = item.growing_program.growing_room.id;
        if (!result[growingRoomDigit]) {
          result[growingRoomDigit] = {
            growing_program_id: item.growing_program.id,
          };
        }

        item.harvest_days.forEach(
          (harvestDay: {
            date: string | number | Date;
            id: any;
            growing_phase: { label: any };
            target_weight: null | number;
            harvested_weight: null | number;
          }) => {
            const date = new Date(harvestDay.date);
            const formattedDate = formatDate(date);

            const differenceInTime = date.getTime() - new Date(item.start).getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;

            result[growingRoomDigit][formattedDate] = {
              date: formattedDate,
              harvest_day_id: harvestDay.id,
              growing_phase_id: harvestDay.growing_phase.id,
              growing_phase: harvestDay.growing_phase.label,
              day_number: differenceInDays,
              growing_room: item.growing_program.growing_room,
              target_weight: harvestDay.target_weight,
              harvested_weight: harvestDay.harvested_weight,
            };
          }
        );
      });
      return result;
    },
  },
});
