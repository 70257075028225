<template>
  <q-select
    borderless
    v-model="currentLocale"
    :options="localeOptions"
    hide-dropdown-icon
    class="rounded-lg bg-white border-solid border-accent border pr-4 pl-1"
    input-style="text-white"
  >
    <template #prepend>
      <q-icon class="pr-4" name="i-solar:earth-bold-duotone" />
    </template>
  </q-select>
</template>

<script setup lang="ts">
  import { LocalStorage } from 'quasar';
  import { useI18n } from 'vue-i18n';

  const { locale } = useI18n();

  const localeNames = reactive({
    'en-US': { name: 'English', flag: '🇺🇸' },
    // 'es-ES': { name: 'Spanish', flag: '🇪🇸' },
    'fr-FR': { name: 'Français', flag: '🇫🇷' },
    'de-DE': { name: 'Deutsch', flag: '🇩🇪' },
    // 'it-IT': { name: 'Italian', flag: '🇮🇹' }
  });

  const currentLocale = ref(`${localeNames[locale.value].name}`);

  interface Props {
    white?: boolean;
  }

  const props = defineProps<Props>();

  watch(currentLocale, () => {
    for (const key in localeNames) {
      if (localeNames[key].name === currentLocale.value.value) {
        locale.value = key;
        const language = LocalStorage.set('language', key);
        break;
      }
    }
  });

  const localeOptions = Object.keys(localeNames).map((key) => ({
    label: `${localeNames[key].name}`,
    value: localeNames[key].name,
  }));
</script>
