<template>
  <!--The right pannel that you can slide from the room check page-->
  <q-drawer
    overlay
    :width="375"
    v-model="model"
    side="right"
    class="text-gray-6 bg-info pb-10 shadow-lg border-l-solid border-gray-2"
  >
    <q-toolbar class="q-py-md sticky border-b border-accent border-b-solid border-b-1px bg-white">
      <q-toolbar-title>
        <div class="items-center flex justify-between flex-nowrap">
          <span class="flex text-center justify-center text-lg">{{
            $t('roomcheck.roomstatus')
          }}</span>

          <q-btn
            flat
            round
            dense
            icon="i-solar:close-circle-bold-duotone"
            size="lg"
            color="gray-6"
            padding="0"
            @click="model = false"
          />
        </div>
      </q-toolbar-title>
    </q-toolbar>
    <div class="flex flex-col bg-info">
      <q-item tag="label" class="full-width" v-ripple>
        <q-item-section class="justify-end">
          <q-item-label>{{ $t('roomcheck.pickers') }} / {{ $t('roomcheck.weight') }}</q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-toggle color="orange" v-model="kgToggle" />
        </q-item-section>
      </q-item>
      <div class="full-width">
        <room-overview :kg="kgToggle" class="h-400px" />
      </div>
      <div class="flex flex-col p-4 gap-4">
        <q-card
          v-for="(element, index) of Object.entries($tm('roomcheck.status'))"
          :key="index"
          class="px-4"
          flat
        >
          <q-card-section class="px-0 border-b border-b-solid border-1px border-accent pb-2">
            <span class="text-subtitle2">{{
              $t('roomcheck.status.' + element[0] + '.title')
            }}</span>
          </q-card-section>
          <q-card-section class="flex flex-wrap gap-2 px-0" v-if="roomStates[index].length > 0">
            <span
              v-for="roomState in roomStates[index]"
              :key="roomState"
              class="p-2 rounded-lg border-solid border bg-info"
              :style="{ borderColor: statusColor('t_' + roomState).color }"
            >
              {{ $t('roomcheck.room') }} {{ roomState }}
            </span>
          </q-card-section>
          <q-card-section class="px-0" v-else>
            {{ $t('roomcheck.status.' + element[0] + '.noelements') }}
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-page-sticky position="bottom-right" :offset="[18, 18]" style="z-index: 50">
      <q-btn
        icon="i-solar:download-minimalistic-bold-duotone"
        color="primary"
        fab
        @click="$emit('download')"
      />
    </q-page-sticky>
  </q-drawer>
</template>

<script setup lang="ts">
  import RoomOverview from './RoomOverview.vue';
  defineProps<{ roomStates: string[][] }>();
  defineEmits<{ download: [] }>();

  const { getters } = useRoomCheckStoreV2();
  const { statusColor } = toRefs(getters);

  const model = defineModel<boolean>();
  const kgToggle = ref(false);
</script>
