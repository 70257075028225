<template>
  <div class="p-4 bg-white rounded-lg w-full flex flex-col text-gray-6">
    <span class="text-xs mb-4 borderBottomAccent pb-2">{{ $t('fridge.lastSubmit') }}:</span>
    <div class="flex flex-wrap gap-4">
      <div class="flex items-center gap-1">
        <q-icon name="i-solar:user-bold-duotone" color="gray-6" size="sm" />
        <q-skeleton v-if="!user && isLoading" class="w-10" />
        <span class="text-xs">{{ user }}</span>
      </div>
      <div class="flex items-center gap-1">
        <q-icon name="i-solar:calendar-bold-duotone" color="gray-6" size="sm" />
        <q-skeleton v-if="!dateString && isLoading" class="w-40" />
        <span class="text-xs">{{ dateString }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps<{ dateString?: string; user?: string | null }>();

  const isLoading = ref(false);
  onMounted(() => {
    isLoading.value = true;
    setTimeout(() => {
      isLoading.value = false;
    }, 3000);
  });
</script>

<style scoped></style>
