// src/stores/factories.js
import { defineStore } from 'pinia';
import { commonActions } from './mixins/commonActions';
import { commonState } from './mixins/commonState';
import { calculateMean } from 'src/utils/math';
import { formatDate, transformDYtoYD } from '../utils/utils';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LocalStorage, extend } from 'quasar';
import { useAuthStore } from './auth-store';
import { useGrowingProgramsStore } from 'src/stores/growing-programs-store';
import { MushroomType } from 'src/domain/models/types';
import { useTotals } from 'src/pages/room-check/composables/use-totals';
import { ComposerTranslation } from 'vue-i18n';

const RESOURCE_NAME = 'crud/harvest-forecast';
const ca = commonActions(RESOURCE_NAME, 'harvest-forecast');

export type RoomcheckGrowingRoomsType = {
  id: string;
  digit: number;
  mushroom_type: MushroomType;
};

export const useAllocationForecastStore = defineStore({
  id: RESOURCE_NAME,

  state: () => ({
    ...commonState(),
    growingPrograms: [],
    roomcheckGrowingRooms: [] as RoomcheckGrowingRoomsType[],
    growingRooms: {},
    consensusForecasts: [] as {
      id: number;
      label: string;
      created_at: string;
      is_consensus: boolean;
    }[],
    consensus: {} as {
      [key: string]: { [key: string]: [number, number, number, number, boolean[]] };
    },
    thresholds: {},
    roomcheck: {} as {
      [key: string]: { [key: string]: number[] };
    },
    roomCheckAllocationForecast: null as null | string | number,
    consensusArrays: {},
  }),

  actions: {
    ...ca,
    async fetchSelectedAllocationForecasts(forecastList) {
      this.consensusForecasts = [];
      if (forecastList) {
        if (!Array.isArray(forecastList)) {
          forecastList = [forecastList];
        }
        const forecastTemp = forecastList.map((el) => {
          return { id: el };
        });
        for (const forecast of forecastTemp) {
          const forecastResult = await this.fetchItem(forecast.id, {
            excludedFields: 'harvest_day_forecasts',
          });
          this.consensusForecasts.push(forecastResult);
        }
      }
      return this.consensusForecasts;
    },
    filterForecastsByDays(processedForecasts, harvestDays) {
      const postProcessedForecasts = [];
      for (const processedForecast of processedForecasts) {
        const harvestDayForecasts = [];

        for (const column of harvestDays) {
          const date = transformDYtoYD(column);
          let added = false;
          for (const harvestDayForecast of processedForecast.harvest_day_forecasts) {
            if (
              harvestDayForecast.harvest_day &&
              harvestDayForecast.harvest_day.date.includes(date)
            ) {
              harvestDayForecasts.push(harvestDayForecast);
              added = true;
            }
          }
          if (!added) {
            harvestDayForecasts.push({
              min_harvest_regions: 0,
              max_harvest_regions: 0,
              target_weight: 0,
              target_weight_unit: 'kg',
              harvest_day: { date: date },
            });
          }
        }

        postProcessedForecasts.push({
          ...processedForecast,
          harvest_day_forecasts: harvestDayForecasts,
        });
      }
      return postProcessedForecasts;
    },
    harvestDaysForForecastsForRoom(forecasts) {
      const columns = new Set();
      for (const f of forecasts) {
        for (const harvest_day_forecast of f.harvest_day_forecasts) {
          const date = formatDate(new Date(harvest_day_forecast.harvest_day.date));
          if (harvest_day_forecast.harvest_day && !(date in columns)) {
            columns.add(date);
          }
        }
      }
      return columns;
    },

    harvestDaysForForecastsForRoomDict(forecasts) {
      const columns = {};
      for (const f of forecasts) {
        for (const harvest_day_forecast of f.harvest_day_forecasts) {
          const roomId = harvest_day_forecast.growing_room.id;
          if (!columns[roomId]) {
            columns[roomId] = {};
          }
          const date = formatDate(new Date(harvest_day_forecast.harvest_day.date));
          if (harvest_day_forecast.harvest_day && !(date in columns[roomId])) {
            columns[roomId][date] = { ...harvest_day_forecast.harvest_day };
          }
        }
      }
      return columns;
    },
    forecastsForRoom(forecasts, roomId) {
      const processedForecast = [];
      for (const forecast of forecasts) {
        processedForecast.push({
          ...forecast,
          harvest_day_forecasts: forecast.harvest_day_forecasts.filter(
            (hdf) => hdf.growing_room.id == roomId
          ),
        });
      }
      const harvestDays = this.harvestDaysForForecastsForRoom(processedForecast);
      return this.filterForecastsByDays(processedForecast, harvestDays);
    },
    checkDifference(arr: Array, threshold = 20) {
      const decimalThreshold = threshold / 100;
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          const difference = Math.abs(arr[i] - arr[j]) / ((arr[i] + arr[j]) / 2);
          if (difference > decimalThreshold) {
            return true;
          }
        }
      }
      return false;
    },
    calculateDifferenceArray(resultElement, threshold) {
      return [
        this.checkDifference(resultElement[0], threshold),
        this.checkDifference(resultElement[1], threshold),
        this.checkDifference(resultElement[2], threshold),
      ];
    },
    calculateConsensus(forecasts, harvestDays, roomId, threshold = 20) {
      const consensusArrays = [];
      const momentArray = [];

      const result = {};
      const harvestDaysArray = Array.from(harvestDays);
      for (const column of harvestDaysArray) {
        consensusArrays.push([[], [], []]);
      }

      for (const forecast of forecasts) {
        for (const [index, harvestForecastDay] of forecast.harvest_day_forecasts.entries()) {
          if (harvestForecastDay.growing_room) {
            this.growingRooms[harvestForecastDay.growing_room.id] = harvestForecastDay.growing_room;
          }
          consensusArrays[index][0].push(harvestForecastDay.min_harvest_regions);
          consensusArrays[index][1].push(harvestForecastDay.max_harvest_regions);
          consensusArrays[index][2].push(harvestForecastDay.target_weight);
          momentArray[index] = harvestForecastDay.harvest_day.moment;
        }
      }
      let differenceArray = [false, false, false];
      for (const [index, resultElement] of consensusArrays.entries()) {
        differenceArray = this.calculateDifferenceArray(resultElement, threshold);
        const min = Math.floor(calculateMean(resultElement[0]));
        let max = Math.ceil(calculateMean(resultElement[1]));

        if (index == 0) {
          max = min;
        }

        result[harvestDaysArray[index]] = [
          min,
          max,
          Math.ceil(calculateMean(resultElement[2])),
          Math.floor(calculateMean([min, max])),
          differenceArray,
          momentArray[index],
        ];
      }
      this.consensusArrays[roomId] = consensusArrays;
      this.consensus[roomId] = result;
      return result;
    },
    calculateConsensusThresholds(threshold) {
      for (const roomId in this.consensus) {
        const harvestDayObj = this.consensus[roomId];
        let index = 0;
        for (const [key, value] of Object.entries(harvestDayObj)) {
          const differenceArray = this.calculateDifferenceArray(
            this.consensusArrays[roomId][index],
            threshold
          );
          this.consensus[roomId][key][4] = differenceArray;
          index = index = 1;
        }
      }
    },

    initializeForecast(
      state_var: string,
      week_days?: Set<string> | null,
      forecast?: any,
      options?: {
        forceNew?: boolean;
      }
    ) {
      console.log('initializeForecast called');
      // If week_days is not provided or is empty, use the next seven days
      if (!week_days || week_days.size === 0) {
        week_days = this.getNextSevenDays;
      }
      let forecast_state = {};

      if (!options?.forceNew) {
        // If a forecast is provided, create the forecast state
        if (forecast) {
          forecast_state = this.createForecastState(forecast);
        } else {
          // If no forecast is provided, attempt to retrieve it from local storage
          forecast_state = this.loadStateFromLocalStorage('roomcheck');
        }
      }

      // If the state variable is not initialized or a forecast state exists, initialize it
      if (Object.keys(this[state_var]).length <= 0 || forecast_state) {
        for (const growing_room of this.roomcheckGrowingRooms) {
          this.growingRooms[growing_room.id] = growing_room;
          this[state_var][growing_room.id] = {};

          // For each day, if a forecast state exists for the room and day, use it. Otherwise, initialize to [0, 0, 0]
          for (const day of Array.from(week_days)) {
            if (
              forecast_state &&
              forecast_state[growing_room.id] &&
              forecast_state[growing_room.id][day]
            ) {
              this[state_var][growing_room.id][day] = forecast_state[growing_room.id][day];
            } else {
              this[state_var][growing_room.id][day] = [0, 0, 0];
            }
          }
        }
      }
    },

    loadStateFromLocalStorage(state_var) {
      const localStorageRoomcheck = LocalStorage.getItem(state_var);
      const { user } = useAuthStore();
      if (
        localStorageRoomcheck &&
        localStorageRoomcheck.date == formatDate(new Date()) &&
        user &&
        localStorageRoomcheck.factory == user?.factoryId
      ) {
        return localStorageRoomcheck.state;
      }
    },
    saveStateInLocalStorage(state_var) {
      const { user } = useAuthStore();
      if (state_var === 'roomcheck') {
        LocalStorage.set('roomcheck', {
          date: formatDate(new Date()),
          factory: user?.factoryId,
          state: this[state_var],
        });
      }
    },

    changeMinPickers(
      state_var: string,
      box_id: number | string,
      day_id: number | string,
      operation = false
    ) {
      if (operation) {
        this[state_var][box_id][day_id][0]++;
      } else {
        this[state_var][box_id][day_id][0]--;
      }
      if (this[state_var][box_id][day_id][1] < this[state_var][box_id][day_id][0]) {
        this[state_var][box_id][day_id][1] = this[state_var][box_id][day_id][0];
      }
    },
    changeMaxPickers(
      state_var: number | string,
      box_id: number | string,
      day_id: number | string,
      operation = false
    ) {
      if (operation) {
        this[state_var][box_id][day_id][1]++;
      } else {
        this[state_var][box_id][day_id][1]--;
      }
      if (this[state_var][box_id][day_id][0] > this[state_var][box_id][day_id][1]) {
        this[state_var][box_id][day_id][0] = this[state_var][box_id][day_id][1];
      }
    },
    async createAllocationForecast(
      state_var,
      growingRoomsHarvestDaysDict,
      consensus = null,
      submitted = false,
      label: string | null = null
    ) {
      const harvestDaysDict = growingRoomsHarvestDaysDict;
      const forecast = {
        harvest_forecasts: consensus,
        label: label,
        submitted: false,
        harvest_day_forecasts: [],
      };
      for (const roomId in this[state_var]) {
        if (this[state_var].hasOwnProperty(roomId)) {
          const weekData = this[state_var][roomId];
          for (const [key, dayData] of Object.entries(weekData)) {
            if (harvestDaysDict[roomId] && harvestDaysDict[roomId][key]) {
              const harvestDay = harvestDaysDict[roomId][key];

              if (harvestDay) {
                forecast.harvest_day_forecasts.push({
                  min_harvest_regions: dayData[0],
                  max_harvest_regions: dayData[1],
                  target_weight: dayData[2],

                  target_weight_unit: 'Kilograms',
                  harvest_day: {
                    id: harvestDay.id ? harvestDay.id : harvestDay.harvest_day_id,
                    moment: consensus ? dayData[5] : dayData[3],
                  },
                });
              }
            }
          }
        }
      }
      return await this.createItem(forecast);
    },
    async editAllocationForecast(
      state_var,
      growingRoomsHarvestDaysDict,
      id,
      consensus = null,
      submitted = false,
      label = null
    ) {
      const harvestDaysDict = growingRoomsHarvestDaysDict;
      const forecast = {
        id: id,
        //'harvest_forecasts': consensus,
        label: label,
        submitted: false,
        harvest_day_forecasts: [],
      };
      for (const roomId in this[state_var]) {
        if (this[state_var].hasOwnProperty(roomId)) {
          const weekData = this[state_var][roomId];
          for (const [key, dayData] of Object.entries(weekData)) {
            if (harvestDaysDict[roomId] && harvestDaysDict[roomId][key]) {
              const harvestDay = harvestDaysDict[roomId][key];
              console.log(harvestDay);
              if (harvestDay) {
                forecast.harvest_day_forecasts.push({
                  //'id': ,
                  min_harvest_regions: dayData[0],
                  max_harvest_regions: dayData[1],
                  target_weight: dayData[2],
                  target_weight_unit: 'Kilograms',
                  harvest_day: {
                    id: harvestDay.id ? harvestDay.id : harvestDay.harvest_day_id,
                    moment: dayData[3],
                  },
                });
              }
            }
          }
        }
      }
      return await this.editItem(id, forecast);
    },
    createForecastState(forecast) {
      // Clear the current state
      const state = {};

      // Iterate over the harvest_day_forecasts in the forecast
      for (const forecastDay of forecast.harvest_day_forecasts) {
        // Extract the roomId from the harvest_day4
        const roomId = forecastDay.growing_room.id;

        this.growingRooms[forecastDay.growing_room.id] = forecastDay.growing_room.digit;

        // If this is the first time we're seeing this roomId, initialize it in the state
        if (!state[roomId]) {
          state[roomId] = {};
        }

        // Extract the date from the harvest_day and format it
        const date = new Date(forecastDay.harvest_day.date);
        const formattedDate = formatDate(date);

        // Add the forecastDay data to the state
        state[roomId][formattedDate] = [
          forecastDay.min_harvest_regions,
          forecastDay.max_harvest_regions,
          forecastDay.target_weight,
          forecastDay.harvest_day.moment,
        ];
      }
      return state;
    },

    generatePDF(state_var: string, title: string, isConsensus?: boolean, t?: ComposerTranslation) {
      const doc = new jsPDF();
      const days: Set<string> = new Set();
      const rooms = new Set();
      const { getters: factoryGetters } = useFactoryStore();
      const { isByPickers } = toRefs(factoryGetters);
      if (isConsensus) {
        title += ' (CONSENSUS)';
      }

      const copy = extend(true, {}, this[state_var]);

      let state_to_print = null;
      if (typeof state_var === 'string') {
        state_to_print = this[state_var];
      } else {
        state_to_print = state_var;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      for (const roomId in state_to_print) {
        let dayAdded = false;
        for (const day in state_to_print[roomId]) {
          const dayDate = new Date(transformDYtoYD(day));
          if (dayDate >= today) {
            days.add(day);
            dayAdded = true;
          } else {
            delete state_to_print[roomId][day];
          }
        }
        if (!dayAdded) {
          delete state_to_print[roomId];
        } else {
          rooms.add(roomId);
        }
      }
      const daysArray = Array.from(days);
      const roomsArray = Array.from(rooms).sort(
        (a, b) => this.growingRooms[a].digit - this.growingRooms[b].digit
      );

      function getDayName(dateStr: string) {
        const [day, month, year] = dateStr.split('-');
        dateStr = `${year}-${month}-${day}`;
        const date = new Date(dateStr);
        const str = date.toLocaleDateString('en-US', { weekday: 'long' });
        if (t) {
          return t('daynames.' + str.toLowerCase());
        } else {
          return str.toLowerCase();
        }
      }
      const daysArrayToPrint = daysArray.map((day) => {
        return day + '\n' + getDayName(day);
      });

      const headers = ['Room', ...daysArrayToPrint];
      const data = [];

      let index = 0;

      for (const room of roomsArray) {
        const roomDigit = this.growingRooms[room].digit;
        const f = this.forecastsForRoom(this.consensusForecasts, room.id);
        //state_to_print[room]["mushroom_type"]["mushroom_color"]
        //console.log(this.growingRooms[room].mushroom_type.mushroom_color)

        const programStore = useGrowingProgramsStore();
        const mushroomType = programStore.getCurrentMushroomType(this.growingRooms[room].digit);
        this.growingRooms[room].mushroom_type = mushroomType;
        let lineTitle = roomDigit + ' - ' + (mushroomType?.mushroom_color || 'N/A');

        //if(this.growingRooms[room].mushroom_type.bio){
        if (mushroomType?.bio) {
          lineTitle += ' (bio)';
        }

        const line = [lineTitle];
        index = 0;
        for (const day of daysArray) {
          if (day in state_to_print[room]) {
            const minPickers = state_to_print[room][day][0];
            const maxPickers = state_to_print[room][day][1];
            const kg = state_to_print[room][day][2];
            if (isByPickers.value) {
              if (state_var == 'consensus' && index == 0) {
                line.push(minPickers + '\n' + kg + ' kg');
              } else {
                line.push(minPickers + ' - ' + maxPickers + '\n' + kg + ' kg');
              }
            } else {
              line.push(kg + ' kg');
            }
          } else {
            line.push('-');
          }
          index++;
        }
        data.push(line);
      }

      //Total line
      const line = ['Total'];
      index = 0;
      const { totalByDayByMushroomType, totalPerMushroomType, totalMushroomTypes, total } =
        useTotals({
          roomcheck: computed(() => this[state_var] as any) as any,
          growingRoomsDict: computed(() => this.growingRooms),
        });
      line[0] += '\n' + total.value + 'kg';
      for (const day of daysArray) {
        if (isByPickers.value) {
          if (state_var == 'consensus' && index == 0) {
            line.push(this.total(state_var, day, 0) + '\n' + this.total(state_var, day, 2) + ' kg');
          } else {
            line.push(
              this.total(state_var, day, 0) +
                ' - ' +
                this.total(state_var, day, 1) +
                '\n' +
                this.total(state_var, day, 2) +
                ' kg'
            );
          }
        } else {
          line.push('\n' + this.total(state_var, day, 2) + ' kg');
        }
        index++;
      }
      data.push(line);

      //Mushroom types lines
      let mushroomTypesCount = 0;
      for (const mushroomType of totalMushroomTypes.value) {
        const line = [
          mushroomType.mushroom_color +
            (mushroomType.bio ? ' Bio' : '') +
            '\n' +
            totalPerMushroomType.value(mushroomType).toString() +
            'kg',
        ];
        for (const day of daysArray) {
          line.push('\n' + totalByDayByMushroomType.value(day, mushroomType).toString() + ' kg');
        }
        mushroomTypesCount++;
        data.push(line);
      }

      const dataLength = data.length;

      doc.setFontSize(16);
      doc.text(title, 15, 10);

      autoTable(doc, {
        styles: { halign: 'center' },
        columnStyles: { 0: { halign: 'center' } },
        headStyles: { fillColor: [0, 0, 0] },
        head: [headers],
        body: data,
        didParseCell: function (data) {
          const rowIndex = data.row.index;
          if (rowIndex >= dataLength - 1 - mushroomTypesCount) {
            data.cell.styles.fillColor = [0, 0, 0];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = 'bold';
          }
        },
        didDrawCell: function (data) {
          const rowIndex = data.row.index;

          // Calculate the vertical middle point of the cell
          const middle = data.cell.y + data.cell.height / 2;

          if (Number.isInteger(data.cell.raw)) {
            if (false) {
              doc.setTextColor(255, 0, 0); // Red
            } else if (false) {
              doc.setTextColor(0, 255, 0); // Green
            } else {
              doc.setTextColor(0, 0, 255); // Blue
            }

            doc.setFontSize(17);

            // Adjust the y-coordinate to vertically align the text to the middle
            doc.text('•', data.cell.x + 4.5, middle);
          }
        }.bind(this),
      });

      doc.save(title);

      extend(true, this[state_var], copy);
    },
  },
  getters: {
    forecastsByRooms() {
      const roomForecasts = {};
      for (const column of this.consensusForecasts) {
        for (const harvest_day_forecast of column.harvest_day_forecasts) {
          const harvest_day_f_room = harvest_day_forecast.growing_room.id;
          if (!(harvest_day_f_room in roomForecasts)) {
            roomForecasts[harvest_day_f_room] = [];
          }
          if (
            harvest_day_forecast.harvest_day &&
            !(harvest_day_forecast.harvest_day.date in roomForecasts)
          ) {
            roomForecasts[harvest_day_forecast.growing_room.id].push(harvest_day_forecast);
          }
        }
      }
      return roomForecasts;
    },
    getNextSevenDays() {
      const result = new Set();
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(date.getDate() + i);
        const formattedDate = formatDate(date);
        result.add(formattedDate);
      }
      return result;
    },
    forecastsByRoomsArray() {
      const roomForecasts = [];
      for (const column of this.consensusForecasts) {
        for (const harvest_day_forecast of column.harvest_day_forecasts) {
          const harvest_day_f_room = harvest_day_forecast.growing_room;
          if (!roomForecasts.find((room) => room.id === harvest_day_f_room.id)) {
            roomForecasts.push(harvest_day_f_room);
          }
          if (
            harvest_day_forecast.harvest_day &&
            !roomForecasts.find(
              (room) =>
                room.harvest_days &&
                room.harvest_days.includes(harvest_day_forecast.harvest_day.date)
            )
          ) {
            const room = roomForecasts.find((room) => room.id === harvest_day_f_room.id);
            if (room) {
              if (!room.harvest_days) {
                room.harvest_days = [];
              }
              room.harvest_days.push(harvest_day_forecast);
            }
          }
        }
      }
      roomForecasts.sort((a, b) => a.digit - b.digit);
      return roomForecasts;
    },
    roomCheckGrowingRoomsDict() {
      const rooms = {};
      for (const element of this.roomcheckGrowingRooms) {
        rooms[element.id] = element;
      }
      return rooms;
    },
    growingRoomsByDigit() {
      const rooms = {};
      for (const element in this.growingRooms) {
        const growingRoom = this.growingRooms[element];
        rooms[growingRoom.digit] = growingRoom;
      }
      return rooms;
    },
    total() {
      return (state_var: string, daysOfTheWeek: string | number, minOrMax: string | number) => {
        let result = 0;
        if (this[state_var]) {
          for (const key in this[state_var]) {
            if (daysOfTheWeek in this[state_var][key]) {
              result += Number(this[state_var][key][daysOfTheWeek][minOrMax]);
            }
          }
        }
        return result;
      };
    },
  },
});
