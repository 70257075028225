import { api } from 'src/boot/axios';
import { handleApiError } from 'src/utils/errorHandler';
import { defineStore } from 'pinia';
import { commonActions } from './mixins/commonActions';
import { commonState } from './mixins/commonState';

import { GrowingProgram } from 'src/domain/models/types';

const RESOURCE_NAME = 'crud/growing-program';

export const useGrowingProgramsStore = defineStore({
  id: RESOURCE_NAME,

  state: () => ({
    ...commonState(),
    growingRoomsPrograms: {},
    fetchError: '',
    fetching: false,
    currents: [] as GrowingProgram[],
  }),
  getters: {
    getCurrentGrowingProgram: (state) => {
      return (growingRoomDigit) => {
        const program = state.currents.find(
          (program) => program.growing_room.digit === growingRoomDigit
        );
        return program;
      };
    },
    getCurrentMushroomType(growingRoomDigit) {
      return (growingRoomDigit) => {
        const program = this.getCurrentGrowingProgram(growingRoomDigit);
        return program?.mushroom_type as
          | { hex_color: string; name: string; mushroom_color: string; bio: string }
          | undefined;
      };
    },
    getPrograms: (state) => {
      return state.programs;
    },
  },
  actions: {
    ...commonActions(RESOURCE_NAME, 'growing-programs'),
    async fetchGrowingProgramsOfGrowingRoom(id: string) {
      this.fetchItemItem(
        id,
        'growing-programs',
        {},
        undefined,
        undefined,
        'growingRoomsPrograms',
        '.' + id
      );
    },
    /* Get the current growing programs ongoing in the factory */
    async fetchCurrent() {
      try {
        const { data } = await api.get('v2/growing-programs/current');
        this.currents = data;
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },
    async fetchByDateInterval(start: string, end: string) {
      try {
        const { data } = await api.get(`v2/growing-programs?from=${start}&to=${end}`);
        this.programs = data;
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },
    async fetchByYearMonth(year: number, month: number) {
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      this.fetchByDateInterval(firstDay.toISOString(), lastDay.toISOString());
    },
  },
});
