import { api } from 'src/boot/axios';
import { defineStore } from 'pinia';
import { handleApiError } from 'src/utils/errorHandler';


export const useInfosStore = defineStore({
    id: 'infos',

    state: () => ({
        fetchError: '',
        fetching: false,
        _versions: {
            GAE_VERSION: null,
            GAE_SERVICE: null,
            APP_ENV: null,
            DB_VERSION: null,
        }
    }),
    getters: {
        gcpService: (state) => state._versions.GAE_SERVICE,
        gcpVersion: (state) => state._versions.GAE_VERSION,
        symfonyEnv: (state) => state._versions.APP_ENV,
        databaseVersion: (state) => state._versions.DB_VERSION,
    },
    actions: {
        async fetchVersions(){
            try {
                const { data } = await api.get(`infos/versions`);
                this._versions = data
            } catch (error) {
                this.fetchError = handleApiError(error);
            } finally {
                this.fetching = false;
            }
        }
    },
});