import { Product } from 'src/components/models';

export const useProductStore = defineStore('product', () => {
  const {
    data: unFilteredProducts,
    suspense: suspenseProducts,
    isLoading: isLoadingProducts,
  } = useTypedQuery('/v2/products');

  const products = computed(() => unFilteredProducts.value?.filter((e) => e.active));

  const productByIdDict = computed(() =>
    products.value?.reduce((acc, e) => {
      acc[e.id] = e;
      return acc;
    }, {} as Record<number, Product>)
  );
  return {
    suspenseProducts,
    getters: { productByIdDict, products, unFilteredProducts, isLoadingProducts },
  };
});
