import { useQuery } from '@tanstack/vue-query';
import { FactoryConfig } from 'src/domain/models/types';

const dayOfWeekMapper: Record<number, string> = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const useFactoryStore = defineStore('factory-store', () => {
  const authStore = useAuthStore();
  const fetch = useFetchCached();

  const { data: factoryWithConfig, suspense: factoryConfigSuspesnse } = useQuery({
    queryKey: ['factory'],
    queryFn: () =>
      fetch<FactoryConfig>(`/crud/factory/${authStore.user?.factoryId}`, {
        staleTime: 1000 * 60 * 60 * 24,
      }),
    enabled: !!authStore.user?.factoryId,
  });

  const isByPickers = computed(
    () => factoryWithConfig.value?.factory_config?.allocation_forecast_by_pickers
  );

  const firstDayNameConfig = computed(
    () => dayOfWeekMapper[factoryWithConfig.value?.factory_config?.first_day_of_week || 1]
  );

  const lastDayNameConfig = computed(() => {
    const dw = factoryWithConfig.value?.factory_config?.first_day_of_week || 1;
    const idx = dw === 1 ? 7 : dw - 1;
    return dayOfWeekMapper[idx];
  });

  return {
    factoryConfigSuspesnse,
    getters: { factoryWithConfig, firstDayNameConfig, lastDayNameConfig, isByPickers },
  };
});
