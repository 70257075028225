<template>
  <q-page>
    <Actions.define>
      <div class="flex items-center gap-4">
        <q-btn
          data-cy="roomcheck-submit-button"
          @click="onSubmit"
          :label="submitSuccess ? $t('roomcheck.submited') : $t('roomcheck.submit')"
          :color="submitSuccess ? 'green' : 'primary'"
          :disabled="(submitButtonDisabled && $q.platform.is.mobile) || !isOnline"
          :loading="submitLoading && !submitSuccess"
          class="q-ml-sm rounded-lg"
          no-caps
          :icon="
            submitSuccess ? 'i-solar:check-square-bold-duotone' : 'i-solar:file-text-bold-duotone'
          "
        >
        </q-btn>
        <q-btn flat class="p-0">
          <q-icon
            size="md"
            color="primary"
            @click="rightDrawerOpen = !rightDrawerOpen"
            name="i-solar:chart-square-bold-duotone"
          />
        </q-btn>
      </div>
    </Actions.define>

    <!-- The actual content of the page that is displayed in the page by default-->
    <RightDrawer
      @download="() => generatePDF('roomcheck', title, false, t)"
      :room-states="roomStates"
      v-model="rightDrawerOpen"
    />

    <q-page class="relative">
      <!-- Index Header -->
      <q-header
        v-if="$q.platform.is.mobile"
        class="bg-white py-1 text-gray-6 border-b-1px border-b-solid border-accent flex justify-between px-4 items-center"
      >
        <!-- Page title -->
        <h1 class="text-xl font-500 text-gray-6">
          <div>{{ limitString($t('menu.roomcheck.label'), 12) }}</div>
          <NetworkStatus />
        </h1>

        <Actions.reuse />
      </q-header>

      <SafeTeleport v-else to="#after-title">
        <Actions.reuse />
      </SafeTeleport>

      <RoomCheckSmall
        v-if="isLoaded"
        :edit="creatingNewForecast"
        @room-status-change="(r) => (roomStates = r)"
        :room-states-previous="roomStates"
      />

      <div v-else class="h-90vh w-full flex justify-center items-center">
        <NetworkStatus v-if="!isOnline" />
        <Loader v-else />
      </div>

      <q-page-sticky v-if="!$q.platform.is.mobile" position="bottom-right">
        <q-btn no-caps color="primary" class="q-ma-sm" to="/allocation-forecasts">
          <q-icon name="list" class="q-mr-sm"></q-icon>
          {{ $t('roomcheck.logs') }}</q-btn
        >
      </q-page-sticky>
      <!-- Desktop version -->
      <!-- <room-check-table
        v-else
        :rooms="currentGrowingRooms"
        @room-status-change="(r) => (roomStates = <any>r)"
      /> -->
    </q-page>
  </q-page>
</template>

<script setup lang="ts">
  import { date, useQuasar } from 'quasar';
  import { limitString } from 'src/utils/utils';
  import { useI18n } from 'vue-i18n';
  import { useIsStaleHarvestDay } from './composables/is-stale-harvestday';
  const { t } = useI18n();
  defineOptions({
    name: 'RoomCheckPage',
  });

  const route = useRoute();
  const router = useRouter();
  const Actions = createReusableTemplate();

  const { isOnline } = useNetwork();

  const { currentGrowingRooms, currentRoomHarvestDaysDict } = storeToRefs(useGrowingPhaseStore());
  const { fetchCurrentGrowingPhases } = useGrowingPhaseStore();

  const { checkIfDataIsStale, queryGPC } = useIsStaleHarvestDay();

  const { roomcheckGrowingRooms, roomcheck } = storeToRefs(useAllocationForecastStore());
  const {
    initializeForecast,
    createAllocationForecast,
    fetchItem,
    saveStateInLocalStorage,
    generatePDF,
  } = useAllocationForecastStore();
  const { state } = useRoomCheckStoreV2();
  const { isLoaded } = toRefs(state);

  const gpc = useGrowingProgramsStore();
  const $q = useQuasar();

  const newOrEdit = async () => {
    const forecast = await fetchItem((route.query.fid || null) as string);

    initializeForecast('roomcheck', null, forecast, { forceNew: !!route.query.new });

    if (route.query.fid || route.query.new) {
      router.replace({ query: {} });
    }
  };

  const { suspense, data } = useTypedQuery('/v2/growing-programs/current');

  async function initPage() {
    window.scrollTo(0, 0);
    if (route.query.fid) {
      isLoaded.value = false;
    }
    await Promise.allSettled([queryGPC(), suspense()]);

    if (data.value) gpc.currents = data.value;
    await fetchCurrentGrowingPhases();
    roomcheckGrowingRooms.value = currentGrowingRooms.value;
    await newOrEdit();
    isLoaded.value = true;
  }

  onActivated(initPage);

  watch(
    isOnline,
    (online, wasOnline) => {
      if (!wasOnline && online) {
        initPage();
      }
    },
    { immediate: true }
  );

  const getTodaysString = () => date.formatDate(new Date(), 'DD-MM-YYYY HH:mm:ss');
  const submitButtonDisabled = useLocalStorage('submitButton', true);
  const submitLoading = ref(false);
  const submitSuccess = ref(false);
  const roomStates = ref<[][]>([[], [], []]);
  const rightDrawerOpen = ref(false);
  const creatingNewForecast = ref(true);
  const title = ref(getTodaysString());

  async function onSubmit() {
    submitLoading.value = true;
    const isStale = await checkIfDataIsStale();
    if (isStale) {
      submitLoading.value = false;
      return;
    }

    title.value = getTodaysString();
    await createAllocationForecast(
      'roomcheck',
      currentRoomHarvestDaysDict.value,
      null,
      false,
      title.value
    );

    title.value = getTodaysString();
    submitLoading.value = false;
    submitButtonDisabled.value = true;
    submitSuccess.value = true;
  }

  watch(
    () => roomcheck,
    () => {
      submitSuccess.value = false;
      submitButtonDisabled.value = false;
      saveStateInLocalStorage('roomcheck');
    },
    { deep: true }
  );
</script>

<style lang="scss" scoped>
  .circle-button {
    width: 45.5px;
    height: 45.5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .go-back-button {
    transform: rotate(0);
    transition: all 0.3s;

    &.active {
      transform: rotate(180deg);
    }
  }
</style>
