import { api } from 'src/boot/axios';
import { useCacheManager } from 'src/composables/useCacheManager';
import { handleApiError } from 'src/utils/errorHandler';
import { setNestedData } from 'src/utils/utils';

const { getCached, setCached } = useCacheManager<any>();

export function commonActions(RESOURCE_NAME, postResource = null) {
  if (postResource == null) {
    postResource = RESOURCE_NAME;
  }
  return {
    async fetchItems(
      params = {},
      cacheDuration?: number | undefined,
      postProcess = (element: any) => element,
      items_state_name = 'items',
      resourceName: string | null = null
    ) {
      resourceName = resourceName ?? RESOURCE_NAME;
      this.fetching = true;

      try {
        let cd = 0;
        if (cacheDuration && cacheDuration >= 0) {
          cd = cacheDuration;
        }
        const cache = getCached(resourceName + JSON.stringify(params), cd);
        if (cache !== null) {
          setNestedData(this, items_state_name, postProcess(cache));
        } else {
          const { data } = await api.get(resourceName, { params });
          const result = postProcess(data);
          setNestedData(this, items_state_name, result);
          this.lastFetched = Date.now();
          setCached(resourceName + JSON.stringify(params), data);
        }
        return this[items_state_name];
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },

    async fetchItem(
      id: string,
      params = {},
      postProcess = (element: any) => element,
      item_state_name = 'item',
      resourceName: string | null = null
    ) {
      resourceName = resourceName ?? RESOURCE_NAME;
      this.fetching = true;
      let result = null;

      try {
        const { data } = await api.get(`${resourceName}/${id}`, { params });
        result = postProcess(data);
        setNestedData(this, item_state_name, result);
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }

      return result;
    },

    async fetchItemItem(
      id: string,
      itemName: string,
      params = {},
      cacheDuration: number = 5 * 60 * 1000,
      postProcess = (element: any) => element,
      resourceName: string | null = null,
      item_state_name: string | null = null
    ) {
      resourceName = resourceName ?? RESOURCE_NAME;
      item_state_name = item_state_name ?? itemName;
      this.fetching = true;
      const resource = `${resourceName}/${id}/${itemName.replace(
        /[A-Z]/g,
        (m) => '-' + m.toLowerCase()
      )}`;

      try {
        const cache = getCached(resource, cacheDuration);
        if (cache !== null) {
          setNestedData(this, item_state_name, postProcess(cache));
        } else {
          const { data } = await api.get(resource, { params });
          const result = postProcess(data);
          setNestedData(this, item_state_name, result);
          setCached(resource, data);
          return result;
        }
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },
    async editItem(id: string, body: any) {
      this.fetching = true;
      try {
        const { data } = await api.put(`${postResource}/${id}`, body);
        const index = this.items.findIndex((element) => element.id === data.id);
        if (index !== -1) {
          this.items[index] = data;
          this.item = null;
          return data;
        }
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },

    async createItem(item: any, postProcess = (element: any) => element) {
      this.fetching = true;
      try {
        const { data } = await api.post(postResource, item);
        this.items.push(postProcess(data));
        return data;
      } catch (error) {
        this.fetchError = handleApiError(error);
      } finally {
        this.fetching = false;
      }
    },

    async deleteItem(id: string) {
      this.fetching = true;
      try {
        const { data } = await api.delete(`${RESOURCE_NAME}/${id}`);
        const index = this.items.findIndex((element) => element.id === id);
        if (index !== -1) {
          this.items.splice(index, 1);
          this.item = null;
          return data;
        }
      } catch (error) {
        this.fetchError = handleApiError(error);
        throw error;
      } finally {
        this.fetching = false;
      }
    },
  };
}
