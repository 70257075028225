import { Product } from 'src/components/models';
import { ProductRecord } from 'src/domain/models/types';
import { Tray } from '../components/TrayCheckDialog.vue';
import { useCheckFidgePageService } from './service';

export const useTrays = (
  newProductRecords: { value: Omit<ProductRecord & { proxyId?: number }, 'id'>[] },
  selectedFridge: { value: { id: any } }
) => {
  const productTrays = useLocalStorage(
    'local-cache/product-trays',
    ref<Record<number, { dateStr?: string; trays?: Tray[] }>>({})
  );

  const trayDialog = ref(false);

  onBeforeMount(() => {
    // filter if date is not today
    const filtered = Object.entries(productTrays.value).reduce((acc, [key, value]) => {
      if (value.dateStr === today.value.DDMM()) {
        acc[+key] = value;
      }
      return acc;
    }, {} as Record<number, { dateStr?: string; trays?: Tray[] }>);

    productTrays.value = filtered;
  });

  const selectedProductTrays = ref<{ product: Product; record?: ProductRecord; trays: Tray[] }>({
    product: {} as Product,
    trays: [{ num: null, weight: null }],
  });

  const traysSync = useDebounceFn(() => {
    const productId = selectedProductTrays.value.product.id;
    if (!productId) return;
    nextTick(() => {
      productTrays.value = {
        ...productTrays.value,
        [productId]: {
          dateStr: today.value.DDMM(),
          trays: selectedProductTrays.value.trays || [{ num: null, weight: null }],
        },
      };
    });
  });

  function openTrayDialog(product: Product, record?: ProductRecord) {
    if (!productTrays.value?.[product.id]) {
      productTrays.value = {
        ...productTrays.value,
        [product.id]: {
          dateStr: today.value.DDMM(),
          trays: [{ num: null, weight: null }],
        },
      };
    }

    selectedProductTrays.value = {
      product,
      trays: productTrays.value?.[product.id]?.trays || [],
      record,
    };
    trayDialog.value = true;
  }

  const { mutateProductRecord, getTodayRecord, todayRef, today } = useCheckFidgePageService();

  const queryInvlidator = useApiQueryInvalidationByRoute();

  async function saveTraysToWeight() {
    const selected = selectedProductTrays.value;
    const weight = selectedProductTrays.value.trays.reduce((acc, e) => (acc += e.weight || 0), 0);

    const { data: pResponse } = await mutateProductRecord({
      id: getTodayRecord(selected.record)?.id,
      correction: 0,
      weight,
      product_id: selected.product.id,
      product_storage_room_id: selectedFridge.value?.id,
      date: todayRef.value,
    });

    trayDialog.value = false;
    const oldEl = newProductRecords.value.find(
      (e: { product_id: number }) => e.product_id === selected.product.id
    );
    if (oldEl) {
      oldEl.weight = weight;
    }

    if (pResponse && pResponse.product_id) {
      if (!getTodayRecord({ product_id: pResponse.product_id })) {
        queryInvlidator(['/v2/product-records']);
      }
    }
  }

  watch(selectedProductTrays, traysSync, { deep: true });

  return {
    trayDialog,
    openTrayDialog,
    selectedProductTrays,
    saveTraysToWeight,
    productTrays,
  };
};
