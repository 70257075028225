// src/stores/mixins/commonState.ts
export function commonState() {
    return {
        lastFetched: null,
        fetchError: null,
        fetching: false,
        item: null,
        items: [],
        editedItem: null
    };
}