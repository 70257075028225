<template>
  <q-dialog no-refocus v-model="model">
    <div class="p-4 rounded-lg bg-white text-gray-6 w-300px">
      <div class="pb-2 borderBottomAccent flex justify-between">
        <span>{{ $t('btn.edit') }} - {{ product?.label }}</span>
        <q-btn
          color="gray-6"
          icon="i-solar:close-circle-line-duotone"
          size="md"
          flat
          dense
          class="p-0"
          @click="model = false"
        />
      </div>

      <div class="py-4">
        <div class="flex gap-10 mb-4">
          <div class="flex flex-col">
            <span class="mb-2">{{ $t('fridge.type') }}:</span>
            <div class="flex p-2 gap-2 border-solid border border-accent rounded-lg items-center">
              <IconsMushroom
                class="text-2xl"
                :style="{ color: getMushroomTypeByProduct(product)?.hex_color }"
              />

              <span>{{ getMushroomTypeByProduct(product)?.label }}</span>
            </div>
          </div>
        </div>
        <FormSelect
          v-if="record"
          :options="storageRooms"
          :disable="disable"
          emit-value
          v-model="record!.product_storage_room_id"
          @update:model-value="$emit('updateStorage')"
          :option-label="(e) => storageRoomsById?.[e.id || e]?.label"
          option-value="id"
          :label="$t('fridge.changeFridge')"
        />
      </div>

      <div class="pt-4 borderTopAccent flex justify-end">
        <q-btn
          @click="$emit('save')"
          :label="$t('btn.save')"
          :loading="loading"
          no-caps
          color="primary"
          class="rounded-lg"
        />
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
  import { Product } from 'src/components/models';
  import { ProductRecord, ProductStorageRoom } from 'src/domain/models/types';

  const { getters: mushroomTypeGetters } = useMushroomTypesStoreV2();
  const { getMushroomTypeByProduct } = toRefs(mushroomTypeGetters);

  const model = defineModel<boolean>();
  defineProps<{
    product?: Product;
    record?: ProductRecord;
    disable?: boolean;
    loading?: boolean;
    storageRooms?: ProductStorageRoom[];
    storageRoomsById?: Record<number, ProductStorageRoom>;
  }>();

  defineEmits(['updateStorage', 'save']);
</script>

<style scoped></style>
