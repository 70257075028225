import { date } from 'quasar';
import { getI18n } from 'src/boot/i18n';
import { notifyError } from './errorHandler';

export function kebabToCamelCase(str: string | string[]): string {
  if (Array.isArray(str)) {
    str = str[0];
  }
  return str.replace(/-([a-z])/g, (match, char) => char.toUpperCase());
}

export function formatDate(date: Date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}

export function formatDateDDMM(date: Date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const formattedDate = `${day}.${month}`;
  return formattedDate;
}

export function buildDate(date: string) {
  const [day, month, year] = date.split('/');
  return new Date(`${year}-${month}-${day}`);
}

const formater = new Intl.NumberFormat('de-CH');

export function formatNumber(number: number | bigint | null) {
  if (typeof number === 'number') {
    return isNaN(number) ? '-' : formater.format(number);
  }
  if (!number) return '-';
  const formated = formater.format(number);
  return formated === 'NaN' ? '-' : formated;
}

export function transformDYtoYD(dateString: string) {
  const parts = dateString.split('-');
  return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
}

export function backendGETFormat(dateString?: string | Date) {
  if (!dateString) dateString = new Date();

  return date.formatDate(dateString, 'DD/MM/YYYY');
}

export function dashedDate(date?: Date | string) {
  return backendGETFormat(date).replaceAll('/', '-');
}

export function backendPOSTFormat(dateString?: string | Date) {
  if (!dateString) dateString = new Date();

  return date.formatDate(dateString, 'DD-MM-YYYY');
}

export function setNestedData(dict, keyPath, value) {
  const keys = keyPath.split('.');
  let currentStep = dict;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      // if we are at the end of the path, set the value
      currentStep[key] = value;
    } else {
      // if the key does not exist in our dictionary, or it's not an object (so we can't continue nesting)
      if (!currentStep[key] || typeof currentStep[key] !== 'object') {
        currentStep[key] = {};
      }

      // move our step into the nested dictionary
      currentStep = currentStep[key];
    }
  }
}

export function getDayNameExtended(date: Date) {
  const label = getDayName(date);
  const raw = date.toLocaleDateString('en-US', { weekday: 'long' }) as
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';

  return {
    // monday is 1
    dayOfWeek: date.getDay() + 1,
    label,
    raw,
  };
}

export function getDayName(dateStr: string | Date, abrev = 3) {
  const i18n = getI18n();
  if (typeof dateStr === 'string' && /^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
    dateStr = transformDYtoYD(dateStr);
  }
  const date = new Date(dateStr);
  let str = date.toLocaleDateString('en-US', { weekday: 'long' });
  str = i18n?.global.t('daynames.' + str.toLowerCase());
  if (abrev) {
    str = str.substring(0, abrev);
  }
  return str;
}

export const useWeekOfTheYear = () => {
  const factoryStore = useFactoryStore();

  return {
    suspense: factoryStore.factoryConfigSuspesnse,
    getWeekOfTheYear: computed(() => (date: Date) => {
      const currentDate = new Date(date) || new Date();
      currentDate.setHours(0, 0, 0, 0);

      // Find the Thursday of the current week
      // Calculate the day offset from Sunday (0), respecting the startDayOfWeek parameter
      const dayOffset =
        (currentDate.getDay() -
          (factoryStore.getters.factoryWithConfig?.factory_config?.first_day_of_week || 1) +
          7) %
        7;

      currentDate.setDate(currentDate.getDate() - dayOffset);

      const year = currentDate.getFullYear();

      const startOfYear = new Date(year, 0, 1);
      if (date.getFullYear() !== year) return 0;

      const weekNumber = Math.ceil(
        ((currentDate.getTime() - startOfYear.getTime()) / 86400000 + 1) / 7
      );

      return weekNumber;
    }),
    getWeekOfTheYearISO: (d: Date) => date.getWeekOfYear(d),
  };
};

export const limitString = (name?: string, limit = 8, dots = true) =>
  (name?.length || 0) > limit ? name?.slice(0, limit) + (dots ? '...' : '') : name;

export function getDatesBetween(startDate: Date, endDate: Date) {
  const dates = [];
  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

// add days to a date
export function addDays(date: Date, days: number) {
  const d = new Date(date);
  d.setDate(date.getDate() + days);
  return d;
}

export function unwrapAllSettled<K, T extends PromiseSettledResult<K>>(args: T[]) {
  const results: PromiseSettledResult<K>[] = args;

  const fulfilledResults: K[] = [];
  const rejectedErrors: any[] = [];

  for (const result of results) {
    if (result.status === 'fulfilled') {
      fulfilledResults.push(result.value);
    } else if (result.status === 'rejected') {
      rejectedErrors.push(result.reason);
      notifyError(result.reason);
    }
  }

  return fulfilledResults;
}

// delay fn
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// very simple hash fn
export function hash(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

export function addMinutesToHHMM(time: string, minutes: number) {
  const [hh, mm] = time.split(':');
  const date = new Date();
  date.setHours(Number(hh));
  date.setMinutes(Number(mm));
  date.setMinutes(date.getMinutes() + minutes);
  return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
}

export function getMinutesFromHHMM(time: string) {
  const [hh, mm] = time.split(':');
  return parseInt(hh) * 60 + parseInt(mm);
}

export function calculateMinuteDifference(from: string, to: string) {
  return getMinutesFromHHMM(to) - getMinutesFromHHMM(from);
}
